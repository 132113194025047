<template>
  <v-container fluid class="tw-p-6 tw-pt-20">
    <v-row>
      <div class="tw-text-3xl tw-mb-4 tw-font-bold"> {{ quotationHeadline }}</div>
      <div class="tw-flex tw-items-center tw-mb-6 tw-w-full">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca..." single-line outlined hide-details
          class="tw-rounded-xl !tw-border-b-none" background-color="white"></v-text-field>

        <div v-if="user.role != 'supplier'">
          <v-select placeholder="Seleziona il tipo di preventivo" :items="quotationTypes" item-text="label"
            item-value="value" outlined flat v-model="selectedQuotationType" class="tw-rounded-xl !tw-ml-6"
            background-color="white" hide-details="">
          </v-select>
        </div>
        <div v-if="user.role != 'supplier'">
          <v-select clearable placeholder="Seleziona il fornitore IS" :items="suppliers" outlined flat
            v-model="selectedSupplier" class="tw-rounded-xl !tw-ml-6" background-color="white" hide-details="">
          </v-select>
        </div>
        <div class="tw-flex-1 tw-flex tw-justify-end">
          <v-btn filled class="!tw-text-white !tw-bg-custom-blue !tw-rounded-xl" primary
            @click="fetchQuotations">Aggiorna</v-btn>
        </div>
      </div>
    </v-row>
    <v-col cols="12" class="!tw-p-0">
      <v-skeleton-loader v-if="user.role == 'supplier'" :loading="loading" transition="fade-transition" class="dt"
        height="500" type="table">
        <table class="tw-w-full tw-bg-white tw-rounded-2xl">
          <thead>
            <tr>
              <th class="!tw-p-2 !tw-font-regular !tw-text-left" v-for="header in headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody v-for="group in groups" :key="group">
            <tr :class="`${getStatusClass(group)} ${getCurrentQuotationGroup(group) &&
                getCurrentQuotationGroup(group).length > 0
                ? ''
                : '!tw-hidden'
              }`">
              <td class="tw-p-4 tw-text-white">
                {{ group == "Fornitore" ? user.username : group }}
              </td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
            </tr>
            <tr :class="`tw-border-b-[1px] !tw-text-sm hover:!tw-bg-grey hover:!tw-bg-opacity-40 tw-border-azure}`"
              v-for="(item, index) in getCurrentQuotationGroup(group)" :key="index">
              <td class="tw-p-3 tw-uppercase">{{ item.quotation.customer }}</td>
              <td class="tw-p-3 tw-uppercase">
                {{ item.quotation.riferimento }}
              </td>
              <td class="tw-p-3">{{ item.quotation.machine_supplier }}</td>
              <td class="tw-p-3">{{ item.quotation.rif_code }}</td>
              <td class="tw-p-3">{{ getPrice(item.supplier.price) }}</td>
              <td class="tw-p-3">
                <div v-if="item.supplier.timeline.length > 0">
                  {{
                    moment(
                      item.supplier.timeline[item.supplier.timeline.length - 1]
                        .datetime
                    ).format("DD/MM/YYYY")
                  }}
                </div>
                <div v-else>
                  {{ moment(item.quotation.creation).format("DD/MM/YYYY") }}
                </div>
              </td>
              <td class="tw-p-3">{{ item.quotation.creator }}</td>
              <td class="tw-p-3">
                <div class="tw-flex tw-justify-start tw-items-center">
                  <div>
                    <SupplierModal :isActionEnabled="getStatusClass(group).includes('tw-bg-custom-red')
                      " action="next" :_id="item._id" :quotation="item.quotation" :supplier="item.supplier" :files="item.supplier.icaFiles ? item.supplier.icaFiles : []
                        " :_draftFiles="item.supplier.draftFiles ? item.supplier.draftFiles : []
                        " />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-skeleton-loader>
      <v-skeleton-loader v-else :loading="loading" transition="fade-transition" class="dt" height="500" type="table">
        <table class="tw-w-full tw-bg-white tw-rounded-2xl">
          <thead>
            <tr>
              <th class="!tw-p-2 !tw-font-regular !tw-text-left" v-for="header in headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(group, key) in icaQuotations" :key="key">
            <tr class="tw-bg-custom-red tw-bg-opacity-60" v-if="group.length > 0">
              <td class="tw-p-4 tw-text-white">{{ mapSubGroup(key) }}</td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
            </tr>
            <tr :class="`tw-border-b-[1px] !tw-text-sm hover:!tw-bg-grey hover:!tw-bg-opacity-40 tw-border-azure}`"
              v-for="(item, index) in group" :key="index">
              <td class="tw-p-3 tw-uppercase">{{ item.quotation.customer }}</td>
              <td class="tw-p-3 tw-uppercase">
                {{ item.quotation.riferimento }}
              </td>
              <td class="tw-p-3">{{ item.quotation.machine_supplier }}</td>
              <td class="tw-p-3">{{ item.supplier.supplier }}</td>
              <td class="tw-p-3">{{ item.quotation.rif_code }}</td>
              <td class="tw-p-3">{{ getPrice(item.supplier.price) }}</td>
              <td class="tw-p-3">
                <div v-if="item.supplier.timeline.length > 0">
                  {{
                    moment(
                      item.supplier.timeline[item.supplier.timeline.length - 1]
                        .datetime
                    ).format("DD/MM/YYYY")
                  }}
                </div>
                <div v-else>
                  {{ moment(item.quotation.creation).format("DD/MM/YYYY") }}
                </div>
              </td>
              <td class="tw-p-3">{{ item.quotation.creator }}</td>
              <td class="tw-p-3">
                <div class="tw-flex tw-justify-start tw-items-center">
                  <div>
                    <SupplierModal isActionEnabled="true" action="next" :index="index" :_id="item._id"
                      :quotation="item.quotation" :supplier="item.supplier" :files="item.quotation.icaFiles.filter(
                        (file) => file.type === (item.quotation.type == 'preventivi_stampa' ? 'mastrino' : 'scheda_tecnica') && !file.archived
                      )
                        " :_draftFiles="item.supplier.draftFiles ? item.supplier.draftFiles : []
                        " />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(group, key) in groups.filter(
            (g) => !['Commerciale', 'Logistica', 'Grafico'].includes(g)
          )" :key="key">
            <tr :class="`${getStatusClass(group)} ${getCurrentQuotationGroup(group) &&
                getCurrentQuotationGroup(group).length > 0
                ? ''
                : '!tw-hidden'
              }`">
              <td class="tw-p-4 tw-text-white">{{ group }}</td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
            </tr>
            <tr :class="`tw-border-b-[1px] !tw-text-sm hover:!tw-bg-grey hover:!tw-bg-opacity-40 tw-border-azure}`"
              v-for="(item, index) in getCurrentQuotationGroup(group)" :key="index">
              <td class="tw-p-3 tw-uppercase">{{ item.quotation.customer }}</td>
              <td class="tw-p-3 tw-uppercase">
                {{ item.quotation.riferimento }}
              </td>
              <td class="tw-p-3">{{ item.quotation.machine_supplier }}</td>
              <td class="tw-p-3">{{ item.supplier.supplier }}</td>
              <td class="tw-p-3">{{ item.quotation.rif_code }}</td>
              <td class="tw-p-3">{{ getPrice(item.supplier.price) }}</td>
              <td class="tw-p-3">
                <div v-if="item.supplier.timeline.length > 0">
                  {{
                    moment(
                      item.supplier.timeline[item.supplier.timeline.length - 1]
                        .datetime
                    ).format("DD/MM/YYYY")
                  }}
                </div>
                <div v-else>
                  {{ moment(item.quotation.creation).format("DD/MM/YYYY") }}
                </div>
              </td>
              <td class="tw-p-3">{{ item.quotation.creator }}</td>
              <td class="tw-p-3">
                <div class="tw-flex tw-justify-start tw-items-center">
                  <div v-if="item.supplier.status != 0">
                    <SupplierModal :isActionEnabled="getStatusClass(group).includes('tw-bg-custom-red')
                      " action="next" :_id="item._id" :quotation="item.quotation" :supplier="item.supplier" :files="item.supplier.icaFiles ? item.supplier.icaFiles : []
                        " :_draftFiles="item.supplier.draftFiles ? item.supplier.draftFiles : []
                        " />
                  </div>
                  <div v-else>
                    <v-btn @click="$router.push(`project/${item._id}`)" class="!tw-bg-custom-blue !tw-text-white">Vai al
                      progetto</v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-skeleton-loader>
      <div class="tw-w-full tw-mt-12 tw-pb-20 tw-flex tw-justify-start">
        <v-pagination color="#11797b" class="pag" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import SupplierModal from "../components/QuotationComponents/supplierModal.vue";
import { mapQuotationStatus } from "../plugins/utils";
import moment from "moment";
import "moment/locale/it";
import EventBus from "../plugins/eventBus";

moment.locale("it");

export default {
  components: { SupplierModal },
  name: "Quotation",
  data() {
    return {
      selectedQuotationType: "preventivi_stampa",
      quotationTypes: [{ label: "Impianti Stampa", value: "preventivi_stampa" }, { label: "Fustelle", value: "preventivi_fustella" }],
      isOpened: false,
      agentActions: [0],
      supplierActions: [1, 3, 5],
      logisticActions: [2, 6],
      graphicsActions: [4],
      loading: false,
      selectedSupplier: null,
      suppliers: [],
      icaActions: [0, 2, 4, 6],
      search: "",
      timeout: 7000,
      page: 1,
      itemsPerPage: 50,
      pageCount: 0,
      quotations: [],
    };
  },
  watch: {
    selectedSupplier(newV) {
      if (this.user.role != "supplier") {
        this.loading = true;
        this.fetchQuotations();
      }
    },
    selectedQuotationType(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        if (!this.selectedSupplier) this.fetchQuotations();
        else this.selectedSupplier = null;
      }
    },
    page(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.fetchQuotations();
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    search(newV, oldV) {
      let debounceTimer;
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(() => {
        if (newV != oldV) {
          this.loading = true;
          this.fetchQuotations();
        }
      }, 600);
    },
  },
  computed: {
    quotationHeadline() {
      if (this.user.role == "supplier") return "I tuoi impianti"
      else {
        if (this.selectedQuotationType == "preventivi_stampa")
          return "Impianti Stampa";
        else
          return "Impianti Fustelle";
      }
    },
    headers() {
      if (this.user.role != "supplier") {
        return [
          {
            text: "Cliente",
            value: "customer",
            sortable: false,
            filterable: true,
          },
          {
            text: "Riferimento",
            value: "name",
            sortable: false,
            filterable: true,
          },
          {
            text: this.selectedQuotationType == "preventivi_stampa" ? "Stampatore" : "Fustellatore",
            value: "machine_supplier",
            sortable: false,
            filterable: true,
          },
          {
            text: "Fornitore",
            value: "supplier",
            sortable: false,
            filterable: true,
          },
          {
            text: this.selectedQuotationType == "preventivi_stampa" ? "Commessa" : "Codice Fornitore",
            value: "rif_code",
            sortable: false,
            filterable: true,
          },
          {
            text: "Prezzo",
            value: "price",
            sortable: false,
            filterable: true,
          },
          {
            text: "Ultima richiesta",
            value: "creation",
            sortable: false,
            filterable: true,
          },
          {
            text: "Richiesto da",
            value: "creator",
            sortable: false,
            filterable: true,
          },
          {
            text: "Azioni",
            value: "actions",
            sortable: false,
            filterable: true,
          },
        ];
      }
      return [
        {
          text: "Cliente",
          value: "customer",
          sortable: false,
          filterable: true,
        },
        {
          text: "Riferimento",
          value: "name",
          sortable: false,
          filterable: true,
        },
        {
          text: "Fornitore",
          value: "machine_supplier",
          sortable: false,
          filterable: true,
        },

        {
          text: "Commessa",
          value: "rif_code",
          sortable: false,
          filterable: true,
        },
        {
          text: "Prezzo",
          value: "price",
          sortable: false,
          filterable: true,
        },
        {
          text: "Ultima richiesta",
          value: "creation",
          sortable: false,
          filterable: true,
        },
        {
          text: "Richiesto da",
          value: "creator",
          sortable: false,
          filterable: true,
        },
        { text: "Azioni", value: "actions", sortable: false, filterable: true },
      ];
    },
    groups() {
      return this.user.role != "supplier"
        ? [
          "Commerciale",
          "Logistica",
          "Grafico",
          "Fornitore",
          "Completati",
          "Archiviati",
        ]
        : ["Fornitore", "Commerciale", "Completati", "Archiviati"];
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    archivedQuotations() {
      return this.quotations.filter((obj) => obj.supplier.status == 8);
    },
    agentQuotations() {
      if (this.user.role != "supplier") {
        return this.quotations.filter((obj) =>
          this.agentActions.includes(obj.supplier.status)
        );
      } else {
        return this.quotations.filter(
          (obj) =>
            !this.supplierActions.includes(obj.supplier.status) &&
            obj.supplier.status != 8 &&
            obj.supplier.status != 7
        );
      }
    },
    icaQuotations() {
      let icaQuotations = [...this.quotations].filter((obj) =>
        this.icaActions.includes(obj.supplier.status)
      );

      // Aggiungi le quotations con defFiles presenti e dxfFiles mancanti
      let graphicsQuotations = [...this.quotations].filter((obj) =>
        obj.supplier.status === 2 &&
        obj.quotation.type === 'preventivi_fustella' &&
        obj.supplier.defFiles.length > 0 &&
        obj.supplier.dxfFiles.length === 0
      );

      return {
        quotation: icaQuotations.filter((obj) => obj.supplier.status == 0),
        order: icaQuotations.filter((obj) => obj.supplier.status == 2 && !graphicsQuotations.includes(obj)),
        graphics: [...icaQuotations.filter((obj) => obj.supplier.status == 4), ...graphicsQuotations],
        reception: icaQuotations.filter((obj) => obj.supplier.status == 6),
      };
    }, supplierQuotations() {
      let filteredQuotations = [...this.quotations].filter((obj) =>
        this.supplierActions.includes(obj.supplier.status)
      );

      // Escludi le quotations che devono essere spostate alla grafica
      filteredQuotations = filteredQuotations.filter(
        (obj) => !(
          obj.supplier.status === 2 &&
          obj.quotation.type === 'preventivi_fustella' &&
          obj.supplier.defFiles.length > 0 &&
          obj.supplier.dxfFiles.length === 0
        )
      );

      filteredQuotations.sort((a, b) => a.supplier.status - b.supplier.status);

      return filteredQuotations;
    },
    logisticQuotations() {
      let filteredQuotations = [...this.quotations].filter((obj) =>
        this.logisticActions.includes(obj.supplier.status)
      );

      filteredQuotations.sort((a, b) => a.supplier.status - b.supplier.status);

      return filteredQuotations;
    },
    graphicsQuotations() {
      return this.quotations.filter((obj) =>
        this.graphicsActions.includes(obj.supplier.status)
      );
    },
    completedQuotations() {
      return this.quotations.filter((obj) => obj.supplier.status == 7);
    },
  },
  created() {
    if (this.user.role != "supplier")
      this.selectedSupplier =
        localStorage.getItem("selectedSupplier") &&
          localStorage.getItem("selectedSupplier") != "null"
          ? localStorage.getItem("selectedSupplier")
          : null;
    this.fetchQuotations();
  },
  mounted() {
    this.loading = true;
    EventBus.$on("data-received", (data) => {
      if (
        data.type == "quotation-updated" &&
        this.$route.path == "/quotation"
      ) {
        this.fetchQuotations();
      }
    });
  },
  methods: {
    mapSubGroup(group) {
      switch (group) {
        case "quotation":
          return "Preventivo";
        case "order":
          return "Ordine";
        case "graphics":
          return "Grafica";
        case "reception":
          return "Ricezione";
        default:
          return group;
      }
    },
    getPrice(price) {
      if (!price) return "";

      let formattedPrice = price.toString().replace(",", ".");

      let numericPrice = parseFloat(formattedPrice);
      if (isNaN(numericPrice)) {
        return price;
      }
      return `€ ${numericPrice.toFixed(2)}`;
    },
    getStatusClass(group) {
      if (group === "Archiviati") {
        // Archived
        return "tw-bg-dark tw-p-2 tw-bg-opacity-60";
      } else if (group === "Fornitore") {
        // Supplier
        return this.user.role === "supplier"
          ? "tw-bg-custom-red tw-p-2 tw-bg-opacity-60"
          : "tw-bg-orange tw-p-2 tw-bg-opacity-60";
      } else if (group === "Commerciale") {
        return this.user.role === "supplier"
          ? "tw-bg-orange tw-p-2 tw-bg-opacity-60"
          : "tw-bg-custom-red tw-p-2 tw-bg-opacity-60";
      } else if (group !== "Completati") {
        // Other cases
        return "tw-bg-custom-red tw-p-2 tw-bg-opacity-60";
      } else {
        // 'Completati' case
        return "tw-bg-green tw-p-2 tw-bg-opacity-60";
      }
    },

    getCurrentQuotationGroup(group) {
      switch (group) {
        case "Commerciale":
          return this.agentQuotations;
        case "Fornitore":
          return this.supplierQuotations;
        case "Logistica":
          return this.logisticQuotations;
        case "Grafico":
          return this.graphicsQuotations;
        case "Completati":
          return this.completedQuotations;
        case "Archiviati":
          return this.archivedQuotations;
      }
    },
    groupString(isSupplierActions) {
      if (this.user.role == "commerciale")
        return isSupplierActions ? "In attesa" : "Da completare";
      else return isSupplierActions ? "Da completare" : "In attesa";
    },
    _mapQuotationStatus(status) {
      return mapQuotationStatus(status);
    },
    fetchQuotations() {
      axios
        .get("quotation", {
          params: {
            quotationType: this.user.role == "supplier" ? "" : this.selectedQuotationType,
            selectedSupplier: this.selectedSupplier
              ? this.selectedSupplier
              : "",
            q: this.search ? this.search : "",
            per_page: this.itemsPerPage,
            page: this.page,
          },
        })
        .then((response) => {
          this.pageCount = response.data.pagination.pages;
          this.suppliers = response.data.existingSuppliers;
          this.quotations = response.data.quotations
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            if (!this.isOpened && this.$route.params.id) {
              const id = this.$route.params.id;
              let item = document.querySelector(`.quotation_${id}`);
              if (item) {
                item.click();
              } else {
                console.error("");
              }
              this.isOpened = true;
            }
          });
        });
    },
  },
};
</script>

<style scoped>
.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.item {
  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}

.v-text-field>>>.v-input__slot::before {
  border: none !important;
}
</style>
