<template>
  <div class="tw-flex tw-bg-grey tw-p-4 tw-box-border tw-rounded-lg tw-mb-4">    
    <div class="tw-flex-1 tw-flex tw-flex-wrap">                     
      <div class="tw-w-1/3" v-if="item.target == 'BCT'">
        <b>ECT: </b> {{item.ect_inpt}}
      </div>                                    
      <div class="tw-w-1/3" v-else>
        <b>BCT: </b> {{item.bct_inpt}}
      </div>                     
      <div class="tw-w-1/3">
        <b>Spessore: </b> {{item.thickness}}
      </div>                
      <div class="tw-w-1/3">
        <b>Perimetro: </b> {{item.perimeter}}
      </div>
      <div class="tw-w-full tw-mt-4" v-if="item.target == 'BCT'">
        <div class="tw-flex tw-flex-col">
          <div class="tw-flex tw-w-full">
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT statico: </b> {{item.bct.static}}
            </div>
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT dinamico standard (/3): </b> {{item.bct.dynamic.standard}}
            </div>
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT dinamico straordinario (/5): </b> {{item.bct.dynamic.extraordinary}}
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-full tw-mt-4" v-else>
        <div class="tw-flex tw-flex-col">
          <div class="tw-flex tw-w-full">
            <div class="tw-text-lg tw-ml-2">
              <b>- ECT: </b> {{item.ect.static}}
            </div>
          </div>
        </div>
      </div>
      <div class="md:tw-hidden tw-flex tw-mt-4 tw-w-full tw-h-auto  tw-justify-center tw-items-center">
        <!-- <v-icon @click="saveBCT(index)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 !tw-text-custom-blue">mdi-pencil-circle</v-icon> -->
        <v-icon @click="deleteBCT(index)" class="tw-cursor-pointer hover:tw-opacity-80 !tw-text-custom-red">mdi-delete-circle</v-icon>
      </div>
    </div>
    <div class="tw-hidden md:tw-flex   tw-justify-center tw-items-center">
      <!-- <v-icon @click="saveBCT(index)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 !tw-text-custom-blue">mdi-pencil-circle</v-icon> -->
      <v-icon @click="deleteBCT(index)" class="tw-cursor-pointer hover:tw-opacity-80 !tw-text-custom-red">mdi-delete-circle</v-icon>
    </div>
    <ResponseNotification />
  </div>
  
</template>

<script>

import axios from 'axios';
import ResponseNotification from '../ResponseNotification.vue';

export default {
  components: { ResponseNotification },
  name: "BctListItem",
  props: ["item", "project_id", "index", "esterno"],
    data(){
        return {
        }
    },
    methods: {
      deleteBCT(index){
        this.$confirm("Sei sicuro di voler eliminare questo calcolo?").then(res => {
          if (res){
            let form = new FormData();
            axios.post("bct-delete/" + index, {"bct_model": JSON.stringify({project_id: this.project_id, esterno: this.esterno})}).then((response) => {
              this.$store.state.project.bct = response.data.bct
              this.$store.dispatch("notificate", {
                type: "success",
                text: "Calcolo eliminato con successo"
              })
            }).catch((error) => {
              this.$store.dispatch("notificate", {
                type: "error",
                text: "Errore eliminazione calcolo"
              })
            })
          }
        })
      },
    }
};

</script>