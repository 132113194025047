<template>
  <v-dialog
    v-model="isVisible"
    content-class="!tw-w-[600px]"
    background-color="white"
    persistent
  >
    <v-card elevation="0" class="tw-p-4 !tw-h-full">
      <v-row>
        <form sm="12" class="tw-flex tw-flex-col form-file">
          <div class="upload-icon tw-flex tw-p-6 tw-justify-center tw-flex-col">
            <div class="tw-flex tw-flex-row tw-mb-8">
              <!-- <div v-if="existingFiles && existingFiles.board">
                        <vue-pdf :src="axios.defaults.baseURL.replace('api/',existingFiles.board)" ></vue-pdf>
                    </div> -->
              <div class="tw-mr-4 tw-text-xl tw-font-medium">
                Scheda Produzione
              </div>
              <div class="tw-flex">
                <input
                  :required="existingFiles.board ? false : true"
                  :value="files.board"
                  @input="loadFile"
                  name="board"
                  type="file"
                  size="1"
                />
                <secondary-btn
                  v-if="existingFiles.board"
                  @click.native="openFile(existingFiles.board)"
                  text="Visualizza"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-mb-8">
              <!-- <div v-if="existingFiles && existingFiles.technical">
                        <embed :src="axios.defaults.baseURL.replace('api/',existingFiles.technical)" type="application/pdf" width="100%" height="600px" />
                    </div> -->
              <div class="tw-mr-4 tw-text-xl tw-font-medium">File Tecnico</div>
              <div class="tw-flex">
                <input
                  :value="files.technical"
                  @input="loadFile"
                  name="technical"
                  type="file"
                  size="1"
                />
                <secondary-btn
                  v-if="existingFiles.technical"
                  @click.native="openFile(existingFiles.technical)"
                  text="Visualizza"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-mb-8">
              <!-- <div v-if="existingFiles && existingFiles.graphics">
                        <embed :src="axios.defaults.baseURL.replace('api/',existingFiles.graphics)" type="application/pdf" width="100%" height="600px" />
                    </div> -->
              <div class="tw-mr-4 tw-text-xl tw-font-medium">File Grafico</div>
              <div class="tw-flex">
                <input
                  :value="files.graphics"
                  @input="loadFile"
                  name="graphics"
                  type="file"
                  size="1"
                />
                <secondary-btn
                  v-if="existingFiles.graphics"
                  @click.native="openFile(existingFiles.graphics)"
                  text="Visualizza"
                />
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-mb-8">
              <!-- <div v-if="existingFiles && existingFiles.labels">
                        <embed :src="axios.defaults.baseURL.replace('api/',existingFiles.labels)" type="application/pdf" width="100%" height="600px" />
                    </div> -->
              <div class="tw-mr-4 tw-text-xl tw-font-medium">Etichette</div>
              <div class="tw-flex">
                <input
                  :value="files.labels"
                  @input="loadFile"
                  name="labels"
                  type="file"
                  size="1"
                />
                <secondary-btn
                  v-if="existingFiles.labels"
                  @click.native="openFile(existingFiles.labels)"
                  text="Visualizza"
                />
              </div>
            </div>
          </div>
        </form>
      </v-row>
      <v-card-actions class="tw-mt-12">
        <v-spacer></v-spacer>
        <secondary-btn
          text="Chiudi"
          @click.native="closeDialog"
        ></secondary-btn>
        <primary-btn
          :loading="loading"
          text="Carica"
          class="tw-ml-2"
          @click.native="uploadFile"
        ></primary-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios";
import SecondaryBtn from "../ui/SecondaryBtn.vue";
//import VuePdf from 'vue-pdf'

export default {
  components: {},
  name: "UploadBoardModal",
  props: ["is_visible", "ordData"],
  data() {
    return {
      lastUpdate: null,
      loading: false,
      existingFiles: {},
      files: {
        board: undefined,
        technical: undefined,
        labels: undefined,
        graphics: undefined,
      },
      formData: new FormData(),
    };
  },
  computed: {
    isVisible() {
      if (this.is_visible) {
        this.fetchBoards();
      } else {
        this.resetData();
      }
      return this.is_visible;
    },
    info: {
      get() {
        return this.ordData;
      },
      set(newName) {
        return this.ordData;
      },
    },
  },
  methods: {
    openFile(dir) {
      window.open(axios.defaults.baseURL.replace("api/", dir));
    },
    fixPreview() {
      var embed = this.$refs.embed;
      var aspectRatio = embed.width / embed.height;
      embed.style.height = embed.clientWidth / aspectRatio + "px";
    },
    resetData() {
      console.log("RESETTING");
      this.loading = false;
      this.operator = null;
      this.info = {};
      (this.isStarting = false), (this.selectedManufacture = {});
      this.formData = new FormData();
      this.files = {
        board: undefined,
        technical: undefined,
        labels: undefined,
        graphics: undefined,
      };
      this.formData = new FormData();
      this.existingFiles = {};
    },
    async fetchBoards() {
      console.log("ord data ", this.info);
      try {
        const res = await axios.get("board-files/" + this.info.CodiceOrdProd);
        let files = {};
        let data = res.data.boardFiles;
        if (data) {
          this.lastUpdate = data.lastUpdate;
          data.path.map((item) => {
            console.log(item);
            Object.assign(files, item);
          });
          this.existingFiles = files;
          return files;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async uploadFile() {
      if (this.checkForm("form-file")) {
        let data = { ...this.info };
        data.operator = this.operator;
        data.commenti = "";

        this.loading = true;
        this.formData.append("data", JSON.stringify(data));

        await axios
          .post("upload-board-file/", this.formData)
          .then(async (res) => {
            this.loading = false;
            const files = await this.fetchBoards();
            if (files.board) {
              this.$confirm(
                "Hai caricato la scheda di produzione, vuoi spostare la lavorazione in coda?"
              ).then((resolver) => {
                if (resolver) {
                  axios
                    .patch(
                      `stock-productions/${this.info.CodiceOrdProd}/queue`,
                      {
                        queue: true,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((result) => {
                      this.$store.dispatch("notificate", {
                        text: "Aggiunto alla coda",
                        type: "success",
                      });
                      this.closeDialog();
                    });
                }
              });
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    loadFile(e) {
      for (const [key, value] of this.formData.entries()) {
        if (e.target.getAttribute("name") === key) {
          this.formData.delete(key);
        }
      }
      this.formData.append(e.target.getAttribute("name"), e.target.files[0]);
      this.formData.append("idOrdProd", this.info.CodiceOrdProd);
    },
    checkForm(classname) {
      var forms = document.getElementsByClassName(classname);
      for (var i = 0; i < forms.length; i++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }
      return true;
    },

    closeDialog() {
      this.resetData();
      this.$emit("isVisibleChanged", false);
    },
  },
};
</script>