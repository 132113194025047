<template>


  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center w-full">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Magazzino</h1>
          <input-field    
            class="ml-8 !tw-w-96"    
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca nel magazzino"
            @input="saveSearch"
          ></input-field>
          <div class="tw-flex tw-items-center">
            <div>Seleziona il tipo di magazzino:</div>
            <custom-select  
              @input="changeFilters"
              v-model="selectedStock"
              label="label"
              :clearable="false" 
              :options="stocks" 
              class="select-dis !tw-bg-white tw-ml-4 tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-64 !tw-border-0 select-style-chooser" 
                >
            </custom-select> 
          </div>          
          <div v-if="selectedStock.location == 'mag'" class="tw-flex tw-items-center tw-ml-6">
            <div>Tipologia:</div>
            <custom-select  
              @input="changeFilters"
              v-model="selectedType"
              label="label"
              :clearable="false" 
              :options="types" 
              class="select-dis !tw-bg-white tw-ml-4 tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-64 !tw-border-0 select-style-chooser" 
              >
            </custom-select> 
          </div>
          <div class="tw-flex-1 tw-justify-end tw-flex">
            <primary-btn  text="Stampa" @click.native="printTable" />
          </div>
          
        </div>
      </v-col>  
        <v-skeleton-loader
          :loading="loading"
          transition="fade-transition"
          class="dt"
          height="500"
          type="table"
        > 
          <v-data-table
          @current-items="getFiltered"
          hide-default-footer
          :headers="headers"
          :items="stock"
          loading="true"
          loading-text="Sto caricando..."
          :items-per-page="itemsPerPage"
          class="elevation-1 item dt font2 !tw-rounded-lg projects"
          height="100%"
          :search="search"
          @page-count="pageCount = $event"
          :page.sync="page"
          >
            <template v-slot:item.DataDoc="{ item }">     
              <td>

              {{formatDate(item.DataDoc)}}
              </td>
            </template>
            <template v-slot:item.Bancali="{ item }">     
              <td>

              {{item.Bancali}}
              </td>
            </template>
            <template v-slot:item.RagioneSociale="{ item }">     
              <td>
              {{item.RagioneSociale ? item.RagioneSociale : "MAGAZZINO"}}
              </td>
            </template>    
            <template v-slot:item.Ingombro="{ item }">     
              <td>
              {{item.Ingombro}}
              </td>
            </template>            
            <template v-slot:item.Formato="{ item }"> 
              {{item.Formato}}
            </template>
          </v-data-table>

        </v-skeleton-loader>
        <div class="text-center pt-2">
          <v-pagination
            color="#11797b"
            class="pag"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </div>
    </v-row>
  </v-container>
  
</template>

<script>
import axios from 'axios'
import printJS from 'print-js'

  export default {
    name: 'Production',
    components: {
    },
    data (){
      return {
        selectedStock: {label: "Magazzino", location: "mag"},
        stocks: [{label: "Magazzino", location: "mag"},{label: "Conto Deposito", location: "Cdep"},],
        selectedType: {label: "Finiti", type: 2},
        types: [{label: "Cartone", type: 0},{label: "Semilavorati", type: 1},{label: "Finiti", type: 2}],
        search: '',
        loading: true,
        timeout: 7000,
        page: 1,
        itemsPerPage: 41,
        pageCount: 0,
        stock: [],
        printingData: []
      }
    },
    computed: {
      headers(){
          return [
          { text: "Riferimento", value: "Riferimento", sortable: false, filterable: false },
          this.selectedType.type != 0 ? { text: "Codice Articolo", value: "CodiceArticolo", sortable: false, filterable: false } : {},
          { text: "Nome Cliente", value: "RagioneSociale", sortable: false, filterable: true },
          this.selectedType.type == 0 ? { text: "Descrizione", value: "DescrizioneArticolo", align: "start", sortable: false } : {},
          this.selectedType.type != 0 ? { text: "Descrizione", value: "Descrizione", align: "start", sortable: false } : { text: "Formato", value: "Formato", align: "start", sortable: false },
          { text: "Giacenza", value: "NumeroResiduo", sortable: false, filterable: false },
          (this.selectedType.type == 2 && this.selectedStock.location == 'mag') ? { text: "Bancali", value: "Bancali", align: "start", sortable: false } : {},
          (this.selectedType.type == 2 && this.selectedStock.location == 'mag') ? { text: "Tipo Bancale", value: "TipoBancale", align: "start", sortable: false } : {},
          (this.selectedType.type == 2 && this.selectedStock.location == 'mag') ? { text: "Ingombro", value: "Ingombro", align: "start", sortable: false } : {},
          //{ text: "Magazzino", value: "DescrizMagazzino", sortable: false, filterable: false },
        ]
      },
      user() {
        return this.$store.getters.getCurrentUser
      },
      requestString(){
        return "?stock=" + this.selectedStock.location + "&tipo=" + this.selectedType.type
      }
    },

    mounted: function() {
      this.tomorrow()
      this.getStock()
      
    },
    methods: {
      getFiltered(e){
        this.printingData = e
      },
      printTable(){
        let data = this.printingData.length > 0 ? this.printingData : this.stock
        let props = []
        this.headers.forEach((item) => {
          if(item["value"]) props.push(item["value"])
        })
        printJS(
          {printable: data, 
          properties: props,
          type: 'json',
          font: 'Open Sans',
          style: "@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');",
          gridHeaderStyle: "color: red;  border: 2px solid #3971A5; background-color: #DDEBEB;  font-family: 'Open Sans', sans-serif !important'",
          gridStyle: 'border: 2px solid #3971A5; font-size: 12px'
          }
        )
      },
      handleFormato(item){
        return item.Descrizione + (item.Canna ? (item.Canna + 'x') : null) + (item.Lunghezza ? (item.Lunghezza) : null)
      },
      handleIngombro(item){
        return (item.LungBanc ? (item.LungBanc + 'x') : null) + (item.LargBanc ? (item.LargBanc + 'x') : null) + (item.AltBanc ? (item.AltBanc) : null)
      },
      handlePallets(item){
        let palletsKeys = Object.keys(item).filter(key => key.startsWith('NumBanc'))
        let result = ''

        palletsKeys.forEach((key,index) => {
          if(item[key]){
            result = result + item[key] + 'x' + item["QuantBanc" + (index+1)] +' - '
          }
        })
        result = result.substring(0, result.length - 3)
        return result
        
      },
      changeFilters() {
        this.loading = true
        this.getStock()
         setTimeout(() => this.getStock(), this.timeout);  
      },
      formatDate(date){
        date = new Date(date)
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      },
      saveSearch(){
        localStorage.setItem("stock_search", this.search);
      },
      getStock(){
        axios.get('stock' + this.requestString)
        .then((response) => {
          let data = response.data.items
          console.log(data.length)
          data.forEach((item, index) => {
            data[index]["Ingombro"] = this.handleIngombro(data[index])
            data[index]["Bancali"] = this.handlePallets(data[index])
            data[index]["Formato"] = this.handleFormato(data[index])
          })
          this.stock = data
          this.search = localStorage.getItem("stock_search");
          this.loading = false
          console.log(this.stock[0])

        })        
        .catch(err => {
          console.log(err)
        })
      },
      goTo(a){ 
        if (event.target.classList.contains('btn__content')) return;
        this.$router.push('/to-production/'+a.id_project).catch(()=>{});
      },
      getCurrentStatus (status) {
        if (status == 0){
          return {color:"#c0c0c0", text:'Registrato'}
        }
        else if (status == 1){
          return {color:"#ffc412", text:'Rilasciato'}
        }        
        else if (status == 2){
          return {color:"red", text:'In corso'}
        }
        else if (status == 3){
          return {color:"green", text:'Completato'}
        }

      },
      tomorrow(delivery){
        var today = new Date()
        var delivery_date = new Date(delivery)
        var delay = this.findDayDifference(today, delivery_date)

        if (delivery){
          var formatted_date = delivery_date.toLocaleString().split(",")[0].replaceAll('/', "-")
          if (delay == -1 ){
            return {date: formatted_date, isExpiring: 1, text: "In scadenza oggi"}
          }

          else if (delay == 1){
            return {date: formatted_date, isExpiring: 1, text: "In scadenza domani"}
          }

          else if (delay < -1){
            return {date: formatted_date, isExpiring: 1, text: "Scaduto"}
          }

          else {
            return {date: formatted_date, isExpiring: 0}
          }
        }
        
      },
      findDayDifference(date1, date2) {
        return Math.floor((date2-date1)/(1000*60*60*24))
      }
    }


  
}
  
  
</script>

<style scoped>

  .expiring {
    background-color: tomato;
    color: white;
    position: absolute;
    left: 80px;
    width: max-content;
    padding: 1px 3px;
    border-radius: 2px;
    font-size: 11px;

  }

  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}

.force-square {

  border-radius: 0px !important;
}

.bigger-badge {

  font-size: 10px !important;
}

.dt {
  width: 100%;
}



</style>
