<template>
    <div class="tw-w-full tw-h-screen tw-fixed asbolute tw-bg-dark tw-bg-opacity-80 tw-flex tw-items-center tw-justify-center tw-top-0 tw-left-0" style="z-index: 20">
        <div class="tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-bg-white tw-px-6 tw-py-12">
            <div class="tw-text-xl tw-mb-4">E' disponibile un aggiornamento per ICA Project</div>
            <div class="tw-text-md tw-mb-8">Clicca qui sotto per aggiornare</div>
            <primary-btn @click.native="update" text="Aggiorna" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpdateModal',
    methods: {
        update(){
            window.location.reload()
        }
    }
}
</script>