<template>


  <v-container
    fluid
  >
    <v-row
    >
      <v-col cols="8" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Contestazioni</h1>
          <input-field    
            class="ml-8 !tw-w-96"    
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca tra le contestazioni"
            @input="saveSearch"
          ></input-field>
        </div>
      </v-col>  
      <v-data-table

      hide-default-footer
      :headers="headers"
      :items="projects"
      loading="true"
      loading-text="Sto caricando..."
      :items-per-page="itemsPerPage"
      class="elevation-1 item"
      height="100%"
      :search="search"
      @click:row="goTo"
      @page-count="pageCount = $event"
      :page.sync="page"
      >
      
      <template v-slot:item.is_editing="{ item }">
        <v-chip
          color="transparent"
          class="capitalizer"
          v-if="item.is_editing"
        >

        {{item.is_editing}}

        </v-chip>
      </template>

      <template v-slot:item.last_status="{ item }">
        <v-chip
          :color="getColor(item.last_status)"
          dark
          class="capitalizer"
        >

        {{item.last_status}}

        </v-chip>
      </template>
      <template v-slot:item.priority="{ item }">
        <td
          v-if="item.last_status!= 'completato'"
          dark
          class="capitalizer"
        >

        {{item.priority}}
        </td>

      </template>
      <template v-slot:item.production_status="{ item }">
        <v-badge
          :color="getpStatus(item.production_status)"
          dark
        >

        </v-badge>
        
      </template>

      <template v-slot:item.has_seen="{ item }">
        <v-chip
          :color="getSeen(item.has_seen)"
          dark
          class="capitalizer"
        >

        {{item.has_seen}}
        </v-chip>
      </template>
      </v-data-table>
        <div class="text-center pt-2">
      <v-pagination
        color="#11797b"
        class="pag"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    </v-row>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#11797b"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
  </v-container>

</template>

<script>
import axios from 'axios'

  export default {
    name: 'Contestazioni', 

    data (){
      return {
        timeout: 7000,
        fab: false,
        page: 1,
        itemsPerPage: 100,
        pageCount: 0,
        scrollPosition: 0,
        search: '',
        headers: [
        { text: "ID"
        , value: "id", sortable:false,filterable: false },
        { text: "Cliente", value: "customer", sortable:false, filterable: true },
        { text: "Riferimento Progetto", value: "rif",  sortable:false,filterable: true },
        { text: "Data di creazione", value: "creation",  sortable:false,filterable: false},
      ],
      pagination: {
    rowsPerPage: 100,
    descending: false,
    page: 1
  },
      projects: [],
        }
      },
  beforeCreate: function () {
            axios.get('disputes')
            .then((response) => {
              if (response.data.status != "logged_in"){
                this.$router.push('/login').catch(()=>{}); 
              }
              if (response.data.alert == 'not allowed'){
                  this.$router.push('/production').catch(()=>{}); 
              }            

              
            })
      
    },
    watch: {
        projects() {
            
            setTimeout(() => this.getProjects(), this.timeout);  
        }
    
},

    mounted: function() {
      
      
      this.getProjects()
      window.addEventListener('scroll', this.updateScroll);
      
      
    },

    updated: function() {
      window.scrollTo(0,parseInt(this.scrollPosition))
    },



    beforeRouteLeave: function(to, from, next) {
      console.log(this.scrollPosition)



      next();
      
    },
    methods: {
      updateScroll() {
      this.scrollPosition = window.scrollY
    },

      saveSearch(){
        

        var search_text = new FormData();

        search_text.append("search", this.search);

        axios.post('save-search', search_text)
          .then((response) => {

            console.log(response)

            
          })

      },

      fixWatcher(){

        
        if (this.sorting == ['status', 'in attesa']){
            this.sorting = ['status', 'completed']
        }
        else {
          this.sorting = ['status', 'in attesa']
        }
        
      },

      newProject() {
        axios.post('newproject')
        .then((response) => {

          this.$router.push('/project/'+response.data.id).catch(()=>{});
            
        })

      },
      goTo(a){
        if (event.target.classList.contains('btn__content')) return;
        this.$router.push('/dispute/'+a._id).catch(()=>{});

      },
      getProjects(){

        axios.get('disputes')
        .then((response) => {

          this.projects = response.data.disputes;
          this.search = response.data.search;


          
        })        
      },

      getColor (status) {
        if (status == "in attesa"){
          return "#ffc412"
        }

        else if (status == "aperto"){
          return "blue"
        }

        else if (status == "in lavorazione"){
          return "red"
        }
        else if (status == "creato"){
          return "black"
        }

        else {
          return "green"
        }
      },
      getpStatus (status) {
        if (status == "in attesa"){
          return "#ffc412"
        }

        else if (status == "aperto"){
          return "blue"
        }

        else if (status == "da completare"){
          return "#ffc412"
        }

        else if (status == "in produzione"){
          return "red"
        }
        else if (status == "file da caricare"){
          return "#2f2f2f"
        }

        else if (status == "completato"){
          return "green"
        }

        else {
          return "transparent"
        }
      },
      getSeen (view) {
        if (view == "X"){
          return ""
        }

        else {
          return "#abebe0"
        }
      },
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
        toTop () {
          this.$vuetify.goTo(0)
      }


    },

    

}
  
  
</script>

<style scoped>


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .item {

    cursor: pointer;
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}



</style>
