<template>
  <div>
    <div
      @click="isExpanded = true"
      :class="`tw-bg-white tw-mb-2 tw-p-4 tw-rounded-2xl hover:tw-cursor-pointer hover:tw-opacity-75 tw-flex tw-flex-wrap tw-justify-between tw-border-2 tw-border-opacity-0 tw-border-dark hover:tw-border-opacity-100 hover:tw-border-custom-blue tw-transition ${
        note.highlight ? '!tw-bg-orange !tw-bg-opacity-60' : ''
      }`"
    >
      <div class="tw-flex-1">
        <h3 :class="'tw-font-semibold tw-text-md ' + getUserColor()">
          {{ note.created_by }}
        </h3>
        <div>{{ note.description }}</div>
      </div>
      <div class="tw-flex tw-w-full tw-justify-end">
        <v-icon size="17" class="tw-mr-1 tw-text-dark tw-opacity-60"
          >mdi-clock</v-icon
        >
        <div class="tw-text-xs">
          {{ moment(note.created_at).format("DD/MM/YYYY HH:mm") }}
        </div>
      </div>
    </div>
    <v-dialog max-width="600" v-model="isExpanded">
      <v-card>
        <v-card-title>
          {{ note.created_by }}
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="note.description"
                  label="Descrizione*"
                ></v-textarea>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="canEdit" color="red darken-1" text @click="deleteNote">
            Elimina
          </v-btn>
          <v-btn color="blue darken-1" text @click="isExpanded = false">
            Chiudi
          </v-btn>
          <v-btn v-if="canEdit" color="blue darken-1" text @click="editNote">
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../../../plugins/axios";
import moment from "moment";
moment.locale("it");

export default {
  props: ["note","esterno","_id"],
  name: "NoteItem",
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    canEdit(){
      console.log(this.note.created_by == this.user.username)
      return this.note.created_by == this.user.username
    },
  },
  methods: {

    editNote() {
      const url = this.esterno
        ? `project/${this._id}/${this.esterno}/notes/${this.note.id}`
        : `project/${this._id}/notes/${this.note.id}`;
      axios
        .patch(url, this.note)
        .then((res) => {
          this.$emit("editNote", this.note);
          this.$store.dispatch("notificate", {
            type: "success",
            text: "Nota modificata correttamente",
          });
          this.isExpanded = false;
        })
        .catch((err) => {
          console.log(err);
          //se l'errore è 401
          if (err.response.status === 401) {
              this.$store.dispatch("notificate", {
                type: "error",
                text: err.response.data.error,
              });
          }
        })
        .finally(() => {
          
        });
      
    },
    deleteNote() {
      this.$confirm("Vuoi cancellare la nota?").then((res) => {
        if (res) {
          const url = this.esterno
            ? `project/${this._id}/${this.esterno}/notes/${this.note.id}`
            : `project/${this._id}/notes/${this.note.id}`;
          axios
            .delete(url)
            .then((res) => {
              this.$emit("deleteNote",  this.note.id);
              this.$store.dispatch("notificate", {
                type: "success",
                text: "Nota eliminata correttamente",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    getUserColor() {
      return this.note.created_by === this.user.username
        ? "tw-text-custom-blue"
        : "tw-text-custom-red";
    },
  },
};
</script>