<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="!tw-p-0 tw-items-center tw-mb-8 tw-flex">
        <div class="tw-flex tw-items-center">
          <v-icon
            @click="$router.push('/customers').catch(() => {})"
            class="!tw-text-dark tw-mr-4 hover:tw-opacity-80 tw-cursor-pointer"
            >mdi-arrow-expand-left</v-icon
          >
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">
            Scheda cliente
          </h1>
        </div>
        <div class="tw-flex tw-items-center tw-ml-6">
          <primary-btn
            @click.native="updateCustomer"
            id="action-btn"
            class="tw-self-baseline tw-mr-2"
            text="Salva Cliente"
          ></primary-btn>
          <v-menu
            v-model="actions_dialog"
            offset-y
            content-class="!tw-shadow-xl !tw-rounded-lg !tw-w-auto !tw-min-w-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <secondary-btn
                :loading="isLoading.actions"
                v-bind="attrs"
                v-on="on"
                @click.native="actions_dialog = true"
                id="action-btn"
                class="tw-self-baseline"
                text="Altre Azioni"
                icon="mdi-menu-down"
              ></secondary-btn>
            </template>
            <v-list class="font2">
              <action-element
                @click.native="goBack"
                text="Chiudi"
                icon="mdi-close-thick"
              ></action-element>
              <action-element
                color="red"
                @click.native="deleteCustomer"
                text="Elimina Cliente"
                icon="mdi-delete"
              ></action-element>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-row class="!tw-flex">
      <v-col class="!tw-w-1/2 font2">
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Nome Cliente</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.name"
            placeholder="Inserisci il nome"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Indirizzo</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.address"
            placeholder="Inserisci l'indirizzo"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Città</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.location"
            placeholder="Inserisci la città"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">CAP</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.cap"
            placeholder="Inserisci il cap"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Provincia</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.province"
            placeholder="Inserisci la provincia"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Stato</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.country"
            placeholder="Inserisci lo stato"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Telefono</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.phone"
            placeholder="Inserisci il numero di telefono"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Email</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.email"
            placeholder="Inserisci la mail"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Riferimento</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.rif"
            placeholder="Inserisci il riferimento"
          ></input-field>
        </div>
        <div class="tw-flex tw-items-center tw-mb-6">
          <label class="tw-font-medium">Sito Web</label>
          <input-field
            class="tw-ml-4 !tw-w-96"
            type="text"
            v-model="customer.website"
            placeholder="Inserisci il sito web"
          ></input-field>
        </div>
      </v-col>
      <v-col class="!tw-w-1/2 font2">
        <div class="tw-mb-6">
          <label class="tw-font-medium !tw-mb-4">Note</label>
          <v-textarea
            type="text"
            height=600
            class="!tw-rounded-xl"
            v-model="customer.notes"
            placeholder="Inserisci il sito web"
            filled
            flat
            background-color="white"
          ></v-textarea>
        </div>
      </v-col>
    </v-row>

    <v-alert
      elevation="7"
      :type="response_type"
      :value="success"
      class="onsuccess"
    >
      {{ success_text }}
    </v-alert>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "Customer",
  components: {},

  data() {
    return {
      isLoading: {
        actions: false,
        saving: false,
      },
      actions_dialog: false,
      fab: false,
      response_type: "success",
      tabs: "mobile-tabs-5-1",
      customer: {},
      canEdit: undefined,
      isDisabled: false,
      overlay: "",
      customers: [],
      dialog: false,
      time: "",
      date: "",
      timestamp: "",
      uploadPercentage: 0,
      success: false,
      success_text: "",
      loading: false,
    };
  },
  beforeCreate: function () {
    axios.get("customer/" + this.$route.params.id).then((response) => {
      if (response.data.status != "logged_in") {
        this.$router.push("/login").catch(() => {});
      }
      this.getData();
    });
  },
  methods: {
    getData: function () {
      axios.get("customer/" + this.$route.params.id).then((response) => {
        this.customer = response.data.customer;
      });
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      const time = today.getHours() + ":" + today.getMinutes();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
      this.date = date;
      this.time = time;
    },

    goBack: function () {
      this.$router.back();
    },
    displayMessage: function (text, type) {
      this.response_type = type;
      this.success_text = text;
      this.success = true;
      setTimeout(() => (this.success = false), 1800);
    },
    updateCustomer: function () {
      const formData = new FormData();

      axios
        .patch("customer/" + this.customer._id, this.customer)
        .then((response) => {
          this.displayMessage("Dati salvati correttamente", "success");
          if (response.data.error) {
            this.displayMessage(response.data.error, "warning");
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: response.data.msg_type,
            });
          }
        });
    },
    deleteCustomer: function () {
      this.$confirm("Sei sicuro di voler cancellare il cliente?").then(
        (result) => {
          if (result) {
            axios.delete("customer/" + this.customer._id).then((response) => {
              this.$router.push("/customers").catch(() => {});
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.actions > button {
  margin: 5px;
}

.onsuccess,
.testolo {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.input-block {
  border: 1px rgb(190, 190, 190) solid;
  width: 100%;
  border-radius: 4px;
}

.cont-data {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.185);
  height: 75%;
  border-radius: 10px;
  box-sizing: border-box;
}

.full-height {
  height: 20%;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 50%;
}

.input-data {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.generic-info {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.data-info {
  width: 50%;
  flex-basis: auto !important;
  box-sizing: border-box;
}
p {
  padding: 0;
  margin: 0;
}

label {
  margin-right: 5px;
}

.actions {
  position: absolute;
  width: 20%;
  height: 75px;
  left: 40%;
  bottom: 5px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-action {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5px;
}

.icon-action {
  height: 100%;
  width: 100%;
}

.smaller {
  height: 40px !important;
  width: 40px !important;
}

.icon {
  width: 50px;
  height: 50px;
}
</style>
