<template>
  <v-tooltip :text="queue ? 'Rimuovi dalla coda' : 'Aggiungi alla coda'" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="toggleQueue"
        icon
        class="!tw-text-white !tw-bg-azure tw-rounded-xl"
        ><v-icon>{{ queue ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
      </v-btn>
    </template>
    <ResponseNotification />
  </v-tooltip>
</template>

<script>
import axios from "../../../plugins/axios";

export default {
  name: "ToggleQueue",
  props: {
    queue: Number,
    _id: Number,
  },
  methods: {
    toggleQueue() {
      this.$confirm(
        `Sicuro di voler ${
          this.queue ? "rimuovere" : "aggiungere"
        } la produzione alla coda?`
      ).then((res) => {
        if (res) {
          axios
            .patch(`stock-productions/${this._id}/queue`, {
              queue: this.queue == 0 ? 1 : 0,
            }, {
              headers: {
                "Content-Type": "application/json",
              }
            })
            .then(() => {
              !this.queue
                ? this.$store.dispatch("notificate", {
                    text: "Aggiunto alla coda",
                    type: "success",
                  })
                : this.$store.dispatch("notificate", {
                    text: "Rimosso dalla coda",
                    type: "success",
                  });
              this.$emit("updateQueue");
            })
            .catch((err) => {
              console.log(err);
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore",
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>