<template>
  <div>
    <primary-btn
    icon="mdi-account-plus"
    square="true"
    @click.native="isVisible = true"
    ></primary-btn>
    <v-dialog v-model="isVisible" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Nuovo cliente</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome Cliente*"
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Indirizzo"
                  v-model="address"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Città" v-model="city"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="cap" label="CAP"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="province"
                  label="Provincia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Stato" v-model="country"></v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field label="Email" v-model="email"></v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field label="Telefono" v-model="phone"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Riferimento" v-model="rif"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Sito Web" v-model="site"></v-text-field>
              </v-col>
              <v-col cols="12" >
                <v-textarea label="Note" v-model="notes"></v-textarea>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isVisible = false">
            Annulla
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createCustomer"
            :loading="loading"
          >
            Crea
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseNotification />
  </div>
</template>

<script>
import axios from "../../../plugins/axios";

export default {
  name: "CustomerModal",
  data() {
    return {
      isVisible: false,
      name: null,
      address: null,
      city: null,
      cap: null,
      province: null,
      country: null,
      email: null,
      phone: null,
      rif: null,
      site: null,
      notes: null,
      loading: false,
    };
  },
  methods: {
    emitNewCustomer(newCustomer, customers) {
      this.$emit("emitNewCustomer", { customer: newCustomer, customers: customers });
    },
    validateData() {
      if (!this.name) {
        this.$store.dispatch("notificate", {
          text: "Inserisci almeno il nome",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    createCustomer() {
      this.loading = true;
      if (!this.validateData()) return;
      axios
        .post("customer", {
          name: this.name,
          address: this.address,
          city: this.city,
          cap: this.cap,
          province: this.province,
          country: this.country,
          email: this.email,
          phone: this.phone,
          rif: this.rif,
          site: this.site,
          notes: this.notes,
        })
        .then((res) => {
          this.emitNewCustomer(res.data.customer, res.data.customer_list);
          this.$store.dispatch("notificate", {
            text: "Nuovo cliente creato",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("notificate", {
            text: err.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.isVisible = false;
        });
    },
  },
};
</script>