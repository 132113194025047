<template>
  <div v-if="items.length > 0" class="tw-w-full tw-h-full tw-bg-white  tw-px-4" ref="scroller">
    <div class="tw-relative">
        <div class="tw-text-2xl tw-font-bold tw-mb-2 !tw-z-[5001] tw-bg-white tw-w-full tw-p-4 tw--mx-4">Timeline</div>
    </div>
    
    <v-timeline side="end">
      <v-timeline-item
        v-for="item in items"
        :key="item.timestamp"
        color="blue-grey"
        :left="displayUser(item.username) == 'Tu'"
        :right="displayUser(item.username) != user.username"
      >
        <v-card>
          <v-card-title class="tw-text-lg !tw-p-2">{{ displayUser(item.username) }}</v-card-title>
          <v-card-text v-if="item.message" class="tw-text-dark !tw-p-2 !tw-leading-4"><b>Note: {{ item.message }}</b></v-card-text>
          <v-card-text class="tw-pt-0 !tw-p-2"><b>Azione:</b> {{ item.action }}</v-card-text>
          <div class="tw-flex !tw-p-2 tw-opacity-60" >
            <v-icon size="14" class="!tw-text-custom-blue">mdi-clock</v-icon>
            <div class="tw-ml-2  tw-font-bold tw-text-sm tw-text-custom-blue">
                {{ formatDate(item.datetime) }}
                
            </div>
          </div>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

export default {
  name: "supplierTimeline",
  props: ["timeline"],
  computed: {
    items() {
      return [...this.timeline].sort((a, b) => b.timestamp - a.timestamp);
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    displayUser(user) {
      return this.user.username == user ? "Tu" : user;
    },
    formatDate(timestamp) {
      const date = moment(timestamp);
      return date.format("DD-MM-YY HH:mm");
    },
    scrollToBottom() {
      try{
        this.$nextTick(() => {
          this.$emit("scrollToBottom");
        });
      }
      catch(e){}

    },
  },
  watch: {
    items: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>
