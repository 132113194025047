<template>
    <v-row class="!tw-p-2 tw-rounded-xl tw-w-fit tw-bg-white !tw-m-0 !tw-mb-4">
        <v-col cols="10">
            {{filename.name}}
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
            <v-icon @click="downloadFile(id_project,filename)" class="download">mdi-download</v-icon>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios"

export default {
    name: "BoxFile",
    props: ["id_project","file"],
    data(){
        return {
        }
    },
    computed: {
        filename(){
            console.log(this.file)
            return {name: this.file.replace(/_/g, " ")}
        }
    },
    methods: {
        downloadFile(idp,file){
            let formData = new FormData()
            formData.append("id",idp)
            formData.append("selector","bquotation")
            formData.append("filename",JSON.stringify(file))
            axios.post("download-file/", formData)
            .then((response) => {
                let blob = new Blob([response.data], { type: 'application/octet-stream' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = file.name.replace(/_/g, " ")
                link.click()
                
            }) 
        }
    }
}

</script>

<style scoped>


.download {
    cursor: pointer;
}

</style>