<template>
  <div>
    <v-btn color="primary" @click="isVisible = true">Confronta file</v-btn>
    <v-dialog v-model="isVisible" max-width="800px">
      <v-card>
        <v-card-title class="headline">
          <span class="headline">Confronta file</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <input
                type="file"
                @change="onFileChange($event, 1)"
                accept="pdf/*"
              />
              <div>
                <img :src="selectedImage1" ref="image1" />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <input
                type="file"
                @change="onFileChange($event, 2)"
                accept="pdf/*"
              />
              <div class="cropper-container">
                <img :src="selectedImage2" ref="image2" />
              </div>
            </v-col>
            <div v-if="result">
              <img :src="result" />
            </div>
          </v-row>
          <v-btn v-if="cropper1 && cropper2" color="primary" @click="cropImages"
            >Crop Images</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isVisible = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "FileCompareTool",
  data() {
    return {
      isVisible: false,
      selectedImage1: null,
      selectedImage2: null,
      cropper1: null,
      cropper2: null,
      result: null,
    };
  },
  watch: {
    selectedImage1(newVal) {
      console.log("IMMAGINE", newVal);
      this.initializeCropper("image1", "cropper1");
    },
    selectedImage2(newVal) {
      this.initializeCropper("image2", "cropper2");
    },
  },

  methods: {
    blobToFile(theBlob, fileName) {
      var file = new File([theBlob], fileName, {
        lastModified: Date.now(),
        type: "image/png",
      });
      return file;
    },
    async onFileChange(e, index) {
      const files = e.target.files;
      if (files && files.length > 0) {
        const file = files[0];

        if (file.type === "application/pdf") {
          // If the file is a PDF, send it to the server for conversion
          const formData = new FormData();
          formData.append("pdf", file);

          try {
            const response = await axios.post("file-convert", formData, {
              responseType: "blob", // Expect a blob response
            });

            // Assuming the server returns the URL of the converted image
            const imageUrl = URL.createObjectURL(response.data);
            console.log(imageUrl);

            // Set the image URL to the corresponding selectedImage
            this[`selectedImage${index}`] = imageUrl;
          } catch (error) {
            console.error("Error converting file:", error);
          }
        } else if (file.type.startsWith("image/")) {
          // Handle image files as before
          const reader = new FileReader();
          reader.onload = (e) => {
            this[`selectedImage${index}`] = e.target.result;
            this.$nextTick(() => {
              this.initializeCropper(
                this.$refs[`image${index}`],
                `cropper${index}`
              );
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    initCropper(imageElement, cropperKey) {
      if (this[cropperKey]) {
        this[cropperKey].destroy();
      }
      this[cropperKey] = new Cropper(imageElement, {
        aspectRatio: 16 / 9,
        viewMode: 1,
      });
    },
    initializeCropper(imageElementRef, cropperKey) {
      const imageElement = this.$refs[imageElementRef];

      if (imageElement) {
        if (imageElement.complete && imageElement.naturalHeight !== 0) {
          this.initCropper(imageElement, cropperKey);
        } else {
          imageElement.onload = () => {
            this.initCropper(imageElement, cropperKey);
          };
        }
      } else {
        console.error("Image element not found");
      }
    },
    cropImages() {
      if (this.cropper1 && this.cropper2) {
        // Determine the dimensions for cropping
        const minCroppedWidth = Math.min(
          this.cropper1.getCroppedCanvas().width,
          this.cropper2.getCroppedCanvas().width
        );
        const minCroppedHeight = Math.min(
          this.cropper1.getCroppedCanvas().height,
          this.cropper2.getCroppedCanvas().height
        );

        // Ensure the dimensions respect the 16/9 aspect ratio
        const aspectRatio = 16 / 9;
        let croppedWidth, croppedHeight;
        if (minCroppedWidth / minCroppedHeight > aspectRatio) {
          // Width is the limiting factor
          croppedWidth = minCroppedWidth;
          croppedHeight = croppedWidth / aspectRatio;
        } else {
          // Height is the limiting factor
          croppedHeight = minCroppedHeight;
          croppedWidth = croppedHeight * aspectRatio;
        }

        // Crop both images to these dimensions
        const croppedBlob1 = this.cropper1
          .getCroppedCanvas({ width: croppedWidth, height: croppedHeight })
          .toBlob((blob1) => {
            const croppedBlob2 = this.cropper2
              .getCroppedCanvas({ width: croppedWidth, height: croppedHeight })
              .toBlob((blob2) => {
                this.compareFiles(blob1, blob2);
              });
          });
      }
    },
    compareFiles(fileone, filetwo) {
      let formData = new FormData();
      formData.append("fileone", this.blobToFile(fileone, "fileone.png"));
      formData.append("filetwo", this.blobToFile(filetwo, "filetwo.png"));
      axios
        .post("file-compare", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.result = "data:image/png;base64," + res.data.image;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
.cropper-container {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
}
</style>