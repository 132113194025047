<template>
    <div class="tw-w-full tw-z-50 tw-h-screen tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-justify-center tw-items-center tw-bg-grey">
        <v-progress-circular
        indeterminate
        color="#2F2F2F"
        ></v-progress-circular>
    </div>
</template>

<script>

export default {
    name:"LoadingAuth"
}


</script>

<style scoped>


</style>