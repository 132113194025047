<template>


  <v-container
    fluid
  >    
      <v-row class="d-flex justify-center">
        <v-col cols="6" class="d-flex justify-center">
          <v-timeline
            style="width: 100%"
            align-top
            dense
            v-if="timeline && timeline.length > 0"
          >
            <v-timeline-item
              color="blue"
              small
              v-for="(block, index) in timeline"
              :key="index"
            >
              <v-row class="pt-1">
                <v-col cols="2">
                  <strong>
                    {{block.date_time.split(" "[0])}}
                    <br>
                    {{block.date_time.split(" "[1])}}
                  </strong>
                </v-col>
                <v-col>
                  <p>{{block.who}} {{block.text}}</p>
                  <div class="text-caption">
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>



  </v-container>

</template>

<script>
import axios from 'axios'
const fs = require('fs');

  export default {
    name: 'Customers', 

    data (){
      return {
        timeout: 7000,
        fab: false,
        page: 1,
        itemsPerPage: 100,
        pageCount: 0,
        scrollPosition: 0,
        timeline: [],
        xml_obj : {
          job_name: "Test_produzione",
          job_id: 13250,
          job_qty: 56,
          job_filename: 'UNIKPACK_-_MARCHANDISE_1x3_MAG_MINOT_R2',
          job_status: 'READY_FOR_PRODUCTION'
        },
        }
      },
  created: function () {
            axios.get('timeline')
            .then((response) => {
              if (response.data.status != "logged_in"){
                this.$router.push('/login').catch(()=>{}); 
              }
              if (response.data.alert == 'not allowed'){
                  this.$router.push('/production').catch(()=>{}); 
              }            

              this.timeline = response.data.timeline
              
            })
      
    },


    methods: {

      createXml(){
        var xml_string = '<?xml version="1.0" encoding="utf-8"?><Task xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Name>'+ '__' + this.xml_obj.job_id + '__' + this.xml_obj.job_name +'</Name><JobID>'+ this.xml_obj.job_id +'</JobID><CutUNC>'+ '\\\\icaproject.icaimballaggi.it\\jacopoos\\files\\728\\production\\' + this.xml_obj.job_filename +'.ARD</CutUNC><Amount>'+ this.xml_obj.job_qty +'</Amount></Task>';
        var filename = this.xml_obj.job_name + "_" + this.xml_obj.job_name + ".xml";

        try { fs.writeFileSync("\\\\esko-ws\\Dati Lavori\\" + filename, xml_string, 'utf-8'); }
        catch(e) { console.log('Failed to save the file !'); }

      }


    },

    

}
  
  
</script>

<style scoped>


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .item {

    cursor: pointer;
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}



</style>
