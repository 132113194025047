<template>
  <v-container
    fluid
  >
  
    <v-row
    class="full-height"
    >
      <v-col cols="8" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Clienti ICA</h1>
          <input-field    
            class="ml-8 !tw-w-96"    
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca tra i clienti"
          ></input-field>
        </div>
      </v-col>   
      <v-col cols="4" class="no-pd tw-flex tw-justify-end tw-items-center tw-mb-4">
        <customer-modal @emitNewCustomer="newCustomer"></customer-modal>
      </v-col>
      <v-data-table
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :headers="headers"
      :items="customers"
      loading="true"
      loading-text="Sto caricando..."
      :items-per-page=9999999999999
      class="elevation-1 tw-cursor-pointer font2"
      height="100%"
      :search="search"
      @click:row="goTo"
      item-class="!tw-uppercase"
      >
      ></v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          color="#11797b"
          class="pag"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-row>
  </v-container>
  
</template>

<script>
import axios from 'axios'
import CustomerModal from '../components/ProjectComponents/NewCustomerProject/CustomerModal.vue'

  export default {
  components: { CustomerModal },
    name: 'Customers',

    data (){
      return {
        search: '',
        headers: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "Ragione Sociale", value: "name", sortable: true, filterable: true },
          { text: "Indirizzo", value: "address", sortable: false },
          { text: "Località", value: "location", sortable: false },
          { text: "CAP", value: "cap", sortable: false },
          { text: "Provincia", value: "province", sortable: false },
          { text: "Stato", value: "country", sortable: false },
          { text: "Telefono", value: "phone", sortable: false },
          { text: "Email", value: "email", sortable: true, filterable: true },
          { text: "Riferimento", value: "rif", sortable: false },
          { text: "Sito", value: "website", sortable: false },
        ],
        page: 1,
        itemsPerPage: 100,
        pageCount: 0,
        pagination: {
          rowsPerPage: 100,
          descending: false,
          sortBy: "name",
          page: 1
        },
        customers: []
      }
      },
  beforeCreate: function () {
            axios.get('customers')
            .then((response) => {
              if (response.data.status != "logged_in"){
                this.$router.push('/login').catch(()=>{}); 
              }
              if (response.data.alert == 'not allowed'){
                  this.$router.push('/production').catch(()=>{}); 
              }            
            })
      
    },

    mounted: function getCustomers() {
      axios.get('customers')
      .then((response) => {
        this.customers = response.data.customers;  
      })
      
    },
    methods: {     
      goTo(a){
        if (event.target.classList.contains('btn__content')) return;
        this.$router.push('/customer/'+a._id).catch(()=>{});

      },

      newCustomer(data){
        this.$router.push('/customer/'+data.customer._id).catch(()=>{});
      }

    }

}
  
  
</script>

<style scoped>

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48%;
  } 

</style>
