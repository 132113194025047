<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <form class="edituser-form">
        <v-card>
          <v-card-title>
            <span class="headline">Modifica utente</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  label="Nome utente"
                  v-model="user.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="user.email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.role"
                  :items="roles"
                  required
                  item-text="description"
                  item-value="name"
                  label="Ruolo"
                  :disabled="!!orgRole"
                ></v-select>
                <span v-if="orgRole" class="tw-text-gray-600">
                  Ruolo ereditato dalla organizzazione: {{ orgRole.description }}
                </span>
              </v-col>
              <v-col cols="12" v-if="user.role == 'supplier'">
                <v-select
                  item-text="username"
                  item-value="id"
                  v-model="user.machineSuppliers"
                  :items="machineSuppliersItems"
                  label="Seleziona i fornitori da associare"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="user.role == 'supplier'">
                <v-select
                  item-text="label"
                  item-value="value"
                  v-model="user.quotationType"
                  :items="quotationTypes"
                  required
                  label="Tipo di fornitore"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.org_id"
                  :items="orgs"
                  item-text="name"
                  item-value="id"
                  label="Organizzazione"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Annulla
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateUser"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <response-notification></response-notification>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import { checkForm } from "../plugins/utils";

export default {
  name: "EditUserDialog",
  props: ["userData", "roles", "agents", "machineSuppliersItems", "orgs"],
  data() {
    return {
      dialog: false,
      quotationTypes: [
        {
          value: "preventivi_stampa",
          label: "Preventivi stampa",
        },
        {
          value: "preventivi_fustella",
          label: "Preventivi fustella",
        },
      ],
      user: {
        id: "",
        username: "",
        email: "",
        role: "",
        quotationType: "",
        machineSuppliers: [],
        org_id: null,
      },
      orgRole: null,
    };
  },
  watch: {
    userData: {
      handler(val) {
        if (val) {
          this.user = { ...val };
          this.dialog = true;
          this.setOrgRole();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setOrgRole() {
      if (!this.user.role && this.user.org_id) {
        const org = this.orgs.find(o => o.id === this.user.org_id);
        if (org && org.role) {
          this.orgRole = this.roles.find(r => r.name === org.role);
          this.user.role = this.orgRole ? this.orgRole.name : "";
        } else {
          this.orgRole = null;
        }
      } else {
        this.orgRole = null;
      }
    },
    updateUser() {
      if (checkForm("edituser-form")) {
        axios
          .patch(`user/${this.user.id}`, this.user)
          .then((response) => {
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
            if (
              response.data.msg.type != "error" &&
              response.data.msg.type != "warning"
            ) {
              this.dialog = false;
              this.$emit("update-success");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.edituser-form {
  width: 100%;
}
</style>
