<template>
    <v-chip v-if="role == 'master'" @click="stopWorking" dark class="tw-animate-pulse" color="red">
        <p class="!tw-mb-0" v-if="hours != '00' && minutes != '00'">
            In lavorazione da {{hours}} ore e {{minutes}} minuti
        </p>
        <p class="!tw-mb-0" v-else>
            In lavorazione da {{minutes}} minuti
        </p>
        
        <v-icon  class="tw-mr-2" right>
          mdi-stop
        </v-icon>
    </v-chip>
    <v-chip v-else  dark class="tw-animate-pulse" color="red">
        <p class="!tw-mb-0" v-if="hours != '00' && minutes != '00'">
            In lavorazione da {{hours}} ore e {{minutes}} minuti
        </p>
        <p class="!tw-mb-0" v-else>
            In lavorazione da {{minutes}} minuti
        </p>
    </v-chip>
        
</template>

<script>
export default {
    name: 'WorkCounter',
    props: ['time', 'role'],
    data(){
        return {
            interval: null,      
            days:0,
            hours:0,
            minutes:0,
            seconds:0,
            intervals:{
                second: 1000,
                minute: 1000 * 60,
                hour: 1000 * 60 * 60,
                day: 1000 * 60 * 60 * 24
            }
        }
    },
    computed: {
        work_time(){
            
            var splitted_time = this.time.split(' ')[0].split('-')
            var splitted_date = this.time.split(' ')[1]
            return splitted_time[1] + "/" + splitted_time[0] + "/" + splitted_time[2] + " " + splitted_date + ":00"
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.updateDiffs();
        },1000);
        
        this.updateDiffs();
    },
    methods: {
        
        updateDiffs() {
            let diff = Math.abs(Date.now() - (new Date(this.work_time)).getTime());
            this.days = Math.floor(diff / this.intervals.day);
            diff -= this.days * this.intervals.day;
            this.hours = Math.floor(diff / this.intervals.hour);
            diff -= this.hours * this.intervals.hour;
            this.minutes = Math.floor(diff / this.intervals.minute);
            diff -= this.minutes * this.intervals.minute;
            this.seconds = Math.floor(diff / this.intervals.second);
        },
        stopWorking(){
            console.log("emmitting closing")
            this.$emit('stop-working')

        }
    }

}
</script>