<template>
    <v-dialog
    v-if="role == 'commerciale'"
      v-model="dialog_transfer"
      persistent
      max-width="600px"
      
    >


      <v-card>
        <v-card-title>
          <span class="headline">Non esiste un progetto associato nella tabella ICA.<br> Crearne uno?</span>
          
        
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_transfer = false"

          >
            Annulla
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmTransfer"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        elevation="7"
        :type="response_type"
        :value="success"
        class="onsuccess"

      >
      {{ success_text }}
      </v-alert>
    </v-dialog>        
</template>

<script>
import axios from 'axios'

export default {
    name: 'transferData',
    props: {
        id: {
            type: Number,
            default: undefined
        },
        estern: {
            type: String,
            default: ""
        },
        role: {
            type: String,
            default: ""
        },
        icaFiles: {
            type: Array,
            
        },
        customerFiles: {
            type: Array
        }
        

    },
    data(){
        return {    
          
          response_type: "success",
          success_text: "",
          success: false,
          project_id: this.id,
          esterno: this.estern,
          ica_files: this.icaFiles,
          customer_files: this.customerFiles,
          dialog_transfer: false,
          ica_file_transfer_form: [],
          customer_file_transfer_form: [],
          to_transfer:{
              "progettazione": 0,
              "ica_files": 0,
              "customer_files": 0,
              "talk":0
              },            
          file_transfer: {},
            
        }
    },
    methods:{
      displayMessage: function(text,type){

          this.response_type = type
          this.success_text = text
          this.success = true
          setTimeout(() => this.wait(), 1400);      


      },
      addFile(file,index,type){
          var files = []
          files[type][index] = file

          return files
      },
      confirmTransfer: function(){
          //this.project.to_transfer = this.to_transfer
          console.log(this.project_id)
          axios.post('transfer-data/' + this.project_id + "/" + this.esterno)
          .then((response) => {
            this.displayMessage(response.data.msg, "success")
            this.$emit("id_associato",response.data.id_associato)

          }) 
        },
        openDialog(){
            this.dialog_transfer = true
        },
        wait(){
            this.success = false
            this.dialog_transfer = false  
        }        
    }
}
</script>

<style scoped>

.form-ica {
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.onsuccess{
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: fit-content;
}
</style>