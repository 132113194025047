<template>
  <div>
    <div v-if="action == 'next'">
      <div>
        <v-btn v-if="supplier.status == 0" @click="isVisible = true"
          class="hover:tw-opacity-80 !tw-text-white !tw-bg-custom-blue tw-cursor-pointer">Richiedi preventivo</v-btn>
        <v-chip v-else @click="isVisible = true" :class="`!tw-text-white quotation_${supplier.salted_id}`"
          :color="_mapQuotationStatus(supplier).color">{{ _mapQuotationStatus(supplier).label }}</v-chip>
        <v-dialog max-height="80%" content-class="!tw-overflow-y-hidden" scrollable v-model="isVisible" persistent
          max-width="1200px">
          <div v-if="!isLoading" class="tw-flex tw-bg-white">
            <v-card class="tw-w-1/2 !tw-overflow-y-scroll tw-flex tw-flex-col">
              <v-card-title> </v-card-title>
              <v-card-text>
                <div class="tw-flex tw-w-full tw-justify-between">
                  <h3 class="tw-text-3xl tw-font-bold !tw-text-dark">
                    {{ getDialogTitle(supplier.status) }}
                  </h3>
                  <a
                    href="mailto:jacopo@icaimballaggi.it?subject=Feedback%20ICA%20Progest&body=Scrivi qua il problema riscontrato">
                    <v-btn class="!tw-bg-azure !tw-text-dark hover:!tw-opacity-80">Qualcosa non va?
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-card-text>

              <div class="tw-px-6">
                <div class="tw-text-xl">
                  Stampatore: {{ quotation.machine_supplier }}
                </div>
                <div v-if="user.role != 'supplier' && supplier.status == 0" class="tw-text-xl">
                  Fornitore: {{ supplier.supplier }}
                </div>
                <div v-if="quotation.rif_code" class="tw-text-xl">
                  {{ quotation.type == 'preventivi_stampa' ? 'Codice commessa' : 'Codice fornitore' }}: {{
                    quotation.rif_code }}
                </div>
                <div class="tw-text-xl">{{ quotation.type == 'preventivi_stampa' ? 'Materiale' : 'Macchina' }}: {{
                  quotation.tp }}</div>
                <div class="tw-text-xl">Resa: {{ quotation.resa }}</div>
                <div v-if="supplier.price" class="tw-text-xl">
                  Prezzo: €{{ supplier.price }}
                </div>

                <div v-if="supplier.status == 0">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Seleziona i file:
                  </div>
                  <div v-if="files.length == 0">
                    Non ci sono file da selezionare, caricali negli allegati
                  </div>
                  <div>
                    <div class="tw-mt-4 tw-mb-2">
                      Seleziona se caricare un file oppure utilizzare un link di
                      un provider esterno
                    </div>
                    <v-radio-group v-model="fileType">
                      <v-radio v-for="(file_type, n) in ['link', 'file']" :key="n" :label="`${file_type}`"
                        :value="file_type"></v-radio>
                    </v-radio-group>
                  </div>
                  <div v-if="fileType == 'file'">
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, index) in files"
                      :key="index" :fname="file" hasSelection="true" path="ica" />
                  </div>
                  <v-combobox v-else v-model="fileLinks" hide-selected
                    hint="Inserisci il link e premi invio per inserirlo" label="Aggiungi link" multiple persistent-hint
                    small-chips @input="checkLinkValidity">
                  </v-combobox>
                </div>

                <div v-if="supplier.status == 1">
                  <div v-if="supplier.icaFiles.length > 0" class="tw-text-xl tw-mt-4 tw-mb-2">
                    Master preventivo:
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, index) in supplier.icaFiles"
                    :key="index" :fname="file" path="ica" />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Inserisci prezzo:
                  </div>
                  <v-text-field :disabled="!isActionEnabled" type="number" filled
                    v-model="supplier.price"></v-text-field>
                </div>

                <!-- Status 2: Confirm Installation / Upload DXF -->
                <!-- Status 2: Confirm Installation / Upload Files -->
                <div v-if="supplier.status == 2">
                  <div v-if="supplier.icaFiles.length > 0" class="tw-text-xl tw-mt-4 tw-mb-2">
                    Master Preventivo:
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, index) in supplier.icaFiles"
                    :key="index" :fname="file" path="ica" />

                  <!-- Check if defFiles are uploaded -->
                  <div v-if="supplier.defFiles.length === 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">
                      Carica i file definitivi:
                    </div>
                    <!-- File upload interface for defFiles -->
                    <div>
                      <div class="tw-mt-4 tw-mb-2">
                        Seleziona se caricare un file oppure utilizzare un link
                        di un provider esterno
                      </div>
                      <v-radio-group :disabled="!isActionEnabled" v-model="fileType">
                        <v-radio v-for="(file_type, n) in ['link', 'file']" :key="n" :label="`${file_type}`"
                          :value="file_type"></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="fileType == 'file'">
                      <v-file-input :disabled="!isActionEnabled" ref="fileInput" small-chips @change="handleFileChange"
                        :rules="rules" required multiple filled label="Carica file definitivi">
                      </v-file-input>
                      <v-combobox :disabled="!isActionEnabled" readonly deletable-chips disable-lookup small-chips
                        v-model="filesListString" multiple no-data-text="Nessun file caricato">
                        <template v-slot:selection="{ item, index }">
                          <v-chip :key="index" small>
                            {{ item }}
                            <v-icon
                              class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                              @click="viewFile(item)" small>mdi-eye</v-icon>
                            <v-icon
                              class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                              @click="onInput(item)" small>mdi-close</v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <v-combobox v-else :disabled="!isActionEnabled" v-model="fileLinks" hide-selected
                      hint="Inserisci il link e premi invio per inserirlo" label="Aggiungi link" multiple
                      persistent-hint small-chips @input="checkLinkValidity">
                    </v-combobox>
                  </div>
                  <!-- If defFiles are uploaded -->
                  <div v-else>
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                      :key="i" :fname="file" path="quotation" />

                    <!-- If quotation.type is 'preventivi_fustella' -->
                    <div v-if="quotation.type == 'preventivi_fustella'">
                      <!-- Check if dxfFiles are uploaded -->
                      <div v-if="supplier.dxfFiles.length === 0">
                        <div v-if="user.role != 'master'" class="tw-text-xl tw-mt-4 tw-mb-2">
                          In attesa del caricamento dei file DXF da parte del grafico.
                        </div>
                        <div v-if="fileType == 'file' && user.role == 'master'" class="tw-text-xl tw-mt-4 tw-mb-2">
                          <div class="tw-text-xl tw-mt-4 tw-mb-2">
                            Carica i file DXF:
                          </div>
                          <v-file-input :disabled="!isActionEnabled" ref="fileInput" small-chips
                            @change="handleFileChange" :rules="rules" required multiple filled label="Carica file DXF">
                          </v-file-input>
                          <v-combobox :disabled="!isActionEnabled" readonly deletable-chips disable-lookup small-chips
                            v-model="filesListString" multiple no-data-text="Nessun file caricato">
                            <template v-slot:selection="{ item, index }">
                              <v-chip :key="index" small>
                                {{ item }}
                                <v-icon
                                  class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                                  @click="viewFile(item)" small>mdi-eye</v-icon>
                                <v-icon
                                  class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                                  @click="onInput(item)" small>mdi-close</v-icon>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </div>
                      </div>
                      <!-- If dxfFiles are uploaded -->
                      <div v-else>
                        <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                        <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                          :key="i" :fname="file" path="quotation" />
                      </div>
                    </div>
                  </div>
                </div>


                <!-- Status 3: Confirm Order -->
                <div v-if="supplier.status == 3">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, index) in supplier.defFiles"
                    :key="index" :fname="file" path="quotation" />

                  <div v-if="supplier.draftFiles.length == 0">
                    <div class="tw-mt-4 tw-mb-2">
                      Per inviare la bozza seleziona se caricare un file oppure
                      utilizzare un link di un provider esterno
                    </div>
                    <v-radio-group :disabled="!isActionEnabled" v-model="fileType">
                      <v-radio v-for="(file_type, n) in ['link', 'file']" :key="n" :label="`${file_type}`"
                        :value="file_type"></v-radio>
                    </v-radio-group>
                  </div>

                  <div class="tw-text-lg tw-font-bold" v-else>
                    Bozza caricata:
                  </div>

                  <div v-if="fileType == 'file' && supplier.draftFiles.length == 0">
                    <v-file-input :disabled="!isActionEnabled" ref="fileInput" small-chips @change="handleFileChange"
                      :rules="rules" required multiple filled label="Carica file definitivi">
                    </v-file-input>
                    <v-combobox :disabled="!isActionEnabled" readonly deletable-chips disable-lookup small-chips
                      v-model="filesListString" multiple no-data-text="Nessun file caricato">
                      <template v-slot:selection="{ item, index }">
                        <v-chip :key="index" small>
                          {{ item }}

                          <v-icon
                            class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="viewFile(item)" small>mdi-eye</v-icon>
                          <v-icon
                            class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="onInput(item)" small>mdi-close</v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                  <v-combobox v-else-if="
                    fileType == 'link' && supplier.draftFiles.length == 0
                  " v-model="fileLinks" hide-selected hint="Inserisci il link e premi invio per inserirlo"
                    label="Aggiungi link" multiple persistent-hint small-chips @input="checkLinkValidity"
                    :disabled="!isActionEnabled">
                  </v-combobox>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in supplier.draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="supplier._type == 'order'">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">
                      Inserisci prezzo:
                    </div>
                    <v-text-field type="number" filled v-model="supplier.price"
                      :disabled="!isActionEnabled"></v-text-field>
                  </div>
                  <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status == 4">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                    :key="i" :fname="file" path="quotation" />

                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in _draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status == 5">
                  <div class="tw-mt-4 tw-mb-2">Carica il DDT</div>
                  <div v-if="fileType == 'file'">
                    <v-file-input ref="fileInput" small-chips @change="handleFileChange" :rules="rules" required
                      multiple filled label="Carica file definitivi">
                    </v-file-input>
                    <v-combobox :disabled="!isActionEnabled" readonly deletable-chips disable-lookup small-chips
                      v-model="filesListString" multiple no-data-text="Nessun file caricato">
                      <template v-slot:selection="{ item, index }">
                        <v-chip :key="index" small>
                          {{ item }}

                          <v-icon
                            class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="viewFile(item)" small>mdi-eye</v-icon>
                          <v-icon
                            class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="onInput(item)" small>mdi-close</v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                    :key="i" :fname="file" path="quotation" />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in _draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status == 6">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.ddtFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                    :key="i" :fname="file" path="quotation" />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in _draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="quotation.type == 'preventivi_fustella'">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status == 7">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.ddtFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                    :key="i" :fname="file" path="quotation" />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in _draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status == 8">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.ddtFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.defFiles"
                    :key="i" :fname="file" path="quotation" />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, j) in _draftFiles"
                    :key="j + files.length" :fname="file" path="quotation" />
                  <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati DXF:</div>
                    <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                      :key="i" :fname="file" path="quotation" />
                  </div>
                </div>

                <div v-if="supplier.status != 8" class="tw-text-xl tw-mt-4 tw-mb-2">
                  Note:
                </div>
                <v-textarea :disabled="!isActionEnabled && supplier.status != 0" v-if="supplier.status != 8" filled
                  v-model="message"></v-textarea>
              </div>
              <v-card-actions class="tw-flex-1 tw-flex tw-items-end">
                <v-spacer></v-spacer>
                <v-btn @click="archiveQuotation(8)" text v-if="user.role != 'supplier' && supplier.status != 8"
                  class="!tw-text-orange">Archivia</v-btn>
                <v-btn @click="disputeDraft" text v-if="
                  user.role != 'supplier' &&
                  supplier.status == 4 &&
                  isActionEnabled
                " class="!tw-text-custom-red">Contesta</v-btn>

                <v-btn color="gray darken-1" text @click="isVisible = false">
                  Annulla
                </v-btn>
                <v-btn @click="updateQuotation(supplier.status, false, true)" text v-if="
                  user.role == 'supplier' &&
                  supplier.status == 3 &&
                  isActionEnabled
                  && quotation.type == 'preventivi_stampa'
                " color="blue darken-1" class="!tw-text-custom-red">Salva (senza avanzare)</v-btn>
                <v-btn v-if="
                  (isActionEnabled &&
                    supplier.status != 7 &&
                    supplier.status != 8) ||
                  supplier.status == 0 ||
                  (supplier.status == 2 && user.role != 'supplier')
                " @click="updateQuotation(supplier.status + 1)" color="green darken-1" text>
                  {{ getButtonLabel(supplier.status) }}
                </v-btn>
                <v-btn v-if="supplier.status == 5 && user.role != 'supplier'"
                  @click="updateQuotation(supplier.status + 1)" color="green darken-1" text>
                  {{ getButtonLabel(supplier.status) }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <div class="tw-w-1/2 !tw-overflow-y-scroll" id="timeline">
              <SupplierTimeline v-if="isTimelineVisible" :timeline="supplier.timeline"
                @scrollToBottom="scrollToBottom" />
            </div>
          </div>
          <v-card v-else class="!tw-h-64 !tw-flex !tw-justify-center !tw-items-center">
            <v-progress-circular indeterminate color="#2F2F2F"></v-progress-circular>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div v-else-if="action == 'view'">
      <div>
        <v-icon @click="isVisible = true"
          class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-eye</v-icon>
        <v-dialog v-model="isVisible" persistent max-width="800px">
          <v-card>
            <v-card-title> </v-card-title>
            <v-card-text>
              <h3 class="tw-text-3xl tw-font-bold !tw-text-dark">
                Recap Preventivo
              </h3>
            </v-card-text>
            <div class="tw-px-6">
              <div class="tw-text-xl">
                Stampatore: {{ quotation.machine_supplier }}
              </div>
              <div v-if="quotation.rif_code" class="tw-text-xl">
                Codice commessa: {{ quotation.rif_code }}
              </div>
              <div class="tw-text-xl">Materiale: {{ quotation.tp }}</div>
              <div class="tw-text-xl">Resa: {{ quotation.resa }}</div>
              <div v-if="supplier.icaFiles.length > 0" class="tw-text-xl tw-mt-4 tw-mb-2">
                Master Preventivo:
              </div>
              <div v-if="files.length == 0">
                Non ci sono file da visualizzare
              </div>
              <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, index) in supplier.icaFiles"
                :key="index" :fname="file" path="ica" />
              <div v-if="quotation.type == 'preventivi_fustella' && supplier.dxfFiles.length != 0">
                <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Tecnici:</div>
                <FileItem @file-selected="handleFileSelection" :_id="_id" v-for="(file, i) in supplier.dxfFiles"
                  :key="i" :fname="file" path="quotation" />
              </div>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="isVisible = false">
                Chiudi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <response-notification></response-notification>
  </div>
</template>

<script>
import FileItem from "../FileItem.vue";
import SupplierTimeline from "./supplierTimeline.vue";
import axios from "../../plugins/axios";
import { mapQuotationStatus } from "../../plugins/utils";

export default {
  components: { FileItem, SupplierTimeline },
  name: "supplierModal",
  props: [
    "read_only",
    "supplier",
    "quotation",
    "supplierIndex",
    "quotationIndex",
    "files",
    "_id",
    "_draftFiles",
    "action",
    "isActionEnabled",
    "index"
  ],
  data() {
    return {
      isResetting: false,
      isTimelineVisible: false,
      message: "",
      fileLinks: [],
      fileType: "file",
      isLoading: false,
      fileErrors: [],
      isVisible: false,
      selectedFiles: [],
      rules: [
        (value) =>
          !value ||
          value.size < 31457280 ||
          "Il file deve essere inferiore a 30 MB, se superiore allega nelle note un link di WeTransfer o altri provider",
      ],
      // Local state for managing files uploaded in the form
      uploadedFiles: [],
    };
  },
  watch: {
    isVisible(newValue) {
      if (!newValue) {
        // Reset data when dialog is closed
        this.resetFormData();
      } else {
        this.isTimelineVisible = true;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    filesListString() {
      // Returns the list of file names to display in the combo box
      return this.uploadedFiles.map((file) => file.name);
    },
  },
  methods: {
    resetFormData() {
      this.uploadedFiles = [];
      this.fileLinks = [];
      this.selectedFiles = [];
      this.message = "";
      this.isResetting = false;
      this.isTimelineVisible = false;
    },
    scrollToBottom() {
      try {
        let timeline = document.getElementById("timeline");
        timeline.scrollTop = timeline.scrollHeight;
      } catch (error) {
        console.log(error);
      }
    },
    viewFile(item) {
      let file = this.uploadedFiles.find((fileObj) => fileObj.name === item);
      if (file) {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
    },
    onInput(removedFileName) {
      // Remove the file from uploadedFiles
      this.uploadedFiles = this.uploadedFiles.filter(
        (fileObj) => fileObj.name !== removedFileName
      );
    },
    getDialogTitle(state) {
      switch (state) {
        case 0:
          return "Richiedi preventivo";
        case 1:
          return "Nuovo preventivo";
        case 2:
          if (this.quotation.type === 'preventivi_fustella') {
            if (this.supplier.defFiles.length > 0 && this.supplier.dxfFiles.length === 0) {
              return "Caricamento File Tecnici";  // Quando ci sono i defFiles ma non i dxfFiles
            } else {
              return "Conferma e richiedi Tecnici";  // Quando non ci sono defFiles o dxfFiles
            }
          } else {
            return "Conferma impianto";
          }
        case 3:
          return "Conferma ordine";
        case 4:
          return "Conferma Bozza";
        case 5:
          return "Conferma spedizione";
        case 6:
          return "Conferma ricezione IS";
        case 7:
          return "Ordine completato";
        default:
          return "";
      }
    },
    getButtonLabel(state) {
      return "Conferma";
    },
    validateFiles(isDraft = false) {
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      let result = true;

      if (this.fileType === "file") {
        // Check if files are uploaded based on supplier status
        switch (this.supplier.status) {
          case 0:
            if (this.selectedFiles.length === 0) {
              result = false;
            }
            break;
          case 2:
            if (
              this.uploadedFiles.length === 0 &&
              !this.hasExistingFiles('defFiles')
            ) {
              result = false;
            } else if (
              this.quotation.type === 'preventivi_fustella' &&
              !this.hasExistingFiles('dxfFiles') &&
              this.supplier.defFiles.length > 0 &&
              this.uploadedFiles.length === 0
            ) {
              result = false;
            }
            break;
          case 3:
            if (
              this.uploadedFiles.length === 0 &&
              !this.hasExistingFiles('draftFiles') &&
              !isDraft
            ) {
              result = false;
            }
            break;
          case 5:
            if (
              this.uploadedFiles.length === 0 &&
              !this.hasExistingFiles('ddtFiles') &&
              this.user.role === "supplier"
            ) {
              result = false;
            }
            break;
          default:
            break;
        }

        if (!result) {
          this.$store.dispatch("notificate", {
            text: "Non hai allegato alcun file",
            type: "warning",
          });
        }
      } else if (this.fileType === "link") {
        if (this.fileLinks.length === 0) {
          this.$store.dispatch("notificate", {
            text: "Non hai inserito alcun link",
            type: "warning",
          });
          result = false;
        } else {
          for (const link of this.fileLinks) {
            if (!urlPattern.test(link)) {
              this.$store.dispatch("notificate", {
                text: "Link non valido",
                type: "warning",
              });
              result = false;
              break;
            }
          }
        }
      }

      return result;
    },
    hasExistingFiles(fileKey) {
      return this.supplier[fileKey] && this.supplier[fileKey].length > 0;
    },
    checkLinkValidity() {
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      this.invalidLinks = this.fileLinks.filter((link) => !urlPattern.test(link));

      if (this.invalidLinks.length > 0) {
        this.$store.dispatch("notificate", {
          text: "Link non valido",
          type: "warning",
        });
        this.fileLinks = this.fileLinks.filter((link) => urlPattern.test(link));
      }
    },
    _mapQuotationStatus(item) {
      return mapQuotationStatus(item, this.quotation.type);
    },
    handleFileChange(e) {
      if (this.isResetting) {
        this.isResetting = false;
        return;
      }

      this.fileErrors = [];

      if (e.length > 0) {
        for (const file of e) {
          if (file.size > 31457280) {
            this.fileErrors.push(file);
          }
        }
      }

      if (this.fileErrors.length > 0) {
        this.$store.dispatch("notificate", {
          text: "File troppo grande, allega nelle note un link di WeTransfer o altri provider",
          type: "warning",
        });
        this.$refs.fileInput.reset();
        return;
      }

      // Add the new files to uploadedFiles
      this.uploadedFiles = this.uploadedFiles.concat(e);

      // Reset the file input
      this.isResetting = true;
      this.$refs.fileInput.reset();
    },
    handleFileSelection(data) {
      if (data.action) {
        this.selectedFiles = [...this.selectedFiles, data.file];
      } else {
        this.selectedFiles = this.selectedFiles.filter(
          (item) => item !== data.file
        );
      }
    },
    async disputeDraft() {
      try {
        const res = await this.$confirm(
          `Sei di voler contestare la bozza? ${!this.message ? "<br>Non hai inserito alcun messaggio" : ""
          }`
        );
        if (!res) {
          return;
        }
      } catch (error) {
        // Handle rejection or error from the confirm dialogue here
        return;
      }
      this.updateQuotation(this.supplier.status - 1, true);
    },
    async archiveQuotation(newStatus) {
      this.$store.dispatch("archiveQuotation", this.supplier);
      if (newStatus === 8) {
        try {
          const res = await this.$confirm(
            "Sei sicuro di voler archiviare l'ordine?"
          );
          if (!res) {
            return;
          }
        } catch (error) {
          return;
        }
      }
      this.isLoading = true;
      const formData = new FormData();
      let supplierCopy = { ...this.supplier };
      let quotation = { ...this.quotation };
      supplierCopy["status"] = newStatus;

      const dataToSend = {
        quotation: quotation,
        supplier: supplierCopy,
        existingFiles: this.selectedFiles,
        isDisputed: this.isDisputed,
        message: this.message,
      };

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(
          key,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      });

      axios
        .patch(
          `quotation/${this._id}/${this.quotation.salted_id}/${this.supplier.salted_id}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          this.$store.dispatch("notificate", {
            text: "Preventivo archiviato correttamente",
            type: "success",
          });
          this.isVisible = false;
          supplierCopy["status"] = newStatus;
        })
        .catch((err) => {
          this.$store.dispatch("notificate", {
            text: "C'è stato un errore, contatta un amministratore",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateQuotation(newStatus, isDisputed = false, isDraft = false) {
      if (this.validateFiles(isDraft)) {
        if (newStatus === 1) {
          try {
            const res = await this.$confirm(
              "Sei sicuro di voler inviare il preventivo al fornitore?"
            );
            if (!res) {
              return;
            }
          } catch (error) {
            return;
          }
        }



        if (
          (newStatus === 2 ||
            (newStatus === 4 &&
              this.supplier._type === "order" &&
              this.supplier.price === 0 &&
              !isDraft)) &&
          (!this.supplier.price || this.supplier.price <= 0)
        ) {
          this.$store.dispatch("notificate", {
            text: "Non hai inserito un prezzo",
            type: "warning",
          });
          return;
        }

        if (this.fileErrors.length > 0) {
          this.$store.dispatch("notificate", {
            text:
              "File " +
              this.fileErrors +
              " grande, allega nelle note un link di WeTransfer o altri provider",
            type: "warning",
          });
          return;
        }

        let quotation = { ...this.quotation };
        let supplierCopy = { ...this.supplier };
        supplierCopy["status"] = newStatus;
        if (newStatus == 1) {
          let newFiles = [];
          if (this.fileType == "link") {
            this.fileLinks.forEach((item) => {
              newFiles.push({ name: item, type: "link", size: 0 });
            });
          }
          supplierCopy.icaFiles =
            this.fileType == "file" ? this.selectedFiles : newFiles;
        }
        const formData = new FormData();

        // Handle file uploads based on status
        if (this.uploadedFiles.length > 0) {
          const fileKey = this.getFileKeyForStatus();
          for (const file of this.uploadedFiles) {
            formData.append(fileKey, file);
          }
        }

        if (this.fileType === "link" && this.fileLinks.length > 0) {
          let newFiles = this.fileLinks.map((item) => ({
            name: item,
            type: "link",
            size: 0,
          }));
          const fileKey = this.getFileKeyForStatus();
          supplierCopy[fileKey] = newFiles;
        }

        const dataToSend = {
          quotation: quotation,
          supplier: supplierCopy,
          existingFiles: this.selectedFiles,
          isDisputed: isDisputed,
          message: this.message,
          isDraft: isDraft,
        };

        Object.entries(dataToSend).forEach(([key, value]) => {
          formData.append(
            key,
            typeof value === "object" ? JSON.stringify(value) : value
          );
        });

        this.isLoading = true;
        axios
          .patch(
            `quotation/${this._id}/${this.quotation.salted_id}/${this.supplier.salted_id}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            this.$store.dispatch("notificate", {
              text: "Preventivo aggiornato correttamente",
              type: "success",
            });
            this.isVisible = false;
            supplierCopy["status"] = newStatus;
          })
          .catch((err) => {
            this.$store.dispatch("notificate", {
              text: "C'è stato un errore, contatta un amministratore",
              type: "error",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getFileKeyForStatus() {
      // Returns the appropriate key for file uploads based on supplier status and quotation type
      switch (this.supplier.status) {
        case 0:
          return "icaFiles";
        case 2:
          if (this.supplier.defFiles.length === 0) {
            return "defFiles";
          } else if (
            this.quotation.type === "preventivi_fustella" &&
            this.supplier.defFiles.length > 0 &&
            this.supplier.dxfFiles.length === 0
          ) {
            return "dxfFiles";
          }
          break;
        case 3:
          return "draftFiles";
        case 5:
          return "ddtFiles";
        default:
          return "";
      }
    },
  },
};
</script>


<style scoped>
/* .v-dialog__content--active {
  justify-content: flex-start !important;
  margin-left: 36%;
}

@media (max-width: 2200px) and (min-width: 900px) {
  .v-dialog__content--active {
    margin-left: 18%;
    width: 600px;
  }
}

@media (max-width: 1600px) and (min-width: 769px) {
  .v-dialog__content--active {
    margin-left: 0;
    width: 600px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .v-dialog__content--active {
    margin-left: 3%;
  }
}

@media (max-width: 576px) {
  .v-dialog__content--active {
    margin-left: 2%;
  }
} */
</style>