var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"!tw-p-0 tw-items-center tw-mb-4",attrs:{"cols":"8"}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('h1',{staticClass:"tw-text-3xl tw-font-bold tw-justify-self-start"},[_vm._v("Contestazioni")]),_c('input-field',{staticClass:"ml-8 !tw-w-96",attrs:{"type":"text","icon":"mdi-magnify","placeholder":"Cerca tra le contestazioni"},on:{"input":_vm.saveSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1 item",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.projects,"loading":"true","loading-text":"Sto caricando...","items-per-page":_vm.itemsPerPage,"height":"100%","search":_vm.search,"page":_vm.page},on:{"click:row":_vm.goTo,"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.is_editing",fn:function(ref){
var item = ref.item;
return [(item.is_editing)?_c('v-chip',{staticClass:"capitalizer",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.is_editing)+" ")]):_vm._e()]}},{key:"item.last_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"capitalizer",attrs:{"color":_vm.getColor(item.last_status),"dark":""}},[_vm._v(" "+_vm._s(item.last_status)+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.last_status!= 'completato')?_c('td',{staticClass:"capitalizer",attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.priority)+" ")]):_vm._e()]}},{key:"item.production_status",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":_vm.getpStatus(item.production_status),"dark":""}})]}},{key:"item.has_seen",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"capitalizer",attrs:{"color":_vm.getSeen(item.has_seen),"dark":""}},[_vm._v(" "+_vm._s(item.has_seen)+" ")])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"pag",attrs:{"color":"#11797b","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"#11797b"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }