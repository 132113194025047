<template>
  <div class="tw-h-full tw-w-full tw-flex-1">
    <div class="tw-flex tw-items-center tw-mb-4">
      <h1 class="tw-text-xl tw-font-bold tw-mr-2">Note tecniche</h1>
      <NewNoteDialog @emitNewNote="addNote" :_id="_id" :esterno="esterno" />
    </div>

    <div
      v-if="!loading"
      ref="scrollable"
      class="tw-w-full tw-rounded-2xl tw-bg-grey tw-h-full tw-overflow-y-scroll tw-p-4"
    >
      <div class="tw-p-2">
        <NoteItem @deleteNote="deleteNote" @editNote="editNote" v-for="(note, index) in notes" :key="index" :note="note" :esterno="esterno" :_id="_id" />
      </div>
    </div>
    <div v-else>
      <div>Sto caricando le note ...</div>
    </div>
  </div>
</template>

<script>
import NoteItem from "./NoteItem.vue";
import NewNoteDialog from "./NewNoteDialog.vue";
import axios from "../../../plugins/axios";

export default {
  name: "NotesComponent",
  props: ["_id", "esterno"],
  data() {
    return {
      notes: [],
      loading: false,
    };
  },
  components: {
    NoteItem,
    NewNoteDialog,
  },
  mounted() {
    this.getNotes();
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      try {
        this.$refs.scrollable.scrollTop = this.$refs.scrollable.scrollHeight;
      } catch {}
    },

    addNote(note) {
      const newNote = { ...note.note, highlight: true };
      this.notes = [...this.notes, newNote];

      setTimeout(() => {
        const lastIndex = this.notes.length - 1;

        this.notes[lastIndex] = { ...this.notes[lastIndex], highlight: false };

        this.notes = [...this.notes];
      }, 1500);
    },
    editNote(note) {
      const index = this.notes.findIndex((n) => n.id === note.id);
      this.notes[index] = note;

    },
    deleteNote(id) {
      const index = this.notes.findIndex((note) => note.id === id);
      this.notes.splice(index, 1);
    },
    getNotes() {
      this.loading = true;
      const url = this.esterno
        ? `project/${this._id}/${this.esterno}/notes`
        : `project/${this._id}/notes`;
      axios
        .get(url)
        .then((response) => {
          this.notes = response.data.notes;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>