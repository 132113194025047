<template>
    <v-badge v-if="num > 0" class="tw-absolute tw-bg-grey tw-top-0 tw-right-0 tw-text-dark tw-text-sm tw-rounded-full tw-px-2 tw-py-1">
        {{num}}
    </v-badge>
</template>

<script>
export default {
    name: 'CounterBadge',
    props: ['num']

}
</script>
