<template>
  <v-row>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Cerca..."
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="projects"
      loading="true"
      loading-text="Sto caricando..."
      :items-per-page="9999999999999"
      class="elevation-1 item"
      height="100%"
      :search="search"
      :sort-by="['id', 'name']"
      :sort-desc="[true, false]"
      @click:row="goTo"
    >
    </v-data-table>
  </v-row>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "Reports",

  data() {
    return {
      search: "",
      timeout: 7000,
      headers: [
        { text: "ID", value: "id", sortable: true, filterable: false },
        {
          text: "Data Report",
          value: "creation",
          align: "start",
          sortable: false,
        },
      ],
      pagination: {
        rowsPerPage: 100,
        descending: false,
        sortBy: "name",
        page: 1,
      },
      projects: [],
    };
  },

  mounted: function () {
    this.getProjects();
  },
  methods: {
    getProjects() {
      axios.get("reports").then((response) => {
        this.projects = response.data.reports;
      });
    },

    goTo(a) {
      if (event.target.classList.contains("btn__content")) return;
      this.$router.push("/report/" + a.id).catch(() => {});
    },

    getColor(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "in produzione") {
        return "red";
      } else if (status == "completato") {
        return "green";
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.item {
  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}
</style>
