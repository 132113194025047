<template>
    <v-alert
      :type="notification.type"
      filled
      class="alert-not"
      :class="notification.type"
      :value="notification.isVisible"
      border="left"
    >
      {{notification.text}}

    </v-alert>
</template>

<script>

export default {
    name: "ResponseNotification",
    computed: {
        notification(){
            return this.$store.getters.getNotification
        }
    }    
}

</script>

<style scoped>
.alert-not {

  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.success {
  background-color: #4CAF50;
  color: white;
}

.warning {
  background-color: #ff9800;
  color: white;
}

.error {
  background-color: #f44336;
  color: white;
}

</style>