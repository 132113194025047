<template>
  <div
    class="tw-w-full tw-h-screen tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-bg-dark"
  >
    <v-row class="!tw-flex-col">
      <v-col cols="12" class="d-flex justify-center align-center tw-flex-col">
        <form v-on:submit.prevent="Login" id="login-cont" class="login-form">
          <h1
            class="tw-text-4xl tw-font-semibold tw-text-dark tw-mb-12 tw-w-full tw-text-center"
          >
            ICA Progest
          </h1>
          <p id="login_error"></p>
          <v-row v-if="isVerified && !isResettingPwd">
            <v-col cols="12">
              <h2 class="tw-font-semibold tw-text-xl">Login</h2>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="username" label="Nome Utente">
              </v-text-field>
            </v-col>

            <v-col cols="9">
              <v-text-field
                v-model="password"
                label="Password"
                v-bind:type="type"
              >
              </v-text-field>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="3">
              <span
                ><v-icon @click="toggleShow" class="pw">mdi-eye</v-icon></span
              >
            </v-col>
          </v-row>
          <v-row v-else-if="!isVerified && !isResettingPwd">
            <v-col cols="12">
              <h2 style="color: #2f2f2f">Verifica codice</h2>
              <h4 style="color: #2f2f2f">
                Controlla nella casella email e inserisci il codice
              </h4>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="code" label="Codice verifica">
              </v-text-field>
            </v-col>
          </v-row>

          <p style="color: red">{{ msg }}</p>
          <div class="tw-w-full">
            <primary-btn
              v-if="!isResettingPwd"
              :block="true"
              :loading="loading"
              @click.native="isVerified ? Login() : authVerify()"
              :text="isVerified ? 'Login' : 'Verifica'"
            />
          </div>
          <v-row v-if="isVerified && !isResettingPwd">
            <v-col cols="12">
              <div
                @click="isResettingPwd = true"
                class="tw-underline tw-font-semibold tw-text-sm hover:tw-opacity-70 tw-cursor-pointer tw-text-dark tw-tracking-wider tw-mt-6 tw-text-center"
              >
                Password Dimenticata
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isVerified && isResettingPwd">
            <v-row class="!tw-m-0 !tw-p-0" v-if="!resetPwdMessage">
              <v-col cols="12">
                <h2 class="tw-font-semibold tw-text-xl">Cambia password</h2>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  v-model="email"
                  label="Inserisci la tua email"
                >
                </v-text-field>
              </v-col>
              <div class="tw-w-full">
                <primary-btn
                  :block="true"
                  :loading="loading"
                  text="Invia link"
                  @click.native="requestPasswordChange"
                />
              </div>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="tw-text-md tw-text-center">
                  {{ resetPwdMessage }}
                </div>
              </v-col>
            </v-row>
          </v-row>
        </form>
      </v-col>
      <response-notification></response-notification>
    </v-row>
  </div>
</template>


<script>
import axios from 'axios'
import PrimaryBtn from '../components/ui/PrimaryBtn.vue'
import { checkForm } from '../plugins/utils'
import {version} from '../../package'

export default {
  components: { PrimaryBtn },

    name: 'Login',
    data() {

        return {
            resetPwdMessage: null,
            email: null,
            isResettingPwd: false,
            loading: false,
            isVerified: true, 
            appV: "",
            code: "",
            username: "",
            password: "",
            user : '',
            msg: '',
            type: 'password'
        }

    },
    beforeCreate: async function(){
      await axios.get('auth').then(async (res) => {
        const settler = new Promise((resolve, reject) => {
            console.log('setting')
            this.$store.dispatch("setuser", res.data.user)
            this.appReady = true
            resolve()
          });
        const response = await settler()
        this.$router.push(res.data.route).catch(()=>{}); 
          
      })
      .catch(err => {
      })
    },
    methods:  {
        requestPasswordChange(){
            if(checkForm('login-form')){
                this.loading = true
                axios.post("forgot-password", {email: this.email}).then((response) => {
                    this.loading = false
                    this.resetPwdMessage = response.data.msg
                    console.log(response.data.msg)
                    
                })
                .catch(err => {
                    if(err.response.status == 404){
                        this.$store.dispatch("notificate", {text: "Non esiste nessun account con questa email", type: "error"})
                    }
                    
                    this.loading = false
                })
            }
        },
        updateUser(){
            this.$root.$emit('update-user')
        },

        async Login() {
            this.loading = true
            await axios.post('/login', {username: this.username, password: this.password})
            .then(async (response) => {
                if(!response.data.status){
                    this.isVerified = false
                    this.msg = ''
                }

                await axios.get("auth-profile/" + "?getV="+ version)
                .then((res) => {
                    if (!res.data.isUpdated){
                        if (!window.location.href.includes("project/")) {
                            if (!res.data.isUpdated){
                                this.$store.state.updateModal = true
                            }
                        }
                    }
                    let res_data = res.data.user
                    res_data.focus_mode = res.data.focus_mode
                    this.$store.dispatch("setuser",res_data)
                    this.$router.push({name: response.data.route}).catch(()=>{});
                    this.loading = false
                })

            
            })
            .catch(err => {
                this.msg = err.response.data.alert
                this.loading = false
            })
            
        },
        async authVerify(){
            this.loading = true
            await axios.post('/auth-verify', {username: this.username, code: this.code})
            .then(async (response) => {

                await axios.get("auth-profile/" + "?getV="+ version)
                .then((res) => {
                    if (!res.data.isUpdated){
                        if (!window.location.href.includes("project/")) {
                            if (!res.data.isUpdated){
                                this.$store.state.updateModal = true
                            }
                        }
                    }
                    let res_data = res.data.user
                    res_data.focus_mode = res.data.focus_mode
                    this.$store.dispatch("setuser",res_data)
                    this.$router.push({name: response.data.route}).catch(()=>{});
                    this.loading = false
                    
                })
            
            })
            .catch(err => {
                this.msg = err.response.data.alert
                this.loading = false
            })
        },
        toggleShow(){
            if (this.type == "password"){
                this.type = "text"
            }
            else {
                this.type = "password"
            }
        }
    }
}
    

</script>

<style scoped>
.pw {
  cursor: pointer;
  vertical-align: middle;
}

.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f2f2f;
  position: absolute;
}
#login-cont {
  width: 360px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.157);
  padding: 40px 20px 40px 20px;
  border-radius: 12px;
  background-color: white;
}

#login_error {
  color: red;
}
</style>