<template>


  <v-container
    fluid
  >
    <v-row
    >
    <v-col cols="12" class="!tw-p-0 tw-items-center tw-mb-4">
      <div class="tw-flex tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Pagamenti</h1>
      </div>
    </v-col>   
  <v-simple-table
  class="elevation-1 item"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="d-flex flex-row align-center">
                  <th style="width: 24%" class="text-left d-flex align-center">
                    Cliente
                  </th>
                  <th style="width: 24%" class="text-left d-flex align-center">
                    Progetto
                  </th>
                  <th style="width: 24%" class="text-left d-flex align-center">
                    Totale ultimo Pagamento
                  </th>
                  <th style="width: 23.5%" class="text-left d-flex align-center">
                    Stato ultimo Pagamento
                  </th>
                </tr>
              </thead>
            </template>
          </v-simple-table>

        </tr>
      </thead>
      <tbody
      
      >
        <tr
          v-for="(item,id) in payments"
          :key="id"
        >
          <v-expansion-panels>
            <v-expansion-panel
 
            >
              <v-expansion-panel-header>
                
                <span style="width: 25%"><b></b> {{item[0].customer}}</span>
                <span style="width: 25%"><b></b> {{item[0].rif}}</span>
                <span style="width: 25%" v-if="item[item.length-1].request.has_iva && item[item.length-1].request.has_iva == '1'" ><b></b> € {{ parseFloat(item[item.length-1].request.qty) + parseFloat(item[item.length-1].request.iva)}}</span>                             
                <span style="width: 25%" v-else ><b></b> € {{ item[item.length-1].request.qty }} </span>                         
                <span style="width: 25%"><b> </b>{{getCurrentStatus(item[item.length-1].status)}} <v-badge style="margin-left: 5px" dark :color="getColor(item[item.length-1].status)"></v-badge></span>                   
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table
                class="elevation-1 item"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Pagamento
                        </th>
                        <th class="text-left">
                          Totale
                        </th>
                        <th class="text-left">
                          Stato
                        </th>
                        <th class="text-left">
                          Avanza Stato
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(payment,index) in item"
                          :key="index"
                      >
                        <td>{{ payment.name }}</td>
                        <td v-if="payment.request.iva">€ {{ parseFloat(payment.request.qty) + parseFloat(payment.request.iva)}}  </td>
                        <td v-else>€ {{ payment.request.qty }}  </td>
                        <td
                          
                          color="black"
                          transparent
                          class="capitalizer"
                        >

                        {{getCurrentStatus(payment.status)}} <v-badge style="margin-left: 5px" dark :color="getColor(payment.status)"></v-badge>
                        </td>

                        <td>

                          
                          <div v-if="payment.status == 0" @click="updatePayment(id,index,'verification')"><span style="margin-right: 8px"><v-btn @click="updatePayment(id,index,'accountant')"  >Conferma Contabile</v-btn></span><span><v-btn @click="updatePayment(id,index,'verification')" dark color="green">Conferma bonifico</v-btn></span></div>
                          <div v-else-if="payment.status == 1" @click="updatePayment(id,index,'verification')"><span><v-btn @click="updatePayment(id,index,'verification')" dark color="green">Conferma bonifico</v-btn></span></div>
                          <div v-else-if="payment.status > 2" ></div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
        </tr>

      </tbody>
    </template>
  </v-simple-table>
        <div class="text-center pt-2">

    </div>
    </v-row>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#11797b"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
  </v-btn>

    <v-alert
    elevation="7"
    :type="response_type"
    :value="success"
    class="onsuccess"

  >
  {{ success_text }}
  </v-alert>

  </v-container>

</template>

<script>
import axios from 'axios'

  export default {
    name: 'Pagamenti', 

    data (){
      return {
        role: '',
        timeout: 7000,
        fab: false,
        page: 1,
        itemsPerPage: 100,
        pageCount: 0,
        scrollPosition: 0,
        search: '',
        headers: [
        { text: "ID", value: "id", sortable:false,filterable: false },
        { text: "Cliente", value: "customer", sortable:false, filterable: true },
        { text: "Riferimento Progetto", value: "rif",  sortable:false,filterable: true },
        { text: "Stato", value: "status",  sortable:false,filterable: true },
        { text: "Avanza Stato", value: "ricevuto",  sortable:false,filterable: true },

      ],
      pagination: {
    rowsPerPage: 100,
    descending: false,
    page: 1
  },
      payments: {},
      response_type: 'success',
      success: false,
      success_text: ''
        }
      },
  beforeCreate: function () {
      axios.get('payments')
      .then((response) => {
        if (response.data.status != "logged_in"){
          this.$router.push('/login').catch(()=>{}); 
        }
        if (response.data.alert == 'not allowed'){
            this.$router.push('/production').catch(()=>{}); 
        }            

        
      })
      
    },

    mounted: function() {
      
      
      this.getPayments()
      
      
    },

    beforeRouteLeave: function(to, from, next) {
      console.log(this.scrollPosition)



      next();
      
    },

    methods: {

      updatePayment(id,i,type){

      var form = new FormData();

      form.append("type", type)
      
      
      axios.post('update-payment/' + id + '/' + i, form)
      .then((response) => {
          this.displayMessage(response.data.msg,"success")
          this.payments = response.data.payments
      })    

      },

      getCurrentStatus(status){
        if (status == 0){
          return "in attesa di contabile"
        }

        else if (status == 1){
          return "in attesa di bonifico"

        }

        else {
          return "Completato"
        }
      },

      displayMessage: function(text,type){

          this.response_type = type
          this.success_text = text
          this.success = true
          setTimeout(() => this.success = false, 1800);      


      },
        getPayments(){

        axios.get('payments')
        .then((response) => {

          this.payments = response.data.payments;
          this.role = response.data.role

          
        })        
      },

    getColor (status) {
        if (status == 0){
          return "red"
        }


        else if (status == 1){
          return "orange"
        }
        else if (status == 3){
          return "blue"
        }

        else {
          return "green"
        }

      },
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
        toTop () {
          this.$vuetify.goTo(0)
      }


    },

    

}
  
  
</script>

<style scoped>


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  


  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}

.onsuccess, .testolo {
  

  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

</style>
