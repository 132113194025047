<template>
  <v-container fluid>
    <v-row v-for="(block, index) in report" :key="index">
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-card elevation="3">
          <v-card-title
            >Cliente: {{ block.customer }} (Progetto N°
            {{ index }})</v-card-title
          >
          <v-card-subtitle class="sbt"
            >RIferimento: {{ block.rif }}</v-card-subtitle
          >
          <v-card-text class="textolo" v-if="block.tracciato">
            <p v-if="block.tracciato.delta.split(':')[0] == '0'">
              Tempo totale lavorazione tracciati:
              {{ block.tracciato.delta.split(":")[1] }} m
            </p>
            <p v-else>
              Tempo totale lavorazione tracciati:
              {{ block.tracciato.delta.split(":")[0] }} h
              {{ block.tracciato.delta.split(":")[1] }} m
            </p>
          </v-card-text>
          <v-card-text class="textolo" v-if="block.mastrino">
            <p v-if="block.mastrino.delta.split(':')[0] == '0'">
              Tempo totale lavorazione mastrini:
              {{ block.mastrino.delta.split(":")[1] }} m
            </p>
            <p v-else>
              Tempo totale lavorazione mastrini:
              {{ block.mastrino.delta.split(":")[0] }} h
              {{ block.mastrino.delta.split(":")[1] }} m
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-card-title v-if="time_tracciato" class="tot-parziale"
          >Totale tempo lavorazione tracciati: <br />{{
            time_tracciato.split(":")[0]
          }}
          h {{ time_tracciato.split(":")[1] }} m</v-card-title
        >

        <v-divider></v-divider>

        <v-card-title v-if="time_mastrino" class="tot-parziale"
          >Totale tempo lavorazione mastrini: <br />{{
            time_mastrino.split(":")[0]
          }}
          h {{ time_mastrino.split(":")[1] }} m</v-card-title
        >

        <v-card-title v-if="tot" class="tot"
          >Totale: <br />
          {{ tot.split(":")[0] }} h {{ tot.split(":")[1] }} m</v-card-title
        >
      </v-col>

      <v-col cols="3"></v-col>
    </v-row>

    <div class="actions" id="actionbar">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="red"
            @click="deleteReport()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Cancella report</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="blue-grey"
            @click="goBack()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
        <span>Chiudi Report</span>
      </v-tooltip>
    </div>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "Report",

  data() {
    return {
      fab: false,
      search: "",
      timeout: 7000,
      pagination: {
        rowsPerPage: 100,
        descending: false,
        sortBy: "name",
        page: 1,
      },
      report: {},
      time_tracciato: "",
      time_mastrino: "",
      tot: "",
    };
  },
  beforeCreate: function () {
    axios.get("report/" + this.report._id).then((response) => {
      this.report = response.data.report;
      this.calcPartial();
      console.log(this.time_mastrino);
    });
  },

  methods: {
    goBack: function () {
      this.$router.back();
    },

    calcPartial() {
      var hours = 0;
      var minutes = 0;
      var h;
      var h2;
      var min;
      var hrs;

      for (let [key, value] of Object.entries(this.report)) {
        console.log(key);

        if (value.tracciato) {
          hours = hours + parseInt(value.tracciato.delta.split(":")[0]);
          minutes = minutes + parseInt(value.tracciato.delta.split(":")[1]);
        }
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      var result_time = String(hours) + ":" + String(minutes);

      h = Math.floor(result_time.split(":")[0]);
      h2 = result_time.split(":")[1] / 60;

      min = Math.floor((h2 - Math.floor(h2)) * 60);

      hrs = Math.floor(h2) + h;

      result_time = hrs + ":" + min;

      this.time_tracciato = result_time;

      hours = 0;
      minutes = 0;

      for (let [key, value] of Object.entries(this.report)) {
        console.log(key);

        if (value.mastrino) {
          hours = hours + parseInt(value.mastrino.delta.split(":")[0]);
          minutes = minutes + parseInt(value.mastrino.delta.split(":")[1]);
        }
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      result_time = String(hours) + ":" + String(minutes);

      h = Math.floor(result_time.split(":")[0]);
      h2 = result_time.split(":")[1] / 60;

      min = Math.floor((h2 - Math.floor(h2)) * 60);

      hrs = Math.floor(h2) + h;

      result_time = hrs + ":" + min;

      this.time_mastrino = result_time;

      if (this.time_tracciato != "" && this.time_mastrino != "") {
        hours = 0;
        minutes = 0;

        hours =
          parseInt(this.time_tracciato.split(":")[0]) +
          parseInt(this.time_mastrino.split(":")[0]);
        minutes =
          parseInt(this.time_tracciato.split(":")[1]) +
          parseInt(this.time_mastrino.split(":")[1]);

        var tot = String(hours) + ":" + String(minutes);

        h = Math.floor(tot.split(":")[0]);
        h2 = tot.split(":")[1] / 60;

        min = Math.floor((h2 - Math.floor(h2)) * 60);

        hrs = Math.floor(h2) + h;

        tot = hrs + ":" + min;

        this.tot = tot;
      }
    },

    deleteReport: function () {
      this.$confirm(
        "Sei sicuro di voler cancellare il seguente progetto?"
      ).then((res) => {
        if (res) {
          axios
            .delete(`report/${this.report._id}`)
            .then((response) => {
              this.$router.push("/home").catch(() => {});
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.actions > button {
  margin: 5px;
}

.tot-parziale {
  font-size: 28px;
}
.tot {
  font-size: 35px;
}

.sbt {
  font-size: 20px;
}

.textolo {
  font-weight: 500;
  font-size: 17px;
}

.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.item {
  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 50%;
}

.actions {
  position: fixed;
  width: 20%;
  height: 75px;
  left: 40%;
  bottom: 5px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-action {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5px;
}

.icon-action {
  height: 100%;
  width: 100%;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}
</style>
