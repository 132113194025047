<template>
  <div>
        <v-btn 
        class="!tw-bg-custom-blue !tw-rounded-xl"
        dark
        
        @click="dialog = true"
        >Nuova Nota</v-btn>

      <v-dialog v-model="dialog"  width="900"
        >
        <v-card >
          <v-card-title>
            <span class="headline">Nuova Nota</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  filled
                  v-model="note.description"
                  label="Descrizione*"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Annulla
            </v-btn>
            <v-btn :loading="loading" color="blue darken-1" text @click="addNote"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <response-notification></response-notification>
    </div>
</template>

<script>
import axios from "../../../plugins/axios";

export default {
  name: "NewNoteDialog",
  props: ["_id", "esterno"],
  data() {
    return {
      dialog: false,
      loading: false,
      note: {
        title: null,
        description: null,
      },
    };
  },
  methods: {
    validateData() {
      if (!this.note.description) {
        this.$store.dispatch("notificate", {
          type: "warning",
          text: "Compila il campo Descrizione",
        });
        return false;
      }
      return true;
    },
    addNote() {
      if (!this.validateData()) return;
      this.loading = true;
      const url = this.esterno ? `project/${this._id}/${this.esterno}/notes` : `project/${this._id}/notes`;
      axios
        .post(url, {
          title: this.note.title,
          description: this.note.description,
          project: this._id,
          esterno: this.esterno,
        })
        .then((response) => {
          this.$store.dispatch("notificate", {
            type: "success",
            text: "Nota aggiunta con successo",
          });
          this.$emit("emitNewNote", {
            note: response.data.note,
          })
        })
        .catch((error) => {
          this.$store.dispatch("notificate", {
            type: "error",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
          this.note.description = null;
        });
    },
  },
};
</script>