<template>
    <div>
        <p>{{time}}</p>
    </div>
</template>

<script>
export default {
    name: 'TimeRow',
    props: {
    isEditingTime: {
        type: Boolean,
        default: false
    },
    time: {
        type: String,
        default: ''
    }
    },
    data(){

        return{
            isEditing : this.isEditingTime
        }
    }
}
</script>

<style scoped>

    .pointer {
        cursor: pointer;
    }
    
    .input-block {
    border: 1px rgb(190, 190, 190) solid;
    border-radius: 4px;
    }

</style>