<template>
    <div>
        <v-btn v-if="!square" :disabled=disabled class="!tw-shadow-md font2 !tw-bg-green !tw-text-white tw-px-4 tw-py-2 tw-rounded-xl tw-font-semibold hover:tw-opacity-80 tw-tracking-widest">{{text}}
            <span v-if="icon"><v-icon>{{icon}}</v-icon></span>
        </v-btn>    
        <v-btn icon v-else :disabled=disabled class="!tw-shadow-md font2 !tw-bg-green !tw-text-white tw-rounded-xl tw-font-semibold hover:tw-opacity-80 tw-tracking-widest">{{text}}
            <v-icon size="20">{{icon}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "ConfirmBtn",
    props:["text","disabled","icon","square"]
}
</script>