<template>
    <div class="tw-flex tw-p-2  hover:tw-bg-grey tw-cursor-pointer  tw-rounded-lg">
        <div class="tw-font-medium">{{text}}</div>
        <span class="tw-flex tw-flex-1 tw-justify-end tw-ml-2">
            <v-icon :color="color" class="!tw-text-unset" size="20">{{icon}}</v-icon>
        </span>
        
    </div>
</template>

<script>
export default {
    name: "ActionElement",
    props: ["text","icon","color"]
}
</script>