<template>


  <v-container
    fluid
  >
    <v-row
    class="full-height"
    >
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cerca..."
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
      
      :headers="headers"
      :items="projects"
      loading="true"
      loading-text="Sto caricando..."
      :items-per-page=10
      class="elevation-1 item"
      height="100%"
      :search="search"
      :sort-by="['id', 'name']"
      :sort-desc="[true, false]">
      
      
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark
          class="capitalizer"
        >

        {{item.status}}
        </v-chip>
      </template>
      <template v-slot:item.has_seen="{ item }">
        <v-chip
          :color="getSeen(item.has_seen)"
          dark
          class="capitalizer"
        >

        {{item.has_seen}}
        </v-chip>
      </template>
      </v-data-table>
    </v-row>
  </v-container>
  
</template>

<script>
import axios from 'axios'

  export default {
    name: 'Workflow',

    data (){
      return {
        search: '',
        headers: [
        { text: "Nome", value: "name", sortable: true, filterable: true },
        { text: "Stato", value: "status", sortable: true,  },
        { text: "Data di creazione", value: "creation", align: "start", sortable: true },
        { text: "Ultima modifica", value: "last_edit", sortable: false },
        { text: "Data inizio", value: "start", sortable: false },
        { text: "Data fine", value: "finish", sortable: false },
        { text: "Quantità prodotta", value: "qty_produced", sortable: false },
      ],
      pagination: {
    rowsPerPage: 100,
    descending: false,
    sortBy: "name",
    page: 1
  },
      projects: [],
        }
      },
  beforeCreate: function () {
            axios.get('workflow')
            .then((response) => {
              if (response.data.status != "logged_in"){
                this.$router.push('/login').catch(()=>{}); 
              }
            
            })
      
    },

    mounted: function() {

      this.getProjects()
      
    },
    methods: {

      getProjects(){

        axios.get('workflow')
        .then((response) => {

          this.projects = response.data.workflows;

          for (var i = 0; i < this.projects.length; i++){
            if (this.projects[i].has_seen == 1){
            this.projects[i].has_seen = "✓✓"
          }
            else {

            this.projects[i].has_seen = "X"

            }

          }

          
        })        
      },

      getColor (status) {
        if (status == "in attesa"){
          return "#ffc412"
        }

        else if (status == "aperto"){
          return "blue"
        }

        else if (status == "SUSPENDED"){
          return "red"
        }
        else if (status == "creato"){
          return "black"
        }

        else {
          return "green"
        }
      },


    },

    

}
  
  
</script>

<style scoped>

  .container {
    min-height: calc(100vh - 60px);
    box-sizing: border-box;
    padding: 40px;

  }

  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .item {

    cursor: pointer;
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

</style>
