<template>

<v-btn
    class="!tw-bg-custom-blue !tw-rounded-xl"
    dark
    @click.stop="navigate"
>
<v-icon
    class="!tw-text-white"
    small
    >mdi-arrow-right</v-icon
>

</v-btn>
    
</template>

<script>
export default {
    name: 'AssociatedButton',
    props: {
        link: String
    },
    methods: {
        navigate() {
            this.$router.push(this.link).catch(() => {});
        }
    }
}
</script>