<template>
  <div
    class="
      tw-flex
      tw-w-full
      tw-flex-col
      tw-border-2
      tw-border-grey
      tw-justify-center
      tw-box-border
      tw-rounded-lg
      font2
      tw-h-[fit-content]
    "
  >
    <div class="tw-full tw-h-auto">
      <img
        :src="apiPath + item.preview"
        class="tw-w-full tw-h-full"
      />
    </div>
    <div class="tw-flex-1 tw-p-4 tw-pr-8 tw-flex tw-flex-col">
      <div class="tw-text-xl tw-font-bold">{{ item.title }}</div>
      <div class="tw-text-sm tw-mt-2">{{ item.description }}</div>
    </div>
    <div class="tw-w-full tw-justify-end tw-p-8">
        <secondary-btn @click.native="deleteArProject" text="Cancella"></secondary-btn>
    </div>
    <response-notification></response-notification>
  </div>
</template>

<script>

import axios from 'axios';
import SecondaryBtn from '../ui/SecondaryBtn.vue';
import ResponseNotification from '../ResponseNotification.vue';

export default {
  components: { SecondaryBtn, ResponseNotification },
  name: "ArCard",
  props: ["item", "project_id", "index"],
    data(){
        return {
            apiPath: axios.defaults.baseURL.replace('api/', ''),
        }
    },
    methods: {
        deleteArProject(){         
            this.$confirm("Sei sicuro di voler cancellare il progetto in realtà aumentata? Una volta cancellato non sarà più disponibile nell'applicazione mobile").then((res) => {
                if (res){
                    let form = new FormData();
                    form.append('project_id', this.project_id);
                    axios.post('delete-ar-project/' + this.index, form ).then(response => {
                        this.$store.state.project.ar_projects = response.data.ar_projects;
                        this.$store.dispatch('notificate', {
                            type: 'success',
                            message: 'Il progetto è stato cancellato',
                        });
                    })
                    .catch(error => {
                        this.$store.dispatch('notificate', {
                            type: 'error',
                            message: 'C\'è stato un errore con la cancellazione del progetto AR',
                        });
                    });
                }
            })
        }
    }
};

</script>