<template>
    <div>
    <v-chip          
        @click="dialog = true"
        :class="missing_informations.are_missing ? 'visible' : 'hidden'"
        class="hover:tw-opacity-70"
        color="black"
        text-color="white"
        >
        <v-icon left>
            mdi-information
        </v-icon>
        Visualizza info mancanti
    </v-chip>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
        <v-card style="padding: 15px">
            <v-card-title style="padding: 0; margin-bottom: 20px" class="text-h5">
                Informazioni mancanti
            </v-card-title>
            <v-textarea
                v-if="role == 'master' || (role == 'commerciale' && esterno !== undefined)"
                v-model="missing_informations.text"
                outlined
                label="Informazioni mancanti"
                auto-grow
            ></v-textarea>            
            <div
            v-else
            style="width: 100%; margin-bottom: 15px"
            >
            <div v-if="role != 'esterno'" class="generic-info tw-flex tw-items-center tw-my-4">
                <label class="  tw-font-semibold">Priorità</label>
                <div class="!tw-relative tw-flex !tw-min-h-[3rem] tw-ml-8 tw-w-full">
                    <custom-select  
                    :clearable="false" 
                    :options="priorities" 
                    required
                    class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer  tw-w-full !tw-border-0 select-style-chooser" 
                    v-model="priority" 
                        >
                    </custom-select> 
                </div>        
            </div>
                <p>{{missing_informations.text}}</p>
            <v-textarea
                v-model="missing_informations.response"
                outlined
                label="Risposta"
                auto-grow
            ></v-textarea>      
            </div>
       
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="green darken-1"
                class="info-button"
                text
                @click="dialog = false"
                >
                Chiudi
                </v-btn>
                <v-btn
                color="green darken-1"
                text
                @click="requestInformations"
                :loading="loading"
                >
                Invia
                </v-btn>
            </v-card-actions>
        </v-card>
        
    </v-dialog>
    <response-notification></response-notification>
    </div>

    
    
</template>

<script>

import axios from 'axios'


export default {
    name: 'missingInformationButton',
    props: {
        pid: {
            type: Number,
            default: undefined
        },
        esterno: {
            type: String,
            default: undefined
        },
        role: {
            type: String,
            default: undefined
        },
        delegate: {
            type: String,
            default: ""
        },
        are_missing: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            priorities: ["ordine in corso","urgente","standard","non urgente"],
            priority: 'standard',
            response_alert: {"is_visible":false,"text":"","type":"success"},
            id: this.pid,
            estern: this.esterno,
            project_delegate: this.delegate,
            missing_informations: {},
            dialog: false,
            userRole: this.role,
            show: this.are_missing,
            loading: false,

        }
    },
    mounted(){
        axios.get("missing-informations/"+ this.id + "/" + ((this.estern && this.estern !== 'undefined') ? this.estern : ""))
        .then((response) => {
            this.missing_informations = response.data.missing_informations
            try{
                if (this.missing_informations && this.missing_informations.are_missing && (this.userRole == 'esterno' || (this.userRole == 'commerciale' && this.estern === undefined))){
                    this.dialog = true
                }
            }
            catch {
                console.log("maybe missing info does not")
            }

        })
    },
    methods:{   
        checkForm(classname){
        var forms = document.getElementsByClassName(classname);
        for (var i = 0; i < forms.length; i++){
            if (!forms[i].reportValidity()){
            return false;
            }
        }
        return true;
        
        },
        requestInformations(){
            this.loading = true
            let form = {...this.missing_informations}
            form.priority = this.priority
            console.log(form)
                
            axios.post("/missing-informations/" + this.id + '/' + ((this.estern && this.estern !== 'undefined') ? this.estern : ""), {"missing":JSON.stringify(form)})
            .then((response) => {
                this.loading = false
                if(response.data.type == "error"){
                    this.$store.dispatch("notificate",{"text":response.data.msg,"type":response.data.type})
                    return 
                }
                this.$store.dispatch("notificate",{"text":response.data.msg.text,"type":response.data.msg.type})
                if (response.data.is_response){
                    this.missing_informations.are_missing = 0
                }
                this.dialog = false
            this.$emit("missing_info_talk",response.data.talk)
            })
            .catch((err) => {
                console.log(err)
                this.loading = false
            })
        }, 
        openDialog(){
            this.dialog = true
        },

        wait(){
            this.response_alert.is_visible = false
            this.dialog = false  
        }

    }
}
</script>

<style scoped>
.onsuccess{
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: fit-content;
}
.visible{
    display: block !important;
}.hidden{
    display: none !important;
}
</style>