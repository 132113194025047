<template>


  <v-container
    fluid
  >
    <v-row
    >
    <v-col cols="8" class="!tw-p-0 tw-items-center tw-mb-4">
      <div class="tw-flex tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Cestino</h1>
        <input-field    
          class="ml-8 !tw-w-96"    
          type="text"
          icon="mdi-magnify"
          v-model="search"
          placeholder="Cerca nel cestino"
        ></input-field>
      </div>
    </v-col>   
    <v-col cols="4" class="no-pd d-flex justify-end">

    </v-col>

<!--      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            style="margin-right: 20px;margin-left: 20px;">mdi-filter-menu
            </v-icon>

        </template>
        <v-card style="bacground-"></v-card>
      </v-menu> -->

      <v-data-table

      hide-default-footer
      :headers="headers"
      :items="projects"
      loading="true"
      loading-text="Sto caricando..."
      :items-per-page="itemsPerPage"
      class="elevation-1 item"
      height="100%"
      :search="search"
      @page-count="pageCount = $event"
      :page.sync="page"
      item-key="idbin"
      >

        <template v-slot:item.last_request="{ item }">
          <td
          v-if="item.last_request != '' && item.last_request != 'None'"
          >

          {{item.last_request}}
          </td>
        </template>            
        
        <template v-slot:item.actions="{ item }">
          <v-chip
            color="green"
            dark
            @click="recoverFromBin(item,'recover')"
          >
            Ripristina
          </v-chip>
        </template>    


      </v-data-table>
        <div class="text-center pt-2">
      <v-pagination
        color="#11797b"
        class="pag"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    </v-row>
    <v-btn
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#11797b"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
  </v-btn>

  <response-notification></response-notification>
  </v-container>

</template>

<script>
import axios from 'axios'

  export default {
    name: 'BinEsterno',
    data (){
      return {
        is_disabled: false,
        timeout: 7000,
        fab: false,
        page: 1,
        itemsPerPage: 100,
        pageCount: 0,
        scrollPosition: 0,
        search: '',
        headers: [
        { text: "ID", value: "id", sortable:false,filterable: false },
        { text: "Cliente", value: "customer", sortable:false, filterable: true },
        { text: "Riferimento Progetto", value: "name",  sortable:false,filterable: true },
        { text: "Corrispondente", value: "referrer",  sortable:false,filterable: false },
        { text: "Data di eliminazione", value: "delete_date", sortable:false, filterable: false },
        { text: "Ultima richiesta", value: "last_request",  sortable:false,filterable: false},
        { text: "Data di creazione", value: "time",  sortable:false,filterable: false},
        { text: "Azioni", value: "actions",  sortable:false,filterable: false},
      ],
      pagination: {
    rowsPerPage: 50,
    descending: false,
    page: 1
  },
      projects: [],
        }
      },

    mounted: function() {
      
      if (this.user.role == 'worker'){
        this.$router.push('/production').catch(()=>{}); 
        
      }
      else if (this.user.role == 'contabile'){
        this.$router.push('/payments').catch(()=>{}); 
        
      }        
      else {
        this.getBin()
      }
      
    },



    /*
    beforeRouteLeave: function(to, from, next) {
      console.log(this.scrollPosition)

        axios.post('save-scroll/projects/' + this.scrollPosition)
        .then((response) => {
               
        })

      next();
      
    },*/
    computed:{
      user(){
        return this.$store.getters.getCurrentUser
      },

      esterno(){
        if (this.user.role == "esterno"){
          return this.user.esterno
        }
        else {
          return ""
        }
      }
    },
    methods: {
      toTop () {
        this.$vuetify.goTo(0)
      },
      getBin(){

        axios.get(this.user.role == "esterno" ? ("bin-esterno/" + this.user.esterno) : "bin")
        .then((response) => {
          this.projects = response.data.projects;
          
        })        
        .catch(err => {
          console.log(err)
        })
      },
      recoverFromBin(item,action_type){
        console.log(item)
        this.$confirm("Sei sicuro di voler ripristinare il progetto?").then(res => {
          if (res){
            var action = new FormData()
            action.append("action", action_type)
            axios.post('bin-action-queue/' + item.idbin + "/" + this.user.esterno, action)
            .then((response) => {
              if (response.data.msg.type == "success"){
                this.projects = response.data.projects;
              }
              this.$store.dispatch("notificate",{"text":response.data.msg.text,"type":response.data.msg.type})
            })
          }
        })

      }

    },

}
  
  
</script>

<style scoped>


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}

.force-square {

  border-radius: 0px !important;
}

.bigger-badge {

  font-size: 10px !important;
}

</style>
