<template>


  <v-container
    fluid
  >
  <v-row class="tw-mb-8">
    <v-col cols="8" class="!tw-p-0 tw-items-center">
      <div class="tw-flex tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Contestazione</h1>
      </div>
    </v-col>  
    <v-col cols="4" class="!tw-p-0 tw-items-center">
        <div class="tw-flex tw-justify-end tw-items-center tw-flex-2">
          <primary-btn :loading="isSaving" @click.native="updateDispute" id="action-btn" class="tw-self-baseline tw-mr-2" text="Salva" ></primary-btn>
          <v-menu v-model="actions_dialog" offset-y  content-class="!tw-shadow-xl !tw-rounded-lg !tw-w-auto !tw-min-w-0">
            <template v-slot:activator="{ on, attrs }">
              <secondary-btn v-bind="attrs" v-on="on" @click.native="actions_dialog = true" id="action-btn" class="tw-self-baseline" text="Altre Azioni" icon="mdi-menu-down"></secondary-btn>
            </template>
            <v-list class="font2">
              <action-element color="red" @click.native="deleteDispute" text="Elimina Contestazione" icon="mdi-delete"></action-element>
            </v-list>
          </v-menu>
        </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="3">
      <p><b>Cliente: </b> {{dispute.customer}}</p>
    </v-col>
    <v-col cols="3">
      <p><b>Riferimento: </b> {{dispute.rif}}</p>
    </v-col>
    <v-col cols="2">
      <p v-if="dispute.creation"><b>Data di creazione: </b> {{dispute.creation.split(" ")[0]}}</p>
    </v-col>
    <v-col cols="2">
      <p v-if="dispute.responsible"><b>Responsabile: </b> {{dispute.responsible}}</p>
    </v-col>
    <v-col cols="2">
      <v-select
      outlined
      label="Seleziona il commerciale in copia"
      :items="users"
      v-model="dispute.responsible_cc"
      @change="checkEdit"
      ></v-select>
    </v-col>
 

  </v-row>


  <v-row>
    <v-col cols="2">
      <v-select
      outlined
      v-model="dispute.supplier"
      :items="suppliers"
      @change="checkEdit"
      >
        <template v-slot:label>
          Fornitore
        </template>
      </v-select>  
    </v-col>
    <v-col cols="3">
      <v-select
      outlined
      :items="stati_supplier"
      v-model="dispute.customer_status"
      @change="checkEdit"
      >
        <template v-slot:label>
          Stato Fornitore
        </template>
      </v-select>
    </v-col>
    <v-col cols="3">
      <v-select
      outlined
      :items="stati_customer"
      v-model="dispute.supplier_status"
      @change="checkEdit"
      >
        <template v-slot:label>
          Stato Cliente
        </template>
      </v-select>
    </v-col>
    <v-col cols="1">
      <v-select
      outlined
      v-model="dispute.fault"
      :items="faults"
      @change="checkEdit"
      >
        <template v-slot:label>
          Colpa
        </template>
      </v-select>  
    </v-col>
    <v-col cols="2">
      <v-text-field
      v-model="dispute.cod"
      @change="checkEdit"
      >
        <template v-slot:label>
          Cod. Articolo
        </template>
      </v-text-field>
    </v-col>   
  </v-row>

  <v-row>
    <v-col cols="6">
      <v-row>
      <p class="!tw-mb-6"><b>DDT Arrivo</b></p>
      </v-row>
      <v-row class="!tw-mb-8" v-for="(ddt_arrive, index) in dispute.ddt.arrive" :key="index">
        <v-row v-if="ddt_arrive.is_saved == 1">
          <v-card width="80%">
            <v-row>
              <v-col cols="5">
                <v-card-subtitle>
                  <b>Numero: </b>{{ddt_arrive.num}}
                </v-card-subtitle>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle>
                  <b>Data: </b>{{ddt_arrive.date}}
                </v-card-subtitle>
              </v-col>
              <v-col cols="2"
              align-self="center">
                <v-icon
                size="30"
                @click="deleteDDT('arrive', index)"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-card>

        </v-row>

        <v-row v-else>

          <v-col cols="5">
            <v-text-field 
            v-model="dispute.ddt.arrive[index].num"
            type="number"
            @change="checkEdit"
            >
              <template v-slot:label>
                Numero
              </template>
            </v-text-field> 
          </v-col>
          <v-col cols="5">

              <v-text-field
                v-model="ddt_arrive.date"
                label="Date"
                hint="DD/MM/YYYY formato"
                persistent-hint
                @change="checkEdit"
                type="date" 
                @keydown="return false"
              ></v-text-field>

          </v-col>
          <v-col cols="2"
            align-self="center">
            <v-icon
            size="30"
            @click="saveDDT('arrive',index)"
            
            >
              mdi-check
            </v-icon>
            <v-icon
            size="30"
            @click="deleteDDT('arrive',index)"
            >
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>



      </v-row>
        <v-col v-if="!dispute.ddt.arrive[dispute.ddt.arrive.length -1] || dispute.ddt.arrive[dispute.ddt.arrive.length -1].is_saved" cols="12" align-self="center">
        <v-btn
        
        color="#11797b"
        dark
        fab
        height="30"
        width="30"
        @click="createDDT('arrive')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      
    </v-col>
    <v-col cols="6">
      <v-row>
      <p class="!tw-mb-6"><b>DDT Consegna</b></p>
      </v-row>
      <v-row class="!tw-mb-8" v-for="(ddt_delivery, index) in dispute.ddt.delivery" :key="index">
        <v-row v-if="ddt_delivery.is_saved == 1">
          <v-card width="80%">
            <v-row>
              <v-col cols="5">
                <v-card-subtitle>
                  <b>Numero: </b>{{ddt_delivery.num}}
                </v-card-subtitle>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle>
                  <b>Data: </b>{{ddt_delivery.date}}
                </v-card-subtitle>
              </v-col>
              <v-col cols="2"
              align-self="center">
                <v-icon
                size="30"
                @click="deleteDDT('delivery', index)"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-card>

        </v-row>

        <v-row v-else>

          <v-col cols="5">
            <v-text-field 
            v-model="dispute.ddt.delivery[index].num"
            type="number"
            @change="checkEdit"
            >
              <template v-slot:label>
                Numero
              </template>
            </v-text-field> 
          </v-col>
          <v-col cols="5">
              <v-text-field
                v-model="ddt_delivery.date"
                label="Date"
                hint="DD/MM/YYYY formato"
                persistent-hint
                @change="checkEdit"
                type="date" 
                @keydown="return false"
              ></v-text-field>
          </v-col>
          <v-col cols="2"
            align-self="center">
            <v-icon
            size="30"
            @click="saveDDT('delivery',index)"
            
            >
              mdi-check
            </v-icon>
            <v-icon
            size="30"
            @click="deleteDDT('delivery',index)"
            >
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>



      </v-row>
        <v-col v-if="!dispute.ddt.delivery[dispute.ddt.delivery.length -1] || dispute.ddt.delivery[dispute.ddt.delivery.length -1].is_saved == 1" cols="12" align-self="center">
        <v-btn
        
        color="#11797b"
        dark
        fab
        height="30"
        width="30"
        @click="createDDT('delivery')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      
    </v-col>



  </v-row>

  <v-row>
    <v-divider></v-divider>
  </v-row>

  <v-row>



    <v-row>

      <v-col cols="3">
        <v-checkbox
        v-model="dispute.supplier_return.is_returned"
        :items="faults"
        false-value="0"
        true-value="1"
        >
              <template #prepend>
                <v-label >Merce resa al fornitore</v-label>
              </template>
        </v-checkbox>  
        <v-text-field
        v-if="dispute.supplier_return.is_returned == 1"
        v-model="dispute.supplier_return.qty"
        @change="checkEdit"
        >
          <template v-slot:label>
            Quantità
          </template>
        </v-text-field>         
      </v-col>
      <v-col cols="3" >
      <v-text-field
      v-model="dispute.refund.to_supplier.qty"
      type="number"
      @change="checkEdit"
      >
        <template v-slot:label>
          Rimborso dal fornitore
        </template>
      </v-text-field>
      <v-checkbox
      v-model="dispute.refund.to_supplier.is_welded"
      false-value="0"
      true-value="1"
      @change="checkEdit"
      >
        <template #prepend>
          <v-label >Saldato?</v-label>
        </template>      
      </v-checkbox>


    </v-col>
      <v-col cols="3">
        <v-checkbox
        v-model="dispute.customer_return.is_returned"
        :items="faults"
        false-value="0"
        true-value="1"
        @change="checkEdit"
        >
              <template #prepend>
                <v-label >Merce resa dal cliente</v-label>
              </template>
        </v-checkbox>  
        <v-text-field
        v-if="dispute.customer_return.is_returned == 1"
        v-model="dispute.customer_return.qty"
        @change="checkEdit"
        >
          <template v-slot:label>
            Quantità
          </template>
        </v-text-field>         
      </v-col>
      <v-col cols="3" >
      <v-text-field
      v-model="dispute.refund.to_customer.qty"
      type="number"
      @change="checkEdit"
      >
        <template v-slot:label>
          Rimborso al cliente
        </template>
      </v-text-field>
      <v-checkbox
      v-model="dispute.refund.to_customer.is_welded"
      false-value="0"
      true-value="1"
      @change="checkEdit"
      >
        <template #prepend>
          <v-label >Saldato?</v-label>
        </template>      
      </v-checkbox>


    </v-col>


    </v-row>


  </v-row>
  <v-row>
    <v-divider></v-divider>
  </v-row>
  <v-row>
    <v-row>
      <v-col cols="6">
        <v-col cols="12"><p><b>Note fornitori</b></p></v-col>
        <v-col cols="12">
          <v-textarea
          outlined
          v-model="dispute.supplier_notes"
          @change="checkEdit"
          ></v-textarea>
          </v-col>
      </v-col>  
      <v-col cols="6">
        <v-col cols="12"><p><b>Note cliente</b></p></v-col>
        <v-col cols="12">
          <v-textarea
          outlined
          v-model="dispute.customer_notes"
          @change="checkEdit"
          ></v-textarea>
          </v-col>
      </v-col>
    </v-row>
    <v-col cols="12">
      <b>Allegati</b>
    </v-col>
  </v-row>

    <v-row class="files nopd">
    <v-col  sm="8" class="form-allegato force-h" >
      <form enctype="multipart/form-data" id="allegato-prod" class="form-ica">
        <label v-if="dispute.files" class="uploader" >
          

            <div v-if="dispute.files.length == 0">Non sono presenti file...</div> 
            
            <v-row
              class="allegato-row"
              v-for="(fname, index) in dispute.files" :key="index"
              >
      
              <v-col
              class="nopd center_y" 
              sm="10">{{ fname.replace(/_/g, " ") }}</v-col>
              <v-col
              class="nopd"
              sm="2">
                <v-col 
                cols="12"
                class="nopd right" >
                  <v-icon
                  class="action-file"
                  @click="shareFiles(fname,'dispute')"
                  
                  >
                    mdi-share
                  </v-icon>
                  <v-icon
                  class="action-file"
                  @click="deleteFiles(index)"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-col>
                
            </v-row>
                          
            </label>
          </form>
        </v-col>
        <v-col sm="4" class="input-ica">
          <div class="upload-icon">
                <v-icon 
                color="primary"
                dark
              >mdi-upload
              </v-icon> 
            <p style="font-size: 13px">Trascina i file qui per caricarli</p>
          </div>
          <input @change="getFiles" id="upload-false_prod-file" class="nicenice" name="files" type="file" size="1" multiple>
        </v-col>
      </v-row>

  <div id="loading">
    <div class="shape">

      <v-progress-circular
          :rotate="360"
          :size="200"
          :width="25"
          :value="uploading"
          color="#1f7a8c"
          class="loading"
        >
          {{ uploading }}
      </v-progress-circular>
    </div>

  </div>
  <v-alert
    elevation="7"
    :type="response_type"
    :value="success"
    class="onsuccess"

  >
  {{ success_text }}
  </v-alert>    
  </v-container>
  
</template>

<script>
import axios from 'axios'

  export default {
    name: 'Contestazione',

    data (){
      return {
        isSaving: false,
        actions_dialog: false,
        fab: false,
        dispute: {
          'customer_return': {},
          'supplier_return': {},
          'ddt': {"delivery":[],"arrive":[]},
          "refund":{
            "to_customer" :{"qty":0,"is_welded":0},
            "to_supplier" :{"qty":0,"is_welded":0}
          },
          "files": []

        },
        stati_supplier: ["aperta","chiusa"],
        stati_customer: ["aperta","chiusa"],
        faults: ["nessuno","nostra","condivisa","fornitore"],
        suppliers: ["DS Smith","Gariboldi","2R","ZDUE","Ellemme","Sandra"],
        menu1: false,
        date: null,
        dateFormatted: null,
        uploading: 0,
        files_list: new FormData(),
        success: false,
        success_text: '',
        response_type: 'success',
        users: ["Fabiano","Gianfranco","Roberta","Chiara","Valentina","Roberto","Monica","Marco","Jacopo"],
        edited: false
        }
      },

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    beforeRouteLeave (to, from, next) {
      if (this.edited == true){
        this.$confirm("Hai dati non salvati, sei sicuro di voler uscire?").then(res => {
          if (res){
          next()
          } 
          else {
            next(false)
          }
        })
      }
      else {
        next()
      }

    },
    beforeCreate: function () {
      axios.get('dispute/' + this.$route.params.id)
      .then((response) => {
        if (response.data.status != "logged_in"){
          this.$router.push('/login').catch(()=>{}); 
        }

        this.dispute = response.data.dispute;
      })
        
    },

    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
    },


    methods: {

    getFiles: function(e) {
      
      
      var files = e.target.files;

      console.log(files)


      for (var x = 0; x < e.target.files.length; x++) {
        this.files_list.append(e.target.getAttribute('name'), e.target.files[x]);
        this.dispute.files.push(e.target.files[x].name);
      }
      

    },

    uploadFiles: function() {


      let config = {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          this.uploading = percentCompleted;
          this.setLoader()
        }
      }

      axios.post('upload-dispute-file/'+this.dispute.id, this.files_list, config)
      .then((response) => {
        
 
      })   
    },

      goBack: function() {
        this.$router.push("/disputes") 
      },

      inputDate(tipo,date,i){

        console.log("sssss")
        console.log(date)

        if (tipo == "delivery"){
          date = this.parseDate(this.dateFormatted)
          this.dispute.ddt.delivery[i].date = this.dateFormatted
          
        }

        else {
          this.dispute.ddt.arrive[i].date = this.dateFormatted
          
        }

        this.menu1 = false

      },

    setLoader: function(){

      const loader = document.querySelector("#loading");
      loader.style.display = 'flex';
       if (this.uploading == 100) {
        setTimeout(function(){       
        
            loader.style.display = 'none';
        }, 1500);
       }

    },

    shareFiles: function(filename,ref,index){

      const fname = new FormData();
      fname.append("filename", filename);
      fname.append("to", ref);
      axios.post('view-dispute-file/' + this.dispute.id_project + "/" + this.dispute.id, fname,{responseType: 'arraybuffer'})
      .then((response) => {
        if (response.data.msg.type == "success"){
          this.$store.dispatch("notificate",{"text":response.data.msg.text,"type":response.data.msg.type})
          window.open("http://" + window.location.host + response.data.path)
        }
        else {
          this.$store.dispatch("notificate",{"text":response.data.msg.text,"type":response.data.msg.type})
        }

      })
    },
    checkEdit: function(){

      this.edited = true;

    },
      createDDT: function(tipo){
        if (tipo == "delivery"){
          this.dispute.ddt.delivery.push({"num":0,"date":"","is_saved":0})
        }

        else {
          this.dispute.ddt.arrive.push({"num":0,"date":"","is_saved":0})

        }

      },

      saveDDT: function(tipo,i){
        if (tipo == "delivery"){
          console.log(this.dispute.ddt.delivery[i])
          this.dispute.ddt.delivery[i].is_saved = 1
        }

        else {
          this.dispute.ddt.arrive[i].is_saved = 1


        }
      },

      deleteDDT: function(tipo,i){
        if (tipo == "delivery"){
          this.dispute.ddt.delivery.splice(this.dispute.ddt.delivery.indexOf(i), 1);
        }

        else {
          this.dispute.ddt.arrive.splice(this.dispute.ddt.delivery.indexOf(i), 1);
          
        }

      },
      displayMessage: function(text,type){

          this.response_type = type
          this.success_text = text
          this.success = true
          setTimeout(() => this.success = false, 1800);      


      },
      deleteFiles: function(number){
        axios.post('delete-dispute-file/'+this.dispute.id + '/' + number)
        .then((response) => {
          if (response.data.msg == 'not found'){

          }
          else {
            //this.displayMessage(response.data.msg, "success")
            this.dispute.files.splice(this.dispute.files.indexOf(number), 1);
          }
        
        })
      },
      updateDispute(){
        this.isSaving = true
        axios.post("dispute/" +this.dispute.id, {'dispute':JSON.stringify(this.dispute)})
        .then((response) => {
          this.displayMessage("Dati aggiornati con successo", "success")
          this.edited = false;
        }) 
        .catch(err => console.log(err))
        this.isSaving = false

        this.uploadFiles()
      },

      deleteDispute: function(){
        this.$confirm("Sei sicuro di voler cancellare la seguente contestazione?").then(res => {
          if (res){
            axios.post('delete-dispute/'+this.dispute.id,)
            .then((response) => {
              this.$router.push('/disputes').catch(()=>{}); 
            })     
          }
        })
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    }
    
    },



    

}
  
  
</script>

<style scoped>

.actions > button {
  margin: 5px;
}

.onsuccess, .testolo {
  

  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.force-h {
  height: 100% !important;
}

#upload-false_prod-file  {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.right {

  text-align: right;
}

.uploader {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px;
  
  
}


.uploader::-webkit-scrollbar {
  width: 5px;
  z-index: 2000;
}


.uploader {
  -ms-overflow-style: 5px;  /* IE and Edge */
  scrollbar-width: 5px;  /* Firefox */
}

.form-ica {
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}


.form-allegato {

  height: 80%;
  padding: 0;
}

.files {

  height: 250px;
  padding: 15px;
}

.nopd{
  padding: 0px !important;
}

.allegato-row {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow:  0px 5px 20px rgba(0, 0, 0, 0.185);
  background-color: white;
  flex: none;
  box-sizing: content-box;
  cursor: grab;
  
  
}

.upload-icon {

  text-align: center;
}


.input-ica {

  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading {

  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.212);
}

  .v-input__control{

    width: auto  !important;
  }

  p {

    margin: 0;
    padding: 0;

  }


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .item {

    cursor: pointer;
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 50%;
  }

  .actions {
    position: fixed;
    width: 20%;
    height: 75px;
    left: 40%;
    bottom: 5px;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-action {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin:  5px;

}

.icon-action {

    height: 100%;
    width: 100%;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

</style>
