<template>
        <div class="!tw-relative tw-flex !tw-min-h-[3rem]">
            <input :step="step" :multiple="multiple" v-if="!darker" :disabled="disabled" :required="required" class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-flex-1 tw-w-full inpt" :value="value" @input="$emit('input', $event.target.value)" :type="type" :placeholder="placeholder">
            <input :step="step" :multiple="multiple" v-else :disabled="disabled" :required="required" class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-flex-1 tw-w-full inpt" :value="value" @input="$emit('input', $event.target.value)" :type="type" :placeholder="placeholder">
            <v-icon v-if="icon" class="tw-absolute top-1/2 tw--left-6 tw-transform tw--translate-x-1/2">{{icon}}</v-icon>
        </div>

</template>

<script>
export default {
    name: "InputField",
    props: ["type","placeholder","icon","value","required","disabled","darker","multiple","step"]
}
</script>

<style>
    input:disabled.inpt {
        opacity: 0.5;
        cursor: not-allowed;
    }
    input:focus.inpt {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 1px;
    }
</style>