<template>
  <div >
      <div
        v-if="!fname.type || fname.type != 'link'"
        class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-2 tw-relative"
      >
        <div v-if="hasSelection" class="tw-mr-2 tw-flex tw-items-center">
          <v-checkbox v-model="isSelected" :value="fname"></v-checkbox>
          
        </div>
        <div class="">
          <!-- <img
            class="tw-16 tw-h-16"
            :src="
              path + '/static/img/file_ico/' + getFileIco(fname.name) + '.png'
            "
            alt=""
          /> -->
        </div>
        
        <div class="tw-flex-1 tw-flex-col font2">
          <p class="tw-font-bold tw-text-sm md:tw-text-md !tw-mb-0">
            {{ fname.name.replace(/_/g, " ") }}
          </p>
          <p class="tw-text-xs !tw-mb-0">
            {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
          </p>
        </div>
        <div class="tw-flex tw-bg-red">
          <!-- <v-icon v-if="user.role == 'commerciale' && project.id_esterno" @click="transferData('ica_file',fname)" class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue">mdi-send</v-icon> -->
          <v-icon
            @click="viewFile(fname.name, 'ica')"
            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
            >mdi-eye</v-icon
          >
          <!-- <v-icon
            @click="downloadFile(fname, 'ica')"
            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
            >mdi-download</v-icon
          >
          <v-icon
            @click="deleteFile(fname, 'ica')"
            class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
            >mdi-delete</v-icon
          > -->
        </div>
        <response-notification></response-notification>
      </div>
      <div v-else>
        <a target="blank" class="tw-underline" :href="fname.name">{{fname.name}}</a>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "FileItem",
  props: ["_id","fname","hasSelection","path"],
  data(){
    return {
      isSelected: false,
    }
    
  },
  watch: {
    isSelected(newV,oldV){
      this.$emit('file-selected', {action: newV, file: this.fname});
    }
  },
  methods: {
    viewFile(filename, ref) {
        // Prepara la URL con l'ID salato e i parametri di query
        const apiUrl = `file-view/${this._id}?filename=${encodeURIComponent(filename)}&to=${encodeURIComponent(this.path)}`;

        axios.get(apiUrl).then((response) => {
            if (response.data.msg.type == "success") {
                this.$store.dispatch("notificate", {
                    text: response.data.msg.text,
                    type: response.data.msg.type,
                });

                window.open(response.data.path);
            } else {
                this.$store.dispatch("notificate", {
                    text: response.data.msg.text,
                    type: response.data.msg.type,
                });
            }
        });
    },
    deleteFile(filename, selector) {
      var form = new FormData();
      form.append("id", this._id);
      form.append("filename", JSON.stringify(filename));
      form.append("selector", this.path);

      this.$confirm(
        "Sei sicuro di voler cancellare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios.post("delete-file/", form).then((response) => {
            //this.project.talk = response.data.timeline; AGGIORNARE CALLBACK STATO
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            if (selector == "ica") {
              //this.project.ica_files = response.data.files;
              //this.selected_ica_files = [];
            } else if (selector == "customer") {
              //this.project.customer_files = response.data.files;
              //this.selected_customer_files = [];
            }
          });
        }
      });
    },
  }
};
</script>