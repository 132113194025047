<template>
    <v-dialog
    v-model="user.patch_info"
    width="900"
    scrollable
    content-class="!tw-h-1/2 tw-bg-white"
    @click:outside="closePatchModal"
    >

        <v-card class="tw-flex tw-flex-col tw-p-8 tw-h-full">
            <div class="tw-text-2xl tw-font-bold tw-mb-8">Cosa c'è di nuovo (versione corrente: {{appVersion}})</div>
            <v-expansion-panels multiple v-model="panel" >
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.22.3
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            -  Aggiunta possibilità di cancellare calcolo BCT/ECT
                        </div>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            -  Aggiunta scheda per la creazione di progetti in realtà aumentata, visualizzabili tramite applicazione smartphone ( solo ICA )
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.20.1
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Inserito sezone calcolo BCT/ECT anche per Pack
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.20.0
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Inserito calcolo BCT/ECT (Solo ICA) all'interno del progetto
                        </div>
                        <ul class="tw-mt-2 tw-text-md tw-list-disc">
                            <li>Possibilità di calcolare BCT (statico, dinamico standard e straordinario), ECT e salvare dati nel database del progetto</li>
                        </ul>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Cliccando sull'icona dell'utente in alto a destra comparirà un menu a discesa con le voci 'Impostazioni', 'Note Patch' e 'Informazioni', 
                            precedentemente situate nella barra di navigazione in basso a sinistra
                        </div>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Ora la focus mode filtra i progetti in attesa in base all'operatore a cui sono stati assegnati (Solo ICA)
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.12.0
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            Inserita FOCUS MODE (Solo ICA)
                        </div>
                        <ul class="tw-mt-2 tw-text-md tw-list-disc">
                            <li>Il primo progetto della lista sarà l'unico lavorabile (in base all'ordine di priorità)</li>
                            <li>Gli altri progetti saranno in modalità sola lettura e oltre a visualizzare le informazioni sarà possibile gestire i preventivi</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.10.0
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Ridotta la velocità di caricamento della lista di progetti
                        </div>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Fix di un errore grafico nella lista degli allegati
                        </div>
                
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel  class="tw-flex tw-flex-col !tw-shadow-none">
                    <v-expansion-panel-header class="tw-text-xl tw-font-semibold">
                        Aggiornamento 1.9.9
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Aggiunto limite priorità progetti (Solo ICA)
                        </div>
                        <div class="tw-mt-2 tw-text-md ">
                            <div>Ogni commerciale avrà le seguenti limitazioni (reset a cadenza settimanale):</div>
                        </div>
                        <ul class="tw-mt-2 tw-text-md tw-list-disc">
                            <li>Limite progetti con priorità "Ordine in corso": 1</li>
                            <li>Limite progetti con priorità "Urgente": 2</li>
                        </ul>
                        <div class="tw-text-lg tw-font-medium tw-mt-2">
                            - Aggiornata visualizzazione barra di navigazione
                        </div>
                        <div class="tw-mt-2 tw-text-md ">
                            <div>Aggiunto testo per icone "impostazioni", "Informazioni" e inserita sezione "Note patch" per visualizzare i vari aggiornamenti</div>
                        </div>

                
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>

            <v-card-actions class="tw-mt-auto !tw-p-0">
                <v-spacer></v-spacer>
                <primary-btn @click.native="closePatchModal" text="Chiudi"></primary-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {version} from '../../package'
import axios from "axios"

export default {
    name: "PatchInfoModal",
    data(){
        return {
            appVersion: version,
            panel: [0]
        }
    },
    computed:{
        user:{
            get(){
                //getting value of patch info modal
                let user = this.$store.getters.getCurrentUser
                if (user){
                    return this.$store.getters.getCurrentUser
                }
                else {
                    return false
                }
            },
            set(){
            }
        },
    },
    methods: {
        closePatchModal(){
            axios.post("patch-close").then(response => {
                this.user.patch_info = false
            })
        }
    }
}
</script>