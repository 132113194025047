<template>
    
    <v-dialog 
        content-class="!tw-rounded-xl"
        v-model="isVisible"
        width="800px"
        scrollable
    >
        <v-card>
            <form class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 ar-form">
                <div class="tw-w-full tw-text-xl tw-font-bold tw-mb-8">Nuovo progetto Realtà Aumentata</div>
                <div class="tw-w-full  tw-flex tw-flex-col">
                    <label class="tw-mb-2">Nome progetto*</label>
                    <input-field    
                    :darker="true"
                    class="tw-w-full"    
                    type="text"
                    placeholder="Titolo"
                    v-model="ar_project_model.title"
                    required
                    ></input-field>   
                </div>                
                <div class="tw-w-full  tw-flex tw-flex-col tw-mt-4">
                    <label class="tw-mb-2">Descrizione progetto (opzionale)</label>
                    <textarea v-model="ar_project_model.description" class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-32 tw-box-border tw-p-4"></textarea>
                </div>     
                <div class="tw-w-1/2 tw-flex tw-flex-col tw-mt-4 tw-justify-center tw-pr-2">
                    <label class="tw-mb-2">Immagine di copertina (opzionale ma consigliata)</label>
                    <input    
                    :darker="true"
                    class="tw-w-full"    
                    type="file"
                    placeholder="Carica l'immagine"
                    ref="fileInput"
                    @change="getPreviewImg"
                    >
                </div>
                <div class="tw-w-1/2 tw-flex tw-flex-col tw-mt-4">
                    <img v-if="img_cover" :src="img_cover" class="tw-w-full tw-h-full tw-rounded-xl tw-box-border tw-border-grey tw-border-2">
                    <div class="tw-flex tw-justify-center tw-items-center tw-h-full tw-rounded-xl tw-box-border tw-border-grey tw-border-2 tw-p-4" v-else>Nessuna immagine selezionata</div>
                </div>
                <div class="tw-w-1/2 tw-flex tw-flex-col tw-mt-4 tw-justify-center tw-pr-2">
                    <label class="tw-mb-2">File 3D* (.glb/gltf)</label>
                    <input    
                    :darker="true"
                    class="tw-w-full"    
                    type="file"
                    placeholder="Carica l'immagine"
                    :value="ar_project_model.source"
                    required
                    @change="appendToForm"
                    >
                </div>
            </form>
            <v-card-actions>
              <v-spacer></v-spacer>
                <secondary-btn @click.native="$store.state.arProjectModal = false" class="tw-mr-4" text="Annulla"></secondary-btn>
                <primary-btn @click.native="createArProject" text="Crea"></primary-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
    
</template>

<script>

    import axios from 'axios';
import PrimaryBtn from '../ui/PrimaryBtn.vue';

    export default  {
  components: { PrimaryBtn },
        name: 'ArProjectModal',
        data(){
            return {
                ar_project_model: {
                    id: null,
                    customer: this.$store.state.project.customer,
                    title: this.$store.state.project.name,
                    reference: this.$store.state.project.name,
                    description: '',
                    source: null,
                    preview: null,
                    isVisible: true,
                    isSelected: false,
                    
                },
                img_cover: null,
                formData: new FormData(),
            }
        },
        computed: {
            isVisible: {
                get() {
                    return this.$store.state.arProjectModal;
                },
                set() {
                }
            }
        },
        methods: {
            checkForm(){
                var forms = document.getElementsByClassName('ar-form');
                for (var i = 0; i < forms.length; i++){
                    if (!forms[i].reportValidity()){
                    return false;
                    }
                }
                return true;
                
            },
            appendToForm(e){
                
                this.formData.append("source", e.target.files[0]);
            },
            getPreviewImg(e){
                let input = this.$refs.fileInput
                let file = input.files
                if (file && file[0]) {
                let reader = new FileReader
                    reader.onload = e => {
                        this.img_cover = e.target.result
                    }
                    reader.readAsDataURL(file[0])
                    this.ar_project_model.preview = file[0]
                    this.formData.append("preview",file[0])
                }
            },
            closeModal(){
                this.$store.state.arProjectModal = false;
            },
            createArProject(){
                if (this.checkForm()){
                    this.ar_project_model.id = this.$store.state.project.id;
                    this.formData.append("ar_project", JSON.stringify(this.ar_project_model))
                    axios.post('create-ar-project', this.formData).then(response => {
                        this.$store.state.project.ar_projects = response.data.ar_projects;
                        this.$store.dispatch("notificate", {
                            type: response.data.msg.type,
                            text: response.data.msg.text
                        });
                        this.$store.state.arProjectModal = false;
                    });
                }

            },
        }
    }
    
</script>