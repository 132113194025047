<template>
  <v-container
    fluid
  >
    <v-row
    >
      <v-col cols="12" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Dati</h1>
        </div>
      </v-col>   
      <v-col cols="2" class="tw-p-0">
        <v-col class="mid-h tw-p-0 !tw-pb-2">
          <v-card
          elevation="0"
          v-on:click="goToCustomers"
          class="card-icon !tw-rounded-lg hover:tw-opacity-80"
          >
          <v-icon
          class=" mid-h"
          size="50"
          color="#11797b"
          >
            mdi-account
          </v-icon>
            <v-card-title
            class="grow-text"
            
            >{{all_c}} Clienti</v-card-title>
            
          </v-card>
        </v-col>

        <v-col class="mid-h tw-p-0 !tw-pt-2">
          <v-card
          elevation="0"
          v-on:click="goToProjects"
          class="card-icon !tw-rounded-lg hover:tw-opacity-80"
          >
          <v-icon
          class="mid-h"
          size="50"
          color="#11797b"
          
          >
            mdi-table-edit
          </v-icon>
            <v-card-title
            class="grow-text"
            > {{all_p}} Progetti</v-card-title>
            <v-card-subtitle class="grow-text">Nuovi oggi: {{today_p}}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="10" >
        <v-row>
          <v-col cols="12" class="tw-p-0 tw-pl-4" >
            <v-col cols="12" class="elevation-0 tw-flex tw-bg-white tw-rounded-lg">
              <v-col cols="4">
                <v-date-picker
                  v-model="dates"
                  range
                  locale="it-IT"
                  elevation="0"
                  color="#11797b"
                  selected-items-text="2 selezionate"
                  class="tw-border-0 "
                  
                ></v-date-picker>
              </v-col>
              <v-col cols="8">
                <v-card
                elevation="0"
                >
                <v-card-title class="nopd">Seleziona il cliente</v-card-title>
                <custom-select  
                placeholder="Seleziona il Cliente"
                :clearable="false"  
                :options="clienti" 
                required
                class="tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer  tw-w-full !tw-border-0 select-style-chooser font2" 
                v-model="cliente" 
                  >
                </custom-select> 
                <v-card-title class="nopd tw-mt-4">Seleziona il commerciale</v-card-title>
                <custom-select  
                placeholder="Seleziona il Commerciale"
                :clearable="false" 
                :options="commerciali" 
                required
                class="tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer  tw-w-full !tw-border-0 select-style-chooser font2 tw-mb-8" 
                v-model="commerciale" 
                  >
                </custom-select> 
                <primary-btn :loading="loading_actions.report" text="Genera Report" @click.native="checkRange"></primary-btn>
                </v-card>
              </v-col>
            </v-col>
          </v-col>
        </v-row>


      </v-col>
    </v-row>
    <v-row>
      <v-col class="tw-p-0 tw-mt-4">
        <v-expansion-panels class="!tw-shadow-none" accordion>
          <v-expansion-panel class="!tw-shadow-none">
            <v-expansion-panel-header class="elevation-0 !tw-rounded-lg ">Visualizza i Report giornalieri</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Reports></Reports>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

  <v-dialog
      v-model="time_data_dialog"
      content-class="dl-time"
      scrollable
      max-width="800px"
      id="printalo"
    >

      <v-card height="100%" width="100%">
        <v-card-title class="headline grey lighten-2">
          Report
        </v-card-title>
        <v-card-subtitle class="headline grey lighten-2">
          <br>
          <br>
          Cliente: {{selected_cliente}}
          <br>
          <br>
          Commerciale: {{selected_commerciale}}
          <br>
          <br>
          Periodo di riferimento: {{dates[0]}} - {{dates[1]}}
        </v-card-subtitle>

          <v-row v-for="(block,index) in report" :key="index">
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <v-card
              elevation="3"
              >
                <v-card-title>Cliente: {{block.customer}} (Progetto N° {{index}})</v-card-title>
                <v-card-subtitle class="sbt">RIferimento: {{block.rif}}</v-card-subtitle>
                <v-card-text class="textolo" v-if="block.tracciato">
                  <p >Tempo totale lavorazione tracciati: {{Math.floor(block.tracciato.delta / 60 / 60) }} h {{Math.floor(block.tracciato.delta / 60) - (Math.floor(block.tracciato.delta / 60 / 60) * 60)}} m</p>
                </v-card-text>
                <v-card-text class="textolo" v-if="block.mastrino">
                  <p >Tempo totale lavorazione mastrini: {{Math.floor(block.mastrino.delta / 60 / 60) }} h {{Math.floor(block.mastrino.delta / 60) - (Math.floor(block.mastrino.delta / 60 / 60) * 60)}} m</p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="1"></v-col>
          </v-row>
          <v-row>

            <v-col cols="1"></v-col>
            <v-col cols="10">
              
              
              <v-card-title  class="tot-parziale">Totale tempo lavorazione tracciati: <br>{{time_tracciato_h }} h {{time_tracciato_m }} m</v-card-title>

              <v-card-title  class="tot-parziale">Totale tempo lavorazione mastrini: <br>{{time_mastrino_h }} h {{time_mastrino_m }} m</v-card-title>

              <v-divider></v-divider>
              <v-card-title class="tot">Totale: <br> {{ tot_h }} h {{ tot_m }} m</v-card-title>
        
            </v-col>

            <v-col cols="1"></v-col>

          </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="time_data_dialog = false"

          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <response-notification></response-notification>
  </v-container>
  
</template>

<script>
import axios from 'axios'
import Reports from './Reports.vue'
//import VueApexCharts from 'vue-apexcharts'

    
  export default {
    name: 'Home',
    components: {
      Reports
    },
    data(){
      return {
        loading_actions: {
          report: false,
        },
        version: "",
        success:false,
        response_type:'warning',
        time_data_dialog: false,
        l_created : 0,
        role : '',
        commerciali: ["Tutti"],
        commerciale: '',
        grafici: ["Tutti","Jacopo","Marco"],
        grafico: '',
        clienti: ["Tutti"],
        cliente: '',
        all_p:0,
        all_c:0,
        dates: [],
        report: {},
        time_tracciato_h: '',
        time_tracciato_m: '',
        time_mastrino_h: '',
        time_mastrino_m: '',
        tot_h: '',
        tot_m: '',
        selected_cliente:'',
        success_text: '',
        selected_commerciale:'',
        today_p:0,
        chartOptions: {
          chart: {
            id: 'bar'
          },
          xaxis: {
            type: 'datetime',
            categories: [],

          },
          yaxis: {
          labels: {

            formatter: (value) => { 
                
                var h = Math.floor(value / 60 / 60);
                var m = Math.floor(value / 60) - (h * 60);

                if (h == 0){
                    return m + " m"
                }
                else{
                    return h + ',' + m + "h"
                }
            }   
        },
          },
        dataLabels: {
          enabled: false,
        }
        },

        series: [{
          name: 'Tempo di lavoro',
          data: [1]
        }]        
        }
      },

    
  beforeCreate: function () {
    axios.get("home")
    .then((response) => {
      if (response.data.status != "logged_in"){
        this.$router.push('/login').catch(()=>{}); 
      }
      if (response.data.alert == 'not allowed'){
          this.$router.push('/production').catch(()=>{}); 
      }

      this.l_created = response.data.created_p
      this.all_p = response.data.all_p
      this.all_c = response.data.all_c.length
      this.role = response.data.role
      this.today_p = response.data.today_p

      for (var i = 0; i<response.data.all_c.length; i++){
        this.clienti.push(response.data.all_c[i])
      }
      for (var j = 0; j<response.data.commerciali.length; j++){
        this.commerciali.push(response.data.commerciali[j])
      }

    })
    .catch(err => {
      console.log(err)
    })
      
    },

    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      }
    },

    methods: {
      goToCustomers(){
         this.$router.push('/customers').catch(()=>{});

      },
      goToProjects(){
         this.$router.push('/projects').catch(()=>{});

      },

      checkRange(){
        this.loading_actions.report = true
        var date_form = new FormData();

        date_form.append("range", this.dates);
        date_form.append("commerciale", this.commerciale);
        date_form.append("customer", this.cliente);
        date_form.append("grafico", this.grafico);

        console.log(date_form)

        if (this.dates.length == 2){
          axios.post("home", date_form)
            .then((response) => {
              if (response.data.report){
                this.report = response.data.report;
                this.calcPartial()

                this.selected_cliente = response.data.selected_cliente
                this.selected_commerciale = response.data.selected_commerciale

                if (Object.keys(this.report).length > 0){
                  this.time_data_dialog = true
                }                
              }
              else {
                this.$store.dispatch("notificate",{"text":"Non ci sono report da visualizzare","type":"error"})
              }
              this.loading_actions.report = false
          })
        }

        else {
          this.$store.dispatch("notificate",{"text":"Seleziona due date prima di continuare","type":"warning"})
          this.loading_actions.report = true
        }
        
      },

      calcPartial(){
        var delta_t = 0

        for (let [key, value] of Object.entries(this.report)){
          console.log(key)

          if (value.tracciato) {
            delta_t = delta_t + value.tracciato.delta;
          }
        }
        this.time_tracciato_h = Math.floor(delta_t / 60 / 60) 
        this.time_tracciato_m = Math.floor(delta_t / 60) - (this.time_tracciato_h * 60)
        var delta_m = 0
        for (let [key, value] of Object.entries(this.report)){
          console.log(key)
          if (value.mastrino) {
            delta_m = delta_m + value.mastrino.delta;
          }
        }
        this.time_mastrino_h = Math.floor(delta_m / 60 / 60) 
        this.time_mastrino_m = Math.floor(delta_m / 60) - (this.time_mastrino_h * 60)
        if (this.time_tracciato != 0 && this.time_mastrino != 0){
          var tot = delta_t + delta_m
          this.tot_h = Math.floor(tot / 60 / 60) 
          this.tot_m = Math.floor(tot / 60) - (this.tot_h * 60)

        }

      },
    }
    
}
  
  
</script>

<style scoped>

.pd-24 {

  padding-left: 24px;
}

.onsuccess, .testolo {
  

  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

  .full-height {

    height: 100% !important;
  }


  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 50%;
  } 



  .grow-text {

    font-size: 20px;
    height: 10%;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .mid-h {

    height: 50%;
    
  }

  .card-icon {

    min-height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  .version {
    position: fixed;
    bottom: 2%;
    right: 2%;
    opacity: 0.6;
    font-size: 13px;
    text-align: right;

  }

  .dl-time {

    background-color: white;
  }

  .nopd {

    padding: 0 !important;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }

</style>
