<template>
        <div @click="isEditing = true" v-if="!isEditing" class="plus-time"><v-icon class="pointer">mdi-plus-circle</v-icon></div>
        <div class="bx mw" v-else >
            <v-row>
                <v-col cols="12">

                        <v-stepper v-model="stepper">
                            <v-stepper-header>
                            <v-stepper-step
                                :complete="stepper > 1"
                                step="1"
                            >
                                Seleziona la data
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="stepper > 2"
                                step="2"
                            >
                                Seleziona durata lavorazione (H:M)
                            </v-stepper-step>

                            <v-divider></v-divider>

                            </v-stepper-header>

                            <v-stepper-items>
                            <v-stepper-content step="1">
                                <VueCtkDateTimePicker inline only-date  label="Seleziona data" no-button-now format="D-M-YYYY 00:00" class="mw" v-model="datetime_start" />

                                <v-btn
                                color="primary"
                                @click="goNext(1,datetime_start)"
                                >
                                Continua
                                </v-btn>

                                <v-btn @click="isEditing = false" text>
                                Annulla
                                </v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-text-field  label="Seleziona durata lavorazione" type="time" value="00:00" v-model="delta" ></v-text-field>

                                <v-btn
                                color="primary"
                                @click="goNext(2,delta)"
                                >
                                Continua
                                </v-btn>

                            </v-stepper-content>

                            </v-stepper-items>
                        </v-stepper>
                </v-col>
                
            </v-row>
            

        </div>
</template>

<script>
import axios from 'axios'
import { bus } from '../main'

export default {
    name: 'PlusTime',
    props: {
        isEditingTime: {
            type: Boolean,
            default: false
            },
        task_type: {
            type: String,
            default: ''
        },
        project_id: {
            type: Number,
            default: 0
        }
    
    },
    data(){

        return{
            isEditing : this.isEditingTime,
            datetime_end: undefined,
            datetime_start: '',
            delta: '',
            stepper: 1,
        }
    },
    methods:{
        goNext(step,data,task){



            console.log("entrato")
            var form_belo = new FormData();
            task = this.task_type

            if (data != ''){
               
                if (step == 1 ){
                    this.datetime_date = data
                    form_belo.append("start",this.datetime_date)

                    axios.post('task/' + task + '/' + this.project_id, form_belo )
                    
                    .then((response) => {
                    
                    })   
                }
                else if(step == 2){
                    this.delta == data

                    var splitted_start = this.datetime_start.split(" ")[0]
                    this.datetime_end = splitted_start + ' ' + this.delta
 
                    form_belo.append("end",this.datetime_end)

                    axios.post('task/' + task + '/' + this.project_id, form_belo )
                    
                    .then((response) => {
                    
                    }) 
                    console.log(this.datetime_start)
                    console.log(this.datetime_end)

                    if (this.delta[0] == 0){
                        this.delta = this.delta.replace(this.delta[0],"") + ":00"
                    }

                    console.log(this.delta)

                    this.isEditing = false

                    bus.$emit('getTimes');
                   
                    
                }
                if (step != 2){
                    step = step + 1
                }
                else {
                     step = 1
                }
            }

        this.stepper = step
        

        }
    }
}
</script>

<style scoped>

    .bx {

        box-sizing: border-box;
        padding: 2px;
    }

    .mw {
        width: 100%;
    }

    .pointer {
        cursor: pointer;
    }
    

    .plus-time  {
        width: 100%;
        z-index: 2;
        text-align: center;
        margin-bottom: 5px;
        background-color: white;
    
    }

</style>