<template>
    <div class="tw-w-full tw-h-screen tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-bg-dark">
        <div v-if="isAuthorized" class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
            <div class="tw-bg-white tw-rounded-xl tw-flex-col tw-w-1/5">
                <form class="pwd-form tw-p-6">
                    <v-row>
                        <v-col cols="12">
                            <h2 class="tw-font-semibold tw-text-xl text-center">{{username}}, conferma la tua password</h2>
                        </v-col>
                        <v-col cols="10">
                            <v-text-field
                            required
                            v-model="password"
                            label="Inserisci la nuova password"
                            :type="pwdType"
                            >

                            </v-text-field>
                        </v-col>                
                        <v-col class="d-flex justify-center align-center" cols="2">
                            <span><v-icon @click="() => {iswdVisible = !isPwdVisible; pwdType == 'password' ? pwdType = 'text' : pwdType = 'password'}" class="pw">{{displayIcon(isPwdVisible)}}</v-icon></span>
                        </v-col>
                        <v-col cols="10">
                            <v-text-field
                            :type="pwdConfirmType"
                            required
                            v-model="confirmPassword"
                            label="Conferma Password"
                            >
                            </v-text-field>
                            
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="2">
                            <span><v-icon @click="() => {isConfirmPwdVisible = !isConfirmPwdVisible; pwdConfirmType == 'password' ? pwdConfirmType = 'text' : pwdConfirmType = 'password'}" class="pw">{{displayIcon(isConfirmPwdVisible)}}</v-icon></span>
                        </v-col>
                        <v-col cols="12">
                            <primary-btn :loading="loading"  @click.native="changePassword" text="Conferma" />
                        </v-col>
                        
                    </v-row>
                </form>
            </div>
        </div>
        <div v-else class="tw-w-full tw-h-screen tw-flex tw-items-center tw-justify-center">
            <div  class="tw-flex tw-flex-col tw-items-center">
                <div class="tw-text-2xl tw-text-white tw-mb-6 text-center">Link scaduto,<br> torna alla pagina di accesso</div>
                <primary-btn :loading="loading" :block="true" @click.native="$router.push('login').catch(()=>{})" text="Torna al login" />
            </div>
        </div>
        <response-notification></response-notification>
    </div>
    
</template>

<script>
    import axios from 'axios'
    import { checkForm } from '../plugins/utils'

    export default {
        name: "ChangePassword",
        data() {
            return {
                loading: false,
                isAuthorized: false,
                username: null,
                password: null,
                confirmPassword: null,
                isPwdVisible: false,
                isConfirmPwdVisible: false,
                pwdType: 'password',
                pwdConfirmType: 'password'
                
            }
        },
        mounted(){
            axios.get('reset-password').then((response) => {
                this.isAuthorized = true
                this.username = response.data.user.username
            })
            .catch(err => {
                console.log(err)
            })
        },
        methods: {
            displayIcon(item){
                return item ? "mdi-eye-off" : "mdi-eye"
            },
            changePassword(){
                if(checkForm('pwd-form')){
                    if(this.password.length > 7){
                        if(this.password == this.confirmPassword){
                            this.loading = true
                            axios.post("reset-password", {username: this.username, password: this.password}).then((response) => {
                                this.$store.dispatch("notificate",{text:"Password modificata correttamente, accedi con le tue nuove credenziali", type:"success"})
                                this.loading = false
                                this.$router.push("login").catch(()=>{})
                            })  
                            .catch(err => {
                                this.loading = false
                                if (err.response.status == 401){
                                    this.$store.dispatch("notificate",{text:"Link scaduto, torna alla pagina di accesso", type:"error"})
                                }

                            })
                        }
                        else {
                            this.$store.dispatch("notificate",{text:"Le password non combaciano", type:"error"})
                        }
                    }
                    else {
                        this.$store.dispatch("notificate",{text:"Password troppo corta", type:"error"})
                    }


                }

            }
        }
    }
</script>