<template>
    <div>
        <v-chip @click="$router.push('/quotation').catch(() => {})" class="!tw-text-white !tw-bg-custom-red !tw-bg-opacity-60 tw-mr-2">{{text}} {{counter}}</v-chip>
    </div>
</template>

<script>
export default {
    name: "DraftCounter",
    props: ["counter","text"]
}
</script>