<template>
  <v-dialog
    v-model="isVisible"
    background-color="white"
    persistent
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card elevation="0" v-if="manufactureData">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Produzione</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="
              selectedManufacture.canComplete && selectedManufacture.state != 3
            "
            dark
            :loading="isSaving"
            @click.native="patchManufactures((global = true))"
            class="!tw-bg-green"
            >Completa</v-btn
          >
          <v-btn dark text @click="closeDialog"> Chiudi </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="sm!tw-p-12 tw-p-4" v-if="!editingManufacture">
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <div>
          <div class="tw-w-full tw-text-2xl tw-font-bold tw-mb-6">
            Dati Produzione
          </div>
          <div class="tw-text-lg tw-my-2">
            <b>Codice Articolo: </b>{{ info.CodiceArticolo }}
          </div>
          <div class="tw-text-lg tw-my-2">
            <b>Cliente: </b>{{ info.RagioneSociale }}
          </div>
          <div class="tw-text-lg tw-my-2">
            <b>Riferimento: </b>{{ info.Descrizione }}
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-mt-6">
          <div class="tw-w-full tw-text-2xl tw-font-bold tw-mb-6">
            Visualizza file
          </div>
          <div v-if="path" class="tw-flex tw-flex-wrap">
            <div
              v-for="(k, v) in Object.keys(path)"
              :key="v"
              class="tw-flex tw-w-full sm:tw-w-1/4 tw-justify-between tw-items-center"
            >
              <div
                class="tw-text-xl tw-flex tw-items-center tw-justify-between sm:tw-justify-start tw-w-full sm:tw-w-auto tw-mt-2 sm:tw-mt-0"
              >
                <div>
                  {{ getTitle(k) }}
                </div>
                <primary-btn
                  text="Visualizza"
                  class="tw-ml-2"
                  @click.native="openFile(path[k])"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="tw-h-full tw-w-full tw-flex tw-flex-wrap mt-12 tw-justify-center">
          <div class="tw-text-2xl tw-font-bold tw-w-full mb-6">Lavorazioni</div>
          <v-card
            elevation="0"
            :class="`${getManufactureWidth()} !tw-w-full tw-h-full !tw-px-4 !tw-bg-transparent`"
            v-for="(item, index) in selectedManufacture.manufactures
              ? Object.keys(selectedManufacture.manufactures)
              : []"
            :key="index"
          >
            <v-card
              v-if="manufactureData"
              class="!tw-p-4 !tw-h-full !tw-overflow-y-scroll"
            >
              <div class="tw-text-2xl tw-font-bold tw-mt-4 tw-mb-6">
                {{ item == "generic" ? "Generica" : item }}
              </div>
              <form :class="'tw-mt-8 form-prod-' + item">
                <div class="tw-w-full">
                  <div
                    v-if="info.Commenti && info.NoteConsegna"
                    class="tw-font-medium tw-text-2xl tw-mb-2"
                  >
                    Note Commessa:
                  </div>
                  <div
                    v-if="info.Commenti"
                    class="tw-my-2 tw-flex tw-items-center"
                  >
                    <b>Note Commessa: </b>{{ info.Commenti }}
                    <v-checkbox
                      required
                      v-if="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                      "
                      v-model="notesConfirm"
                      :value="info.Commenti"
                      label="Conferma lettura"
                      class="tw-ml-4"
                    ></v-checkbox>
                  </div>
                  <div
                    v-if="info.NoteConsegna"
                    class="tw-my-2 tw-flex tw-items-center"
                  >
                    <b>Note Consegna: </b>{{ info.NoteConsegna }}
                    <v-checkbox
                      required
                      v-if="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                      "
                      v-model="notesConfirm"
                      :value="info.NoteConsegna"
                      label="Conferma lettura"
                      class="tw-ml-4"
                    ></v-checkbox>
                  </div>
                  <div
                    v-if="info.NoteLavCom"
                    class="tw-my-2 !tw-mb-6 tw-flex tw-items-center"
                  >
                    <b>Note Lavorazione Consegna: </b>{{ info.NoteLavCom }}
                    <v-checkbox
                      required
                      v-if="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                      "
                      v-model="notesConfirm"
                      :value="info.NoteLavCom"
                      label="Conferma lettura"
                      class="tw-ml-4"
                    ></v-checkbox>
                  </div>

                  <div
                    v-if="
                      stockManufactures.filter((item) => item.NoteAvanz)
                        .length > 0
                    "
                    class="tw-font-semibold tw-text-2xl tw-my-4"
                  >
                    Note Lavorazione:
                  </div>
                  <div v-for="(man, index) in stockManufactures" :key="index">
                    <div
                      v-if="man.NoteAvanz"
                      class="tw-my-2 tw-flex tw-items-center"
                    >
                      <b>Note Lavorazione {{ man.Risorsa }}: </b
                      >{{ man.NoteAvanz }}
                      <v-checkbox
                        required
                        v-if="man.NoteAvanz"
                        v-model="notesConfirm"
                        :value="index"
                        label="Conferma lettura"
                        class="tw-ml-4"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    manufactureStatus(item) == 0 ||
                    manufactureStatus(item) == 2 ||
                    manufactureStatus(item) == 4
                  "
                >
                  <div
                    class="tw-w-2/4 tw-flex"
                    v-for="(op, index) in operator[item]"
                    :key="index"
                  >
                    <div class="tw-flex-1">
                      <div class="tw-font-semibold">
                        Operatore {{ index + 1 }}
                      </div>
                      <custom-select
                        :clearable="false"
                        :options="operatorList"
                        class="select-dis !tw-bg-grey tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                        v-model="operator[item][index]"
                      >
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :disabled="
                              user.role != 'magazzino' && user.role != 'worker'
                                ? true
                                : false
                            "
                            :required="!operator[item][index]"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </custom-select>
                    </div>
                    <div
                      v-if="index != 0"
                      class="tw-pl-2 tw-flex tw-items-center"
                    >
                      <v-icon
                        @click="operator[item].splice(index, 1)"
                        class="!tw-text-custom-red"
                        >mdi-delete</v-icon
                      >
                    </div>
                  </div>
                  <div class="tw-w-1/4 tw-pl-2 tw-flex tw-items-center">
                    <v-icon
                      @click="operator[item].push(undefined)"
                      class="!tw-text-custom-blue"
                      >mdi-plus</v-icon
                    >
                  </div>
                </div>
                <div
                  class="tw-flex tw-flex-wrap"
                  v-if="manufactureStatus(item) == 1"
                >
                  <div class="tw-w-1/2">
                    <div class="tw-font-semibold">Prodotte</div>
                    <input-field
                      class="tw-my-4 tw-min-w-full"
                      type="number"
                      v-model="produced[item]"
                      placeholder="Inserisci quantità prodotte"
                      required
                      :darker="true"
                    ></input-field>
                  </div>
                  <div class="tw-w-1/2 tw-pl-2">
                    <div class="tw-font-semibold">Scarti</div>
                    <input-field
                      class="tw-my-4 tw-min-w-full"
                      type="number"
                      v-model="refused[item]"
                      placeholder="Inserisci scarti"
                      required
                      :darker="true"
                    ></input-field>
                  </div>
                  <div class="tw-w-full tw-mt-2">
                    <div class="tw-font-semibold">Note</div>
                    <input-field
                      class="tw-my-4 tw-min-w-full"
                      type="text"
                      v-model="comments[item]"
                      placeholder="Inserisci note"
                      :darker="true"
                    ></input-field>
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-flex-wrap tw-mt-2"
                    v-if="
                      manufactureStatus(item) == 1 &&
                      item ==
                        Object.keys(selectedManufacture.manufactures)[
                          Object.keys(selectedManufacture.manufactures).length -
                            1
                        ]
                    "
                  >
                    <div
                      class="tw-w-full tw-flex tw-flex-row tw-flex-wrap tw-items-center"
                      v-for="(pal, index) in pallet"
                      :key="index"
                    >
                      <div class="tw-flex-col tw-w-1/3 tw-flex">
                        <div class="tw-font-semibold">N° bancali</div>
                        <div class="tw-flex">
                          <input-field
                            required
                            class="tw-my-4 tw-min-w-full"
                            type="number"
                            v-model="pal.nBanc"
                            placeholder="Inserisci N° bancali"
                            :darker="true"
                          ></input-field>
                        </div>
                      </div>
                      <div class="tw-flex-col tw-w-1/3 tw-flex tw-pl-2">
                        <div class="tw-font-semibold">Pezzi per bancale</div>
                        <div class="tw-flex">
                          <input-field
                            required
                            type="number"
                            class="tw-my-4 tw-min-w-full"
                            v-model="pal.qtyxBanc"
                            placeholder="Inserisci Pezzi per bancale"
                            :darker="true"
                          ></input-field>
                        </div>
                      </div>
                      <div
                        v-if="index != 0"
                        class="tw-pl-2 tw-flex tw-items-center"
                      >
                        <v-icon
                          @click="pallet.splice(index, 1)"
                          class="!tw-text-custom-red"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </div>
                    <div class="tw-w-1/4 tw-pl-2 tw-flex tw-items-center">
                      <v-icon
                        @click="calcNewPallet(item)"
                        class="!tw-text-custom-blue"
                        >mdi-plus</v-icon
                      >
                    </div>
                  </div>
                </div>

                <div class="tw-w-full tw-my-6">
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-data-table
                    ref="table"
                    hide-default-footer
                    :headers="headers"
                    :items="
                      selectedManufacture.manufactures
                        ? selectedManufacture.manufactures[item]
                        : []
                    "
                    :items-per-page="99999999999"
                    class="elevation-1 tw-max-h-60 tw-overflow-y-scroll"
                  >
                    <template v-slot:item.start_time="{ item }">
                      <td v-if="item.start_time">
                        {{
                          moment
                            .unix(item.start_time)
                            .locale("it")
                            .format("LLL")
                        }}
                      </td>
                    </template>
                    <template v-slot:item.end_time="{ item }">
                      <td v-if="item.end_time">
                        {{
                          moment.unix(item.end_time).locale("it").format("LLL")
                        }}
                      </td>
                    </template>
                    <template v-slot:item.manufactures="{ obj, index }">
                      <td v-if="!isEditing">
                        <v-icon
                          v-if="
                            selectedManufacture.manufactures[item][0][
                              'end_time'
                            ]
                          "
                          @click="
                            editField(
                              selectedManufacture.manufactures[item][index],
                              index,
                              item
                            )
                          "
                          class="tw-cursor-pointer"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          v-if="
                            user.role != 'magazzino' &&
                            user.role != 'worker' &&
                            selectedManufacture.manufactures[item][0][
                              'end_time'
                            ]
                          "
                          @click="deleteProduction(selectedManufacture.manufactures[item][index], item)"
                          class="tw-cursor-pointer tw-ml-2"
                          >mdi-delete</v-icon
                        >
                      </td>
                      <td v-else>
                        <v-progress-circular
                          indeterminate
                          color="#2F2F2F"
                        ></v-progress-circular>
                      </td>
                    </template>
                  </v-data-table>
                </div>

                <div v-if="manufactureStatus(item) != 3">
                  <div
                    v-if="user.role == 'magazzino' || user.role == 'worker'"
                    class="tw-w-full tw-pl-2 tw-mt-4 tw-flex tw-items-center tw-mb-4"
                  >
                    <primary-btn
                      :loading="loadings[0]"
                      :disabled="
                        manufactureStatus(item) != 0 &&
                        manufactureStatus(item) != 4 &&
                        manufactureStatus(item) != 2
                      "
                      text="Inizia"
                      @click.native="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                          ? handleProduction(1, item)
                          : false
                      "
                      class="tw-mr-2"
                    />
                    <primary-btn
                      v-if="
                        !produced[item] &&
                        !refused[item] &&
                        !pallet[0].nBanc &&
                        !pallet[0].qtyxBanc
                      "
                      :disabled="manufactureStatus(item) != 1"
                      text="Pausa"
                      @click.native="
                        manufactureStatus(item) != 1
                          ? false
                          : handleProduction(4, item)
                      "
                      class="tw-mr-2"
                    />
                    <primary-btn
                      :loading="loadings[1]"
                      :disabled="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                          ? true
                          : false
                      "
                      text="Fine ciclo"
                      @click.native="
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 2 ||
                        manufactureStatus(item) == 4
                          ? false
                          : handleProduction(2, item)
                      "
                      class="tw-mr-2"
                    />
                    <primary-btn
                      :loading="loadings[2]"
                      :disabled="
                        manufactureStatus(item) == 1 ||
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 4
                          ? true
                          : false
                      "
                      text="Termina"
                      @click.native="
                        manufactureStatus(item) == 1 ||
                        manufactureStatus(item) == 0 ||
                        manufactureStatus(item) == 4
                          ? false
                          : handleProduction(3, item)
                      "
                    />
                  </div>
                </div>

                <div v-else>
                  <div>Lavorazione Completata</div>
                </div>
              </form>
            </v-card>
          </v-card>
        </div>
      </div>
      <div v-else>
        <div class="tw-w-full tw-flex tw-mt-6 tw-mb-2 tw-px-12">
          <v-icon
            @click="editingManufacture = null"
            class="!tw-cursor-pointer hover:!tw-text-dark"
            size="30"
            >mdi-chevron-left</v-icon
          >
        </div>
        <form class="tw-mt-8 form-prod sm:tw-p-12 tw-p-4">
          <div class="tw-w-full tw-text-2xl tw-font-bold tw-mt-2 tw-mb-6">
            Modifica Ciclo di Produzione
          </div>
          <div>
            <div
              class="tw-w-2/4 tw-flex"
              v-for="(op, index) in editingManufacture.operator"
              :key="index"
            >
              <div class="tw-flex-1">
                <div class="tw-font-semibold">Operatore {{ index + 1 }}</div>
                <custom-select
                  :clearable="false"
                  :options="operatorList"
                  :disabled="
                    user.role != 'magazzino' && user.role != 'worker'
                      ? true
                      : false
                  "
                  required
                  class="select-dis tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                  v-model="editingManufacture.operator[index]"
                >
                </custom-select>
              </div>
              <div v-if="index != 0" class="tw-pl-2 tw-flex tw-items-center">
                <v-icon
                  @click="editingManufacture.operator.splice(index, 1)"
                  class="!tw-text-custom-red"
                  >mdi-delete</v-icon
                >
              </div>
            </div>
            <div class="tw-w-1/4 tw-pl-2 tw-flex tw-items-center">
              <v-icon
                @click="editingManufacture.operator.push(undefined)"
                class="!tw-text-custom-blue"
                >mdi-plus</v-icon
              >
            </div>
          </div>
          <div class="tw-flex tw-flex-wrap">
            <div class="tw-w-1/2">
              <div class="tw-font-semibold">Prodotte</div>
              <input-field
                class="tw-my-4 tw-min-w-full"
                type="number"
                v-model="editingManufacture.produced"
                placeholder="Inserisci quantità prodotte"
                required
                :darker="true"
              ></input-field>
            </div>
            <div class="tw-w-1/2 tw-pl-2">
              <div class="tw-font-semibold">Scarti</div>
              <input-field
                class="tw-my-4 tw-min-w-full"
                type="number"
                v-model="editingManufacture.refused"
                placeholder="Inserisci scarti"
                required
                :darker="true"
              ></input-field>
            </div>
            <div class="tw-w-full tw-mt-2">
              <div class="tw-font-semibold">Note</div>
              <input-field
                class="tw-my-4 tw-min-w-full"
                type="text"
                v-model="editingManufacture.comments"
                placeholder="Inserisci note"
                :darker="true"
              ></input-field>
              <div
                class="tw-w-full tw-flex tw-flex-wrap tw-mt-2"
                v-if="editingManufacture.pallet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-row tw-flex-wrap tw-items-center"
                  v-for="(pal, index) in editingManufacture.pallet"
                  :key="index"
                >
                  <div class="tw-flex-col tw-w-1/3 tw-flex">
                    <div class="tw-font-semibold">N° bancali</div>
                    <div class="tw-flex">
                      <input-field
                        class="tw-my-4 tw-min-w-full"
                        type="number"
                        v-model="pal.nBanc"
                        placeholder="Inserisci N° bancali"
                        :darker="true"
                      ></input-field>
                    </div>
                  </div>
                  <div class="tw-flex-col tw-w-1/3 tw-flex tw-pl-2">
                    <div class="tw-font-semibold">Pezzi per bancale</div>
                    <div class="tw-flex">
                      <input-field
                        class="tw-my-4 tw-min-w-full"
                        type="number"
                        v-model="pal.qtyxBanc"
                        placeholder="Inserisci Pezzi per bancale"
                        :darker="true"
                      ></input-field>
                    </div>
                  </div>
                  <div
                    v-if="index != 0"
                    class="tw-pl-2 tw-flex tw-items-center"
                  >
                    <v-icon
                      @click="editingManufacture.pallet.splice(index, 1)"
                      class="!tw-text-custom-red"
                      >mdi-delete</v-icon
                    >
                  </div>
                </div>
                <div class="tw-w-1/4 tw-pl-2 tw-flex tw-items-center">
                  <v-icon
                    @click="
                      editingManufacture.pallet.push({
                        nBanc: null,
                        qtyxBanc: null,
                      })
                    "
                    class="!tw-text-custom-blue"
                    >mdi-plus</v-icon
                  >
                </div>
              </div>
            </div>
            <div class="tw-w-full tw-pl-2">
              <div class="tw-font-semibold tw-my-2">Modifica Orario</div>
              <div class="tw-flex tw-justify-between tw-w-full">
                <div class="tw-flex-col tw-w-1/2 tw-mr-2">
                  <div class="tw-mb-1">Inizio</div>
                  <VueCtkDateTimePicker
                    position="relative"
                    label="Seleziona data e ora"
                    :max-date="limitDate"
                    format="DD-MM-YYYY, HH:mm"
                    v-model="startTime"
                    dark
                    locale="it"
                  />
                </div>
                <div class="tw-flex-col tw-w-1/2 tw-ml-2">
                  <div class="tw-mb-1">Fine</div>
                  <VueCtkDateTimePicker
                    position="relative"
                    label="Seleziona data e ora"
                    :max-date="limitDate"
                    format="DD-MM-YYYY, HH:mm"
                    v-model="endTime"
                    dark
                    locale="it"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="tw-w-full tw-my-6">
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </form>
        <div class="tw-flex tw-justify-end tw-mb-8 tw-w-full tw-px-12">
          <secondary-btn
            @click.native="editingManufacture = null"
            text="Annulla"
            class="tw-mr-4"
          />
          <primary-btn
            :loading="isSaving"
            @click.native="isSaving ? null : patchManufactures()"
            text="Salva"
          />
        </div>
      </div>
    </v-card>
    <response-notification class="!tw-w-auto tw-z-50"></response-notification>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios";
import moment from "moment";

//import InputField from '../ui/InputField.vue'
//import print from 'print-js'

export default {
  components: {},
  name: "ManufactureModal",
  props: ["is_visible", "manufactureData"],
  data() {
    return {
      manufactureWindow: {},
      manufacturesType: "PLOTTER",
      isEditing: false,
      notesConfirm: [],
      stockManufactures: [],
      operatorList: ["Matteo", "Nicola", "Salvatore", "Emanuele"],
      headers: [
        { text: "Data inizio", value: "start_time" },
        { text: "Data fine", value: "end_time" },
        { text: "Operatori", value: "operator" },
        { text: "Prodotto", value: "produced" },
        { text: "Scarto", value: "refused" },
        { text: "Azioni", value: "manufactures" },
      ],
      panel: [0, 1, 2],
      isLoading: false,
      isSaving: false,
      noteConfirm: false,
      operator: {},
      pallet: [{ nBanc: null, qtyxBanc: null }],
      isStarting: false,
      selectedManufacture: {},
      manufacture: {},
      path: [],
      produced: {},
      refused: {},
      loadings: [false, false, false],
      editingManufacture: null,
      startTime: null,
      endTime: null,
      limitDate: null,
      comments: {},
    };
  },
  computed: {
    isVisible() {
      if (this.is_visible) {
        this.fetchManufactures();
      } else {
        this.resetData();
        //this.resetManufacures()
      }
      return this.is_visible;
    },
    info() {
      return this.manufactureData;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    getManufactureWidth() {
      const width = Object.keys(this.selectedManufacture.manufactures).length;
      if (width == 3) return 'sm:!tw-w-1/3';
      if (width == 2) return 'sm:!tw-w-1/2';
      if (width == 1) return 'sm:!tw-w-1/2';
      return 'sm:!tw-w-1/3';
    },
    preserveManfucatureOrder(manufactures) {
      let manTypes = {};
      const order = [
        "PLOTTER",
        "PLATINA",
        "AUTOPLATINA",
        "LAVORAZIONI MANUALI",
      ];

      for (let i = 0; i < order.length; i++) {
        const key = order[i];
        if (key in manufactures) {
          if (key !== "generic") {
            manTypes[key] = manufactures[key];
          } else {
            manTypes["GENERICA"] = manufactures[key]; // Adjust based on your needs
          }
        }
      }

      return manTypes;
    },
    manufactureStatus(item) {
      return this.selectedManufacture.manufactures[item][
        this.selectedManufacture.manufactures[item].length - 1
      ].state;
    },
    calcNewPallet(manType) {
      let currentPallet = [...this.pallet];
      let newCalc = {};
      if (
        this.produced[manType] > 0 &&
        currentPallet[0].nBanc > 0 &&
        currentPallet[0].qtyxBanc > 0
      ) {
        newCalc.nBanc = 1;
        newCalc.qtyxBanc =
          this.produced[manType] -
          currentPallet[0].nBanc * currentPallet[0].qtyxBanc;
        currentPallet.push(newCalc);
        this.pallet = currentPallet;
      } else {
        currentPallet.push({ nBanc: null, qtyxBanc: null });
      }
    },
    calculateWindowDistance() {
      let existings = [];
      Object.keys(this.manufactureWindow).forEach((key) => {
        if (this.manufactureWindow[key])
          existings.push(this.manufactureWindow[key]);
      });
    },
    deleteProduction(item, manType) {
      let form = { item: item, metadata: { ...this.info }, manType: manType };
      console.log(form)
      this.$confirm(
        "Sei sicuro di voler cancellare il ciclo di produzione?"
      ).then((res) => {
        this.isEditing = true;
        if (res) {
          axios
            .post("handle-productions/" + this.info.CodiceOrdProd, form)
            .then((res) => {
              this.selectedManufacture = res.data.data;

              this.$store.dispatch("notificate", {
                text: "Ciclo di produzione cancellato correttamente",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore, contattare un amministratore",
                type: "error",
              });
            })
            .finally(() => {
              this.isEditing = false;
            });
        }
      });
    },
    patchManufactures(global = false) {
      this.isSaving = true;
      this.isEditing = true;
      let form = { ...this.editingManufacture };
      form.global = global;
      form.manufacturesType = this.manufacturesType;
      form.start_time = Number(
        moment(this.startTime, "DD-MM-YYYY, HH:mm").unix()
      );
      form.end_time = Number(moment(this.endTime, "DD-MM-YYYY, HH:mm").unix());
      console.log(this.selectedManufacture);
      if (global) {
        form.manufactures = this.selectedManufacture.manufactures;
      }
      form.metadata = {
        RagioneSociale: this.info.RagioneSociale,
        Riferimento: this.info.Riferimento,
        CodiceArticolo: this.info.CodiceArticolo,
        Descrizione: this.info.Descrizione,
      };
      axios
        .patch("handle-productions/" + this.info.CodiceOrdProd, form)
        .then((res) => {
          if (!global) {
            delete form["metadata"];
            let edited = form;
            this.selectedManufacture.manufactures[this.manufacturesType][
              this.editingManufacture._id
            ] = edited;
            this.editingManufacture = null;
            this.$store.dispatch("notificate", {
              text: "Produzione aggiornata correttamente",
              type: "success",
            });
          } else {
            this.$store.dispatch("notificate", {
              text: "Produzione completata correttamente",
              type: "success",
            });
            this.selectedManufacture.state = 3;
            this.closeDialog();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isEditing = false;
          this.isSaving = false;
        });
    },
    editField(item, _id, type) {
      console.log(item);
      console.log(_id);
      this.manufacturesType = type;
      this.editingManufacture = item;
      this.editingManufacture._id = _id;
      this.startTime = moment
        .unix(this.editingManufacture.start_time)
        .format("DD-MM-YYYY, HH:mm");
      this.endTime = moment
        .unix(this.editingManufacture.end_time)
        .format("DD-MM-YYYY, HH:mm");
    },
    openFile(dir) {
      window.open(axios.defaults.baseURL.replace("api/", dir));
    },
    getTitle(item) {
      switch (item) {
        case "board":
          return "Scheda Produzione";
        case "technical":
          return "Allegato Tecnico";
        case "labels":
          return "Etichette";
        case "graphics":
          return "File Grafico";
      }
    },
    resetData() {
      this.selectedManufacture.manufactures = {};
      (this.isStarting = false), (this.selectedManufacture = {});
      this.path = undefined;
      this.selectedManufacture = false;
      this.produced = {};
      this.refused = {};
      this.pallet = [{ nBanc: null, qtyxBanc: null }];
      this.comments = {};
      this.operator = {};
      this.editingManufacture = null;
      this.formData = new FormData();
    },
    checkForm(classname) {
      var forms = document.getElementsByClassName(classname);
      for (var i = 0; i < forms.length; i++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }
      return true;
    },
    backTo() {
      this.isStarting = false;
      this.selectedManufacture = {};
    },
    loadManufacture(manufacture) {
      this.selectedManufacture = manufacture;
      this.isStarting = true;
    },
    handleProduction(action, manufacture) {
      if (
        action == 4
          ? true
          : this.checkForm("form-prod-" + manufacture) && action != 4
      ) {
        let data = { ...this.selectedManufacture };
        data.CodiceOrdProd = this.info.CodiceOrdProd;
        data.metadata = this.info;
        data.action = action;
        data.operator = this.operator[manufacture];
        data.comments = this.comments[manufacture];
        data.manufacturesType = manufacture;
        data.pallet = this.pallet;

        let loadings = [...this.loadings];
        loadings[action - 1] = true;
        this.loadings = loadings;

        if (action == 2) {
          data.produced = this.produced[manufacture];
          data.refused = this.refused[manufacture];
        }
        //data.commenti = this.info.Commenti
        console.log("questo è il form", data);
        axios
          .post("handle-productions", data)
          .then((res) => {
            console.log(res);
            res.data.production.manufactures = this.preserveManfucatureOrder(
              res.data.production.manufactures
            );
            this.selectedManufacture = res.data.production;
            switch (action) {
              case 1:
                this.$store.dispatch("notificate", {
                  text: "Produzione iniziata",
                  type: "success",
                });
                break;
              case 2:
                this.$store.dispatch("notificate", {
                  text: "Produzione interrotta",
                  type: "success",
                });
                break;
              case 3:
                this.$store.dispatch("notificate", {
                  text: "Produzione completata correttamente",
                  type: "success",
                });
                break;
              case 4:
                this.$store.dispatch("notificate", {
                  text: "Produzione sospesa ",
                  type: "success",
                });
                break;
            }

            this.produced = {};
            this.refused = {};
            this.pallet = [{ nBanc: null, qtyxBanc: null }];
            this.comments = {};
          })
          .catch((err) => {
            if (err.response.data.msg)
              this.$store.dispatch("notificate", {
                text: err.response.data.msg,
                type: "warning",
              });
            else
              this.$store.dispatch("notificate", {
                text: "Errore, contattare amministratore",
                type: "error",
              });
          })
          .finally(() => {
            loadings = [...this.loadings];
            loadings[action - 1] = false;
            this.loadings = loadings;
          });
      }
    },
    resetManufacures() {
      this.manufacture = {};
    },
    fetchBoards() {
      axios
        .get("board-files/" + this.info.CodiceOrdProd)
        .then((res) => {
          let files = {};
          let data = res.data.boardFiles;
          if (data) {
            this.lastUpdate = data.lastUpdate;
            data.path.map((item) => {
              console.log(item);
              Object.assign(files, item);
            });
            this.path = files;
            console.log(this.path);
            //this.fixPreview()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchManufactures() {
      if (this.info) {
        console.log("FETCHING")
        this.axios
          .get("stock-production/" + this.info.CodiceOrdProd)
          .then((res) => {
            let operators = {};
            let comments = {};
            Object.keys(res.data.production.manufactures).forEach((key) => {
              operators[key] = [undefined];
              comments[key] = "";
            });
            this.operator = operators;
            this.stockManufactures = res.data.stockManufactures;

            res.data.production.manufactures = this.preserveManfucatureOrder(
              res.data.production.manufactures
            );
            this.selectedManufacture = res.data.production;
            this.manufacturesType = Object.keys(
              this.selectedManufacture.manufactures
            )[0];

            this.fetchBoards();
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    closeDialog() {
      this.$emit("isVisibleChanged", false);
    },
  },
};
</script>
