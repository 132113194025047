import Vue from 'vue'
import VueWorker from 'vue-worker';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store.js'
//import VueSocketIO from 'vue-socket.io'
import VueNativeNotification from 'vue-native-notification'
import './assets/css/main.css';
import moment from 'moment'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import ResponseNotification from './components/ResponseNotification.vue'
import ConfirmDialog from './components/ui/ConfirmDialog.vue'
import VuetifyConfirm from 'vuetify-confirm'
import PrimaryBtn from './components/ui/PrimaryBtn.vue'
import SecondaryBtn from './components/ui/SecondaryBtn.vue'
import InputField from './components/ui/InputField.vue'
import ActionElement from './components/ui/ActionElement.vue'
import ConfirmBtn from './components/ui/ConfirmBtn.vue'
import "./tw.css"
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import { uniLayerGroupMonochrome, uniCarWash, uniPlusSquare, uniBell } from 'vue-unicons/dist/icons'
import vSelect from "vue-select";
Vue.component("custom-select", vSelect);
import "vue-select/dist/vue-select.css";
import env from '../env';
import apiXKey from '../apiXKey';
import socket from './plugins/socket';


Unicon.add([uniLayerGroupMonochrome, uniCarWash, uniPlusSquare, uniBell])
Vue.use(Unicon)

Vue.component('PrimaryBtn', PrimaryBtn);
Vue.component('ConfirmBtn', ConfirmBtn);
Vue.component('SecondaryBtn', SecondaryBtn);
Vue.component('InputField', InputField);
Vue.component('ActionElement', ActionElement);

let socketURL = 'ws://127.0.0.1:65432/'; // default to development URL

if (process.env.NODE_ENV === 'production') {
  socketURL = 'wss://icaproject.icaimballaggi.it/ws/';
  socketURL = 'wss://icaproject.icaimballaggi.it/ws/';
}

Vue.use(socket, { connection: socketURL });
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Conferma',
  buttonFalseText: 'Annulla',
  buttonTrueColor: 'primary',
  buttonFalseColor: 'primary',
  buttonTrueFlat: true,
  buttonFalseFlat: true,
  width: 350,
  property: '$confirm'
})

require('./assets/css/main.css');

export const bus = new Vue();

Vue.use(VueWorker);
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})
/*

Vue.use(new VueSocketIO({
  debug: true,
  connection: 'http://192.168.1.50:5000/',
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
}))
*/

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('ResponseNotification', ResponseNotification);
Vue.component('ConfirmDialog', ConfirmDialog);

Vue.use(require('vue-cookies'))


Vue.config.productionTip = false

// Vue.directive('click-outside', {
//   bind() {
//     this.event = event => this.vm.$emit(this.expression, event)
//     this.el.addEventListener('click', this.stopProp)
//     document.body.addEventListener('click', this.event)
//   },
//   unbind() {
//     this.el.removeEventListener('click', this.stopProp)
//     document.body.removeEventListener('click', this.event)
//   },

//   stopProp(event) { event.stopPropagation() }
// })

axios.defaults.withCredentials = true
axios.defaults.baseURL = env;
axios.defaults.headers.common["X-API-Key"] = apiXKey

Vue.use(VueAxios, axios)


Vue.prototype.bus = new Vue()

Vue.prototype.moment = moment

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

