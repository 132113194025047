<template>
  <v-container
    class="container-v md:tw-p-32 !tw-p-6 tw-flex tw-flex-col tw-mb-40"
    fluid
    v-if="project._id"
  >
    <transferData
      v-on:id_associato="setIdEsterno"
      ref="transfer"
      v-if="project._id"
      :id="project.id"
      :estern="esterno"
      :role="role"
      :icaFiles="project.ica_files"
      :customerFiles="customerFiles"
    ></transferData>
    <div
      v-if="project.status == 'in lavorazione' && user.role != 'commerciale'"
      class="tw-w-full tw-h-screen tw-bg-dark tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-flex-col tw-fixed"
      v-bind:class="overlay"
    >
      <p>{{ project.delegate }} sta lavorando al progetto</p>
      <primary-btn
        class="tw-mt-2"
        text="Torna indietro"
        @click.native="$router.push('/projects-queue')"
      ></primary-btn>

      <work-counter class="tw-mt-4" :time="start_time_tracciato"></work-counter>
    </div>
    <form class="tw-flex tw-flex-wrap tw-mb-12 generic-data">
      <div class="tw-w-full">
        <div
          class="tw-mb-8 tw-flex md:tw-flex-row md:tw-items-center tw-flex-col"
        >
          <div class="tw-flex tw-items-center">
            <v-icon
              @click="$router.push('/projects-queue').catch(() => {})"
              class="!tw-text-dark tw-mr-4 hover:tw-opacity-80 tw-cursor-pointer"
              >mdi-arrow-expand-left</v-icon
            >
            <h2 class="tw-text-3xl tw-font-bold">
              Progetto N° {{ project.id }}
            </h2>
          </div>
          <div
            class="tw-flex md:tw-overflow-x-hidden tw-overflow-x-scroll tw-mt-4 md:tw-mt-0 md:tw-ml-4"
          >
            <div>
              <v-chip
                v-if="
                  project.last_status == 'in attesa' &&
                  project.status != 'in lavorazione'
                "
                dark
                color="orange"
                >In attesa</v-chip
              >
              <v-chip
                v-else-if="
                  project.last_status == 'completato' &&
                  project.status != 'in lavorazione'
                "
                dark
                color="green"
                class="tw-ml-4"
                >Completato</v-chip
              >
            </div>
            <div>
              <v-chip
                dark
                color="blue"
                class="is_editing tw-ml-4"
                v-if="project.is_editing && project.is_editing != user.username"
                >Aperto da {{ project.is_editing }}</v-chip
              >
            </div>
            <div>
              <v-chip
                v-if="project.production_status == 'file da caricare'"
                dark
                color="lightgray"
                class="tw-ml-4"
              >
                Produzione in attesa di file
              </v-chip>
              <v-chip
                v-else-if="project.production_status == 'in attesa'"
                dark
                color="orange"
                class="tw-ml-4"
              >
                Produzione in attesa
              </v-chip>
              <v-chip
                v-else-if="project.production_status == 'da completare'"
                dark
                color="lightgreen"
                class="tw-ml-4"
              >
                Produzione da completare
              </v-chip>
              <v-chip
                v-else-if="project.production_status == 'completato'"
                dark
                color="green"
                class="tw-ml-4"
              >
                Produzioni completate
              </v-chip>
            </div>
            <div>
              <missingInformationButton
                class="tw-ml-4"
                v-on:missing_info_talk="setTalk"
                ref="missing_info"
                v-if="project._id"
                :pid="project.id"
                :text="project.missing_informations.text"
                :role="user.role"
                :delegate="project.delegate"
                :esterno="esterno"
              ></missingInformationButton>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-1 md-tw-justify-end tw-justify-start tw-mr-4 tw-mb-2 tw-opacity-60"
          >
            <div class="tw-underline font2 tw-mt-2" v-if="edited">
              Ci sono dati non salvati
            </div>
          </div>
          <div class="tw-flex md:tw-justify-end tw-items-center">
            <primary-btn
              :loading="isLoading.saving"
              @click.native="updateProject"
              id="action-btn"
              class="tw-self-baseline tw-mr-2"
              text="Salva Progetto"
            ></primary-btn>
            <v-menu
              v-model="actions_dialog"
              offset-y
              content-class="!tw-shadow-xl !tw-rounded-lg !tw-w-auto !tw-min-w-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <secondary-btn
                  :loading="isLoading.actions"
                  v-bind="attrs"
                  v-on="on"
                  @click.native="actions_dialog = true"
                  id="action-btn"
                  class="tw-self-baseline"
                  text="Altre Azioni"
                  icon="mdi-menu-down"
                ></secondary-btn>
              </template>
              <v-list class="font2">
                <action-element
                  v-if="user.role == 'commerciale'"
                  color="green"
                  @click.native="Completed"
                  text="Completa"
                  icon="mdi-check-bold"
                ></action-element>
                <action-element
                  v-if="user.role == 'esterno'"
                  color="purple"
                  @click.native="cloneProject"
                  text="Clona Progetto"
                  icon="mdi-content-copy"
                ></action-element>
                <action-element
                  @click.native="goBack"
                  text="Chiudi Progetto"
                  icon="mdi-close-thick"
                ></action-element>
                <action-element
                  v-if="user.role == 'commerciale'"
                  color="black"
                  @click.native="missingInfo"
                  text="Info Mancanti"
                  icon="mdi-information"
                ></action-element>
                <action-element
                  color="red"
                  @click.native="deleteProject"
                  text="Elimina Progetto"
                  icon="mdi-delete"
                ></action-element>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-w-full md:tw-flex-row tw-flex-wrap">
        <v-col class="input-data tw-p-0 tw-pr-4 !tw-text-sm">
          <div
            v-if="esterno != 'Francia' && esterno != 'susni'"
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Cliente*</label
            >
            <input-field
              class="md:tw-ml-8 tw-w-full"
              type="text"
              v-model="project.customer"
              placeholder="Inserisci il Cliente"
              :disabled="isDisabled"
              required
              @input.native="checkEdit"
            ></input-field>
          </div>
          <div
            v-else
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Cliente*</label
            >
            <custom-select
              @input="checkEdit"
              :clearable="false"
              :options="customers_list"
              :disabled="isDisabled"
              required
              class="select-dis !tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser tw-mr-2"
              v-model="project.customer"
            >
            </custom-select>
            <primary-btn
              icon="mdi-account-plus"
              square="true"
              @click.native="dialog = true"
            ></primary-btn>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Nuovo Cliente</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        @input="checkEdit"
                        label="Nome Cliente*"
                        v-model="new_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        @input="checkEdit"
                        label="Indirizzo"
                        v-model="new_address"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        @input="checkEdit"
                        label="Città"
                        v-model="new_city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        @input="checkEdit"
                        v-model="new_cap"
                        label="CAP"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        @input="checkEdit"
                        v-model="new_province"
                        label="Provincia"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        @input="checkEdit"
                        label="Stato"
                        v-model="new_country"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        @input="checkEdit"
                        label="Email"
                        v-model="new_email"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field
                        @input="checkEdit"
                        label="Telefono"
                        v-model="new_phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        @input="checkEdit"
                        label="Riferimento"
                        v-model="new_ref"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        @input="checkEdit"
                        label="Sito Web"
                        v-model="new_site"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Annulla
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="newCustomer">
                    Salva
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Progetto*</label
            >
            <input-field
              class="md:tw-ml-8 tw-w-full"
              type="text"
              v-model="project.name"
              placeholder="Inserisci il nome del progetto"
              :disabled="isDisabled"
              required
              @input.native="checkEdit"
            ></input-field>
          </div>
        </v-col>
        <v-col class="input-data tw-p-0">
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Corrispondente*</label
            >
            <input-field
              class="md:tw-ml-8 tw-w-full"
              type="text"
              v-model="project.referrer"
              placeholder="Inserisci il nome del corrispondente"
              required
              @input.native="checkEdit"
              disabled
            ></input-field>
          </div>
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >CC</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-w-full"
            >
              <custom-select
                placeholder="Seleziona commerciale in CC"
                @input="checkEdit"
                :clearable="false"
                :options="secondary_referrers"
                :disabled="isDisabled"
                required
                class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                v-model="project.secondary_referrer"
              >
              </custom-select>
            </div>
          </div>
        </v-col>
        <v-col class="input-data tw-p-0 md:tw-pl-4">
          <div
            class="generic-info md:tw-flex-row tw-flex-col tw-flex md:tw-items-center"
          >
            <label
              class="tw-mb-2 md:tw-mb-0 tw-font-semibold !tw-text-sm md:!tw-text-lg"
              >Operatore</label
            >
            <div
              class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 tw-w-full"
            >
              <custom-select
                :clearable="false"
                :options="delegates"
                :disabled="isDisabled"
                required
                class="!tw-bg-white tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                v-model="project.delegate"
                @input="delegateProject"
              >
              </custom-select>
            </div>
          </div>
          <div></div>
        </v-col>
      </div>
    </form>
    <v-row class="tw-h-3/4 tw-flex-1 tw-box-border tw-rounded-lg tw-p-0">
      <v-col class="data-info tw-flex md:tw-flex-col tw-flex-col tw-p-0">
        <v-tabs
          show-arrows
          v-model="tabs"
          fixed-tabs
          background-color="#2F2F2F"
          height="100%"
          dark
          class="tw-rounded-t-xl !tw-text-white !tw-h-24"
          active-class="active-tab"
        >
          <v-tabs-slider color="#11797b"></v-tabs-slider>
          <v-tab
            href="#mobile-tabs-5-1"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg !tw-ml-4 tw-text-white"
          >
            Progettazione
          </v-tab>

          <v-tab
            href="#mobile-tabs-5-2"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Allegati
            <CounterBadge :num="icaFiles.length + customerFiles.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-12"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Archiviati ICA
            <CounterBadge :num="archivedICAFiles.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-13"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Archiviati (altro)
            <CounterBadge :num="archivedCustomerFiles.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-3"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg tw-text-white"
          >
            Preventivi
            <CounterBadge :num="project.preventivi_scatola.length" />
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-4"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg !tw-mr-4 tw-text-white"
          >
            Tempi
          </v-tab>
          <v-tab
            href="#mobile-tabs-5-5"
            class="!tw-h-16 hover:!tw-bg-azure hover:!tw-text-dark tw-my-4 tw-mx-2 tw-box-border tw-rounded-lg !tw-mr-4 tw-text-white"
          >
            BCT/ECT
          </v-tab>
        </v-tabs>

        <v-tabs-items
          class="md:!tw-h-full !tw-h-[fit-content] md:tw-pb-0 tw-pb-32"
          v-model="tabs"
        >
          <v-tab-item :value="'mobile-tabs-5-1'">
            <v-card
              flat
              class="tw-min-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <v-row class="time-content">
                <v-col cols="12" class="tw-p-0 tw-mb-8 tw-flex tw-items-center">
                  <div class="tw-font-semibold tw-text-2xl">
                    Progettazione Tecnica
                  </div>
                  <secondary-btn
                    class="tw-ml-2"
                    text="A ICA"
                    v-if="user.role == 'commerciale'"
                    @click.native="transferData('progettazione_tecnica')"
                  ></secondary-btn>
                </v-col>
                <form
                  class="tw-w-full tw-flex md:tw-flex-wrap md:tw-flex-row tw-flex-col generic-data font2"
                >
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center"
                  >
                    <label class="tw-flex-1 md:tw-mb-0 tw-mb-2"
                      >Tipo Imballo</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-96 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.imballo"
                      placeholder="Inserisci il tipo di imballo"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-12 md:tw-mt-0 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">FEFCO</label>
                    <input-field
                      darker="true"
                      class="md:!tw-w-96 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.fefco"
                      placeholder="FEFCO"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-12 md:tw-mt-0 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">Onda</label>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 md:tw-flex-none tw-flex-1"
                      type="text"
                      v-model="project.progettazione_tecnica.comp"
                      placeholder="Onda"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    v-if="!est_or_int"
                    class="tw-flex md:tw-items-center md:tw-flex-wrap md:tw-flex-row tw-flex-col md:tw-w-1/2 tw-w-full md:tw-mt-12 tw-mt-4"
                  >
                    <label class="md:tw-mb-0 tw-mb-2">Dimensioni</label>
                    <div
                      class="!tw-relative tw-flex !tw-min-h-[3rem] md:tw-ml-8 md:tw-mr-4 md:!tw-w-80 md:tw-flex-none tw-flex-1"
                    >
                      <custom-select
                        placeholder="Seleziona le dimensioni"
                        :clearable="false"
                        :options="['Esterne', 'Interne']"
                        :disabled="isDisabled"
                        class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                        v-model="est_or_int"
                      >
                      </custom-select>
                    </div>
                  </div>
                  <span
                    v-if="est_or_int == 'Esterne'"
                    class="tw-flex md:tw-items-center tw-flex-wrap md:tw-w-1/2 tw-w-full tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Dim. Esterne
                    </label>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.l"
                      placeholder="L"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.p"
                      placeholder="W"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_est.h"
                      placeholder="H"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <span>
                      <v-icon
                        v-if="are_dimensions"
                        @click="est_or_int = null"
                        style="cursor: pointer; margin-left: 15px"
                        >mdi-delete</v-icon
                      >
                    </span>
                  </span>
                  <span
                    v-else-if="est_or_int == 'Interne'"
                    class="tw-flex md:tw-items-center tw-flex-wrap md:tw-w-1/2 tw-w-full tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Dim. Interne</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.l"
                      placeholder="L"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.p"
                      placeholder="W"
                      :disabled="isDisabled"
                      required
                      @input.native="checkEdit"
                    ></input-field>
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:!tw-w-24 tw-w-1/3 tw-px-1"
                      type="number"
                      v-model="project.progettazione_tecnica.dim_int.h"
                      placeholder="H"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                    <span>
                      <v-icon
                        v-if="are_dimensions"
                        @click="est_or_int = null"
                        style="cursor: pointer; margin-left: 15px"
                        >mdi-delete</v-icon
                      >
                    </span>
                  </span>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-ml-1 md:tw-mt- tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Tipologia di stampa</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 md:tw-mr-6 md:!tw-w-96 tw-w-full"
                      type="text"
                      v-model="project.progettazione_grafica.stampa"
                      placeholder="Inserisci il tipo di stampa"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                  <div
                    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center tw-mt-4"
                  >
                    <label class="md:tw-w-auto tw-w-full md:tw-mb-0 tw-mb-2"
                      >Colori</label
                    >
                    <input-field
                      darker="true"
                      class="md:tw-ml-4 !tw-w-24"
                      type="number"
                      v-model="project.progettazione_grafica.colori"
                      placeholder="0"
                      :disabled="isDisabled"
                      @input.native="checkEdit"
                    ></input-field>
                  </div>
                </form>
                <div class="tw-w-full tw-mt-12 tw-h-96">
                  <NotesComponent
                    :_id="project._id"
                    :esterno="esterno"
                    />
                </div>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-2'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <v-dialog
                v-model="fileDialog"
                width="600"
                background-color="white"
              >
                <v-row class="wh">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <div
                        v-if="form_file == 'ica-file'"
                        class="tw-font-semibold tw-text-2xl tw-my-4"
                      >
                        Allegati ICA
                      </div>
                      <div v-else class="tw-text-xl tw-font-bold">
                        Allegati Cliente
                      </div>
                      <div class="tw-flex tw-flex-col">
                        <div
                          v-for="(file, index) in current_uploading"
                          :key="index"
                          class="tw-w-full tw-flex tw-flex-col tw-bg-grey tw-mb-2 tw-rounded-xl tw-p-4"
                        >
                          <div class="tw-text-md tw-font-medium">
                            {{ file.name }}
                          </div>
                          <div class="tw-text-sm">{{ file.size }}MB</div>
                          <v-progress-linear
                            v-if="uploading != 100"
                            color="#11797B"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                          <v-progress-linear
                            v-else
                            color="green"
                            class="tw-mt-2"
                            rounded
                            :value="uploading"
                          ></v-progress-linear>
                        </div>
                      </div>
                      <v-col
                        sm="12"
                        class="input-ica tw-h-72 tw-mt-8 !tw-rounded-xl"
                      >
                        <div class="upload-icon">
                          <v-icon color="primary" dark>mdi-upload </v-icon>
                          <p style="font-size: 13px">
                            Clicca o trascina i file qui per caricarli
                          </p>
                        </div>
                        <input
                          @input="uploadFiles"
                          v-if="form_file == 'ica-file'"
                          ref="ica_files"
                          id="upload-false_ica-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                        <input
                          @input="uploadFiles"
                          v-else
                          ref="customer_files"
                          id="upload-false_ica-file"
                          :name="form_file"
                          type="file"
                          size="1"
                          multiple
                        />
                      </v-col>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="closeFileDialog">
                          Chiudi
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-dialog>
              <div
                class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
              >
                <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati ICA ({{ project.ica_files.filter(f => !f.archived).length }})
                    </div>
                    <primary-btn
                      v-if="user.role == 'commerciale'"
                      @click.native="openUploadFileDialog('ica-file')"
                      class="tw-ml-4"
                      text="Carica file"
                      icon="mdi-upload"
                    ></primary-btn>
                  </div>
                  <div class="file-list-wrapper tw-flex-1 tw-h-full">
                    <div
                      class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                    >
                      <div
                        v-for="(fname, index) in project.ica_files.filter(f => !f.archived)"
                        :key="index"
                        class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                      >
                        <div class="">
                          <img
                            class="tw-16 tw-h-16"
                            :src="
                              path +
                              '/static/img/file_ico/' +
                              getFileIco(fname.name) +
                              '.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="tw-flex-1 tw-flex-col font2">
                          <p class="tw-font-bold tw-text-sm md:tw-text-md">
                            {{ fname.name.replace(/_/g, " ") }}
                          </p>
                        </div>
                        <div class="tw-flex tw-bg-red">
                          <v-icon
                            @click="shareFiles(fname.name, 'ica')"
                            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                            >mdi-eye</v-icon
                          >
                          <v-icon
                            @click="downloadFile(fname, 'ica')"
                            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                            >mdi-download</v-icon
                          >
                            <v-icon
                              @click="archiveFile('ica_files',fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-archive</v-icon
                            >
                          <v-dialog
                            v-model="filePreviewDialog"
                            background-color="white"
                            fullscreen
                            :retain-focus="false"
                            transition="slide-y-transition"
                          >
                            <v-card elevation="0" class="tw-w-full tw-h-full">
                              <div
                                class="tw-w-full tw-h-full tw-flex tw-flex-col"
                              >
                                <v-toolbar
                                  color="#DDEBEB"
                                  elevation="0"
                                  max-height="64px"
                                >
                                  <v-toolbar-title></v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-toolbar-items>
                                    <v-btn icon @click="closePreviewDialog">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </v-toolbar-items>
                                </v-toolbar>

                                <div
                                  class="tw-flex-1 tw-flex tw-w-full wt-h-full tw-items-center tw-justify-center"
                                >
                                  <img
                                    class="tw-object-contain"
                                    :src="preview"
                                    @error="handleNoPreview"
                                  />
                                  <div
                                    v-if="no_preview"
                                    class="tw-flex-col tw-text-center font2"
                                  >
                                    <div class="tw-text-xl">
                                      Preview attualmente non disponibile.
                                    </div>
                                    <div class="tw-text-xl tw-mb-4">
                                      Vuoi scaricare il file?
                                    </div>
                                    <primary-btn
                                      @click.native="downloadRequestedFile"
                                      class="tw-ml-4"
                                      text="Scarica file"
                                      icon="mdi-upload"
                                    ></primary-btn>
                                  </div>
                                </div>
                              </div>
                            </v-card>
                          </v-dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col md:tw-pl-4"
                >
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati Cliente ({{ customerFiles.length }})
                    </div>
                    <primary-btn
                      @click.native="openUploadFileDialog('customer-file')"
                      class="tw-ml-4"
                      text="Carica file"
                      icon="mdi-upload"
                    ></primary-btn>
                  </div>
                  <div class="file-list-wrapper tw-flex-1 tw-h-full">
                    <div
                      class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                    >
                      <div
                        v-for="(fname, index) in customerFiles"
                        :key="index"
                        class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                      >
                        <div class="">
                          <img
                            class="tw-16 tw-h-16"
                            :src="
                              path +
                              '/static/img/file_ico/' +
                              getFileIco(fname.name) +
                              '.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="tw-flex-1 tw-flex-col font2">
                          <p class="tw-font-bold tw-text-sm md:tw-text-md">
                            {{ fname.name.replace(/_/g, " ") }}
                          </p>
                        </div>
                        <div class="tw-flex tw-bg-red">
                          <v-icon
                            v-if="user.role == 'commerciale'"
                            @click="transferData('customer_file', fname.name)"
                            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                            >mdi-send</v-icon
                          >
                          <v-icon
                            @click="shareFiles(fname.name, 'customer')"
                            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                            >mdi-eye</v-icon
                          >
                          <v-icon
                            @click="downloadFile(fname, 'customer')"
                            class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                            >mdi-download</v-icon
                          >
                            <v-icon
                              @click="archiveFile('customer_files',fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-archive</v-icon
                            >
                          <v-dialog
                            v-model="filePreviewDialog"
                            background-color="white"
                            fullscreen
                            :retain-focus="false"
                            transition="slide-y-transition"
                          >
                            <v-card elevation="0" class="tw-w-full tw-h-full">
                              <div
                                class="tw-w-full tw-h-full tw-flex tw-flex-col"
                              >
                                <v-toolbar
                                  color="#DDEBEB"
                                  elevation="0"
                                  max-height="64px"
                                >
                                  <v-toolbar-title></v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-toolbar-items>
                                    <v-btn icon @click="closePreviewDialog">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </v-toolbar-items>
                                </v-toolbar>

                                <div
                                  class="tw-flex-1 tw-flex tw-w-full wt-h-full tw-items-center tw-justify-center"
                                >
                                  <img
                                    class="tw-object-contain"
                                    :src="preview"
                                    @error="handleNoPreview"
                                  />
                                  <div
                                    v-if="no_preview"
                                    class="tw-flex-col tw-text-center font2"
                                  >
                                    <div class="tw-text-xl">
                                      Preview attualmente non disponibile.
                                    </div>
                                    <div class="tw-text-xl tw-mb-4">
                                      Vuoi scaricare il file?
                                    </div>
                                    <primary-btn
                                      @click.native="downloadRequestedFile"
                                      class="tw-ml-4"
                                      text="Scarica file"
                                      icon="mdi-upload"
                                    ></primary-btn>
                                  </div>
                                </div>
                              </div>
                            </v-card>
                          </v-dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-12'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <div class="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati Archiviati ({{ archivedICAFiles.length }})
                    </div>

                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Allegati ICA</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in archivedICAFiles"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('ica_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'ica')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'ica')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-13'">
            <v-card
              flat
              class="tw-h-full md:tw-overflow-y-hidden tw-overflow-y-auto !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border md:tw-p-6 tw-p-4"
            >
              <div class="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div class="tw-w-full tw-flex tw-flex-col">
                  <div class="tw-mb-8 tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Allegati Archiviati ({{ archivedCustomerFiles.length }})
                    </div>

                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-h-full"
                >
                  <div class="md:tw-w-1/2 tw-w-full tw-flex tw-flex-col">
                    <div class="tw-mb-8 tw-flex tw-items-center">
                      <div class="tw-text-xl">Allegati Cliente</div>
                    </div>
                    <div class="file-list-wrapper tw-flex-1 tw-h-full">
                      <div
                        class="file-list tw-w-full tw-min-h-1/2 tw-max-h-[87%] tw-overflow-y-scroll md:tw-pr-4 tw-box-border"
                      >
                        <div
                          v-for="(fname, index) in archivedCustomerFiles"
                          :key="index"
                          class="file-obj tw-full tw-flex tw-bg-grey tw-box-border tw-p-4 tw-rounded-xl tw-items-center tw-mb-4 tw-relative"
                        >
                          <div class="">
                            <img
                              class="tw-16 tw-h-16"
                              :src="
                                path +
                                '/static/img/file_ico/' +
                                getFileIco(fname.name) +
                                '.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="tw-flex-1 tw-flex-col font2">
                            <p class="tw-font-bold tw-text-sm md:tw-text-md">
                              {{ fname.name.replace(/_/g, " ") }}
                            </p>
                            <p class="tw-text-xs">
                              {{ (fname.size / (1024 * 1024)).toFixed(2) }} MB
                            </p>
                          </div>
                          <div class="tw-flex tw-bg-red">
                            <v-icon
                              @click="recoverFile('customer_files', fname)"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-restore</v-icon
                            >
                            <v-icon
                              @click="downloadFile(fname, 'customer')"
                              class="tw-mr-2 tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
                              >mdi-download</v-icon
                            >
                            <v-icon
                              @click="deleteFile(fname, 'customer')"
                              class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-red"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-3'">
            <v-card
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-4 md:tw-p-6"
              flat
            >
              <v-row
                class="tw-m-0 tw-p-0 tw-flex !tw-flex-col !tw-flex-nowrap tw-h-full"
              >
                <div class="tw-flex">
                  <div class="tw-font-semibold tw-text-2xl">
                    Preventivi
                    <span
                      v-if="
                        project.preventivi_scatola &&
                        project.preventivi_scatola.length > 0
                      "
                      >({{ project.preventivi_scatola.length }})</span
                    >
                  </div>
                  <primary-btn
                    @click.native="openPrevDialog('preventivi_scatola')"
                    class="tw-ml-4"
                    text="Nuovo"
                    icon="mdi-plus"
                  ></primary-btn>
                </div>
                <v-col cols="12" class="tw-h-full">
                  <div
                    v-if="
                      project.preventivi_scatola &&
                      project.preventivi_scatola.length == 0
                    "
                  >
                    <p>Non ci sono preventivi...</p>
                  </div>
                  <form
                    enctype="multipart/form-data"
                    id="preventivo-scatola"
                    class="tw-flex-1 tw-overflow-hidden !tw-h-full"
                  >
                    <div
                      v-if="
                        project.preventivi_scatola &&
                        project.preventivi_scatola.length > 0
                      "
                      class="tw-flex tw-flex-col tw-max-h-full tw-overflow-y-scroll md:tw-pr-8"
                    >
                      <div
                        v-for="(quotation, index) in project.preventivi_scatola"
                        :key="index"
                        class="tw-flex tw-box-border tw-rounded-xl tw-bg-grey tw-w-full tw-p-4 md:tw-p-8 tw-mb-4"
                      >
                        <!-- 
                              v-if="project.preventivi_scatola &&"
                              class="tw-p-6"
                              >

                              <v-col cols="4" class="tw-p-0">
                                <span  ><b>Compo: </b>{{ quotation.compo }}</span>
                              </v-col>         

                              <v-col cols="3" class="tw-p-0">
                                <span ><b>Quantità: </b>{{ quotation.qty }}</span>
                              </v-col>                                 
                              
                              <v-col cols="3" class="tw-p-0">
                                <span ><b>Tipo di Stampa: </b>{{ quotation.stampa }}</span>
                              </v-col>  
                              
                              <v-col cols="2" class="tw-p-0">
                                <span ><b>N° Colori </b>{{ quotation.n_colori }}</span>
                              </v-col>  
                              
                              <v-col cols="4" class="tw-p-0 tw-my-2">
                                <span v-if="quotation.price.p" ><b>Prezzo: </b>€{{ quotation.price.p }}</span>
                                <span v-else ><b>Prezzo </b>In attesa</span>
                              </v-col>       

                              <v-col cols="3" class="tw-p-0 tw-my-2">
                                <span v-if="quotation.price.f" ><b>Fustella: </b>€{{ quotation.price.f }}</span>
                                <span v-else ><b>Fustella </b>In attesa</span>
                              </v-col>     

                              <v-col cols="3" class="tw-p-0 tw-my-2">
                                <span v-if="quotation.price.s" ><b>Impianto Stampa: </b>€{{ quotation.price.s }}</span>
                                <span v-else ><b>Impianto Stampa </b>In attesa</span>
                              </v-col>                        

                              <v-col cols="12" class="tw-p-0">
                                <span v-if="quotation.notes" ><b>Note: <br> </b>{{ quotation.notes }}</span>
                              </v-col>
                              
                              <v-col
                              class="nopd center_y mg-10"
                              sm="12">
                                <v-col 
                                class="nopd center" >
                                  <v-icon
                                  size="30"
                                  class="action-file !tw-text-custom-blue hover:tw-opacity-80"
                                  @click="quotation.is_editing = true"
                                  
                                  >
                                    mdi-pencil-circle
                                  </v-icon>
                                  <v-icon
                                  size="30"
                                  class="action-file !tw-text-custom-red hover:tw-opacity-80"
                                  @click="deleteQuotation(index,'preventivi_scatola')"

                                  >
                                    mdi-delete-circle
                                  </v-icon>
                                </v-col>
                              </v-col>       

                            </v-row>                              -->
                        <v-row
                          v-if="!quotation.is_editing"
                          class="tw-p-4 md:tw-p-6"
                        >
                          <v-col class="!tw-p-0 tw-items-center" sm="12">
                            <div
                              class="tw-w-full"
                              v-if="
                                quotation.quotations &&
                                quotation.quotations.length > 0
                              "
                            >
                              <div
                                class="tw-flex tw-justify-center tw-p-4 tw-bg-white tw-mb-4 tw-rounded-xl tw-flex-wrap"
                                v-for="(
                                  sub_quotation, index2
                                ) in quotation.quotations"
                                :key="index2"
                              >
                                <span class="tw-w-full md:tw-w-2/12"
                                  ><b>Composizione <br /> </b>
                                  {{ sub_quotation.compo }}</span
                                >
                                <span class="tw-w-full md:tw-w-2/12"
                                  ><b>Quantità <br /> </b>
                                  {{ sub_quotation.qty }}</span
                                >
                                <span class="tw-w-full md:tw-w-1/12"
                                  ><b>Stampa <br /> </b>
                                  {{ sub_quotation.stampa }}</span
                                >
                                <span class="tw-w-full md:tw-w-1/12"
                                  ><b>N° Colori<br /> </b>
                                  {{ sub_quotation.n_colori }}</span
                                >
                                <div class="tw-w-full md:tw-w-2/12">
                                  <span v-if="sub_quotation.unitario != 0"
                                    ><b>Prezzo<br /> </b>€
                                    {{ sub_quotation.unitario }}</span
                                  >
                                  <span v-else
                                    ><b>Prezzo<br /> </b>In attesa</span
                                  >
                                </div>
                                <div class="tw-w-full md:tw-w-2/12">
                                  <span v-if="sub_quotation.fustella.price != 0"
                                    ><b>Fustella <br /> </b>€
                                    {{ sub_quotation.fustella.price }}</span
                                  >
                                </div>
                                <div class="tw-w-full md:tw-w-2/12">
                                  <span
                                    v-if="
                                      sub_quotation.impianto_stampa.price != 0
                                    "
                                    ><b>Imp. Stampa <br /> </b>€
                                    {{
                                      sub_quotation.impianto_stampa.price
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col cols="12" class="tw-p-0">
                            <span v-if="quotation.notes"
                              ><b>Note: <br /> </b>{{ quotation.notes }}</span
                            >
                          </v-col>

                          <v-col class="nopd center_y mg-10" sm="12">
                            <v-col class="nopd center">
                              <v-icon
                                size="30"
                                class="action-file !tw-text-custom-blue hover:tw-opacity-80"
                                @click="quotation.is_editing = true"
                                v-if="user.role == 'commerciale'"
                              >
                                mdi-pencil-circle
                              </v-icon>
                              <v-icon
                                size="30"
                                class="action-file !tw-text-custom-red hover:tw-opacity-80"
                                @click="
                                  deleteQuotation(index, 'preventivi_scatola')
                                "
                              >
                                mdi-delete-circle
                              </v-icon>
                            </v-col>
                          </v-col>
                        </v-row>

                        <!-- PREVENTIVO SE L'UTENTE STA EDITANDO -->
                        <!-- <v-row
                              class="tw-p-4"
                              v-else
                              >
                              <v-col cols="4" class="!tw-p-2 tw-flex tw-flex-col">
                                <div class="tw-mb-2">Compo</div>
                                <input-field    
                                  class="tw-w-full"    
                                  type="text"
                                  v-model="quotation.compo"
                                  placeholder="Compo"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>         

                              <v-col cols="3" class="!tw-p-2 tw-flex tw-flex-col">
                                <div class="tw-mb-2">Quantità</div>
                                <input-field    
                                  class="tw-w-full"    
                                  type="number"
                                  v-model="quotation.qty"
                                  placeholder="Quantità"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>                                 
                              
                              <v-col cols="3" class="!tw-p-2 tw-flex tw-flex-col">
                                <div class="tw-mb-2">Tipo di Stampa</div>
                                <custom-select  
                                placeholder="Seleziona il tipo di stampa"
                                :clearable="true" 
                                label="name"
                                :options="['Flex','Offset','Digitale']" 
                                :disabled="isDisabled" 
                                required
                                class="!tw-bg-white  tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer  tw-w-full !tw-border-0 select-style-chooser" 
                                v-model="quotation.stampa" 
                                  >
                                </custom-select> 
                              </v-col>  
                              
                              <v-col cols="2" class="!tw-p-2 tw-flex tw-flex-col">
                                <div class="tw-mb-2">N° Colori</div>
                                <input-field    
                                  class="tw-w-full"    
                                  type="number"
                                  v-model="quotation.n_colori"
                                  placeholder="N° Colori"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>  
                              
                              <v-col cols="4" class="!tw-p-2 tw-my-2  tw-flex tw-flex-col">
                                <div class="tw-mb-2">Prezzo</div>
                                <input-field    
                                  class="tw-w-full"    
                                  type="number"
                                  v-model="quotation.price.p"
                                  placeholder="Prezzo"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>       

                              <v-col cols="4" class="!tw-p-2 tw-my-2  tw-flex tw-flex-col">
                                <div class="tw-mb-2">Fustella</div>
                                <input-field    
                                  class="tw-w-full2"    
                                  type="number"
                                  v-model="quotation.price.f"
                                  placeholder="Fustella"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>     

                              <v-col cols="4" class="!tw-p-2 tw-my-2  tw-flex tw-flex-col">
                                <div class="tw-mb-2">Impianto Stampa</div>
                                <input-field    
                                  class="tw-w-full"    
                                  type="number"
                                  v-model="quotation.price.s"
                                  placeholder="Impianto Stampa"
                                  :disabled="isDisabled"
                                  required
                                ></input-field>
                              </v-col>  

                              <v-col cols="12" class="!tw-p-2 tw-flex tw-flex-col">
                                <div class="tw-mb-2">Note</div>
                                  <textarea class="tw-bg-white tw-rounded-xl tw-w-full tw-h-64 tw-box-border tw-p-4" v-model="quotation.notes" placeholder="Inserisci le note..."></textarea>
                              </v-col>
                              <v-col
                              class="nopd center_y mg-10"
                              sm="12">
                                <v-col 
                                class="nopd center" >
                                  <v-icon
                                  size="30"
                                  class="action-file !tw-text-green hover:tw-opacity-80"
                                  @click="updateQuotation(index, 'preventivi_scatola')"
                                  
                                  >
                                    mdi-check-circle
                                  </v-icon>
                                  <v-icon
                                  size="30"
                                  class="action-file hover:tw-opacity-80"
                                  @click="quotation.is_editing = false"

                                  >
                                    mdi-close-circle
                                  </v-icon>
                                </v-col>
                              </v-col>       
                              <v-col v-if="quotation.creation" class="tw-p-0 tw-flex tw-justify-end" cols="12">
                                <p style="font-size: 12px"><b>Data di creazione: </b>{{quotation.creation}}</p>
                              </v-col>                                                
                              <v-col
                              class="nopd center_y"
                              sm="6">
                                <v-col 
                                class="nopd center" >
                                </v-col>
                              </v-col>
                            </v-row>        
                                                   -->
                        <v-row v-else class="!tw-p-0 tw-flex tw-flex-col">
                          <v-row
                            class="tw-p-4 tw-items-center"
                            v-for="(
                              sub_quotation, index2
                            ) in quotation.quotations"
                            :key="index2"
                          >
                            <v-col cols="2" class="!tw-p-2 tw-flex tw-flex-col">
                              <div class="tw-mb-2 tw-font-bold">
                                Composizione
                              </div>
                              <input-field
                                class="tw-w-full"
                                type="text"
                                v-model="sub_quotation.compo"
                                placeholder="Compo"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                            </v-col>

                            <v-col cols="1" class="!tw-p-2 tw-flex tw-flex-col">
                              <div class="tw-mb-2 tw-font-bold">Quantità</div>
                              <input-field
                                class="tw-w-full"
                                type="number"
                                v-model="sub_quotation.qty"
                                placeholder="Quantità"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                            </v-col>

                            <v-col cols="2" class="!tw-p-2 tw-flex tw-flex-col">
                              <div class="tw-mb-2 tw-font-bold">Stampa</div>
                              <custom-select
                                placeholder="Seleziona il tipo di stampa"
                                :clearable="true"
                                label="name"
                                :options="['Flex', 'Offset', 'Digitale']"
                                :disabled="isDisabled"
                                required
                                class="!tw-bg-white tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                                v-model="sub_quotation.stampa"
                              >
                              </custom-select>
                            </v-col>

                            <v-col cols="1" class="!tw-p-2 tw-flex tw-flex-col">
                              <div class="tw-mb-2 tw-font-bold">N° Colori</div>
                              <input-field
                                class="tw-w-full"
                                type="number"
                                v-model="sub_quotation.n_colori"
                                placeholder="N° Colori"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                            </v-col>

                            <v-col
                              cols="1"
                              class="!tw-p-2 tw-my-2 tw-flex tw-flex-col"
                            >
                              <div class="tw-mb-2 tw-font-bold">Prezzo</div>
                              <input-field
                                class="tw-w-full"
                                type="number"
                                v-model="sub_quotation.unitario"
                                placeholder="Prezzo"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                            </v-col>

                            <v-col
                              cols="2"
                              class="!tw-p-2 tw-my-2 tw-flex tw-flex-col"
                            >
                              <div class="tw-mb-2 tw-font-bold">Fustella</div>

                              <input-field
                                v-if="sub_quotation.fustella.isEditing"
                                class="tw-w-full2"
                                type="number"
                                v-model="sub_quotation.fustella.price"
                                placeholder="Fustella"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                              <input-field
                                v-else-if="
                                  sub_quotation.fustella.price > 0 &&
                                  !sub_quotation.fustella.isEditing
                                "
                                class="tw-w-full2"
                                type="number"
                                v-model="sub_quotation.fustella.price"
                                placeholder="Fustella"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                              <v-chip
                                v-else
                                @click="editQuot(index, index2, 'fustella')"
                                dark
                                class="!tw-bg-custom-blue tw-cursor-pointer"
                                style="width: fit-content"
                                >+ Fustella</v-chip
                              >
                            </v-col>

                            <v-col
                              cols="2"
                              class="!tw-p-2 tw-my-2 tw-flex tw-flex-col"
                            >
                              <div class="tw-mb-2 tw-font-bold">
                                Impianto Stampa
                              </div>
                              <input-field
                                v-if="sub_quotation.impianto_stampa.isEditing"
                                class="tw-w-full"
                                type="number"
                                v-model="sub_quotation.impianto_stampa.price"
                                placeholder="Impianto Stampa"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                              <input-field
                                v-else-if="
                                  sub_quotation.impianto_stampa.price > 0 &&
                                  !sub_quotation.impianto_stampa.isEditing
                                "
                                class="tw-w-full"
                                type="number"
                                v-model="sub_quotation.impianto_stampa.price"
                                placeholder="Impianto Stampa"
                                :disabled="isDisabled"
                                required
                              ></input-field>
                              <v-chip
                                v-else
                                @click="
                                  editQuot(index, index2, 'impianto_stampa')
                                "
                                dark
                                class="!tw-bg-custom-blue tw-cursor-pointer"
                                style="width: fit-content"
                                >+ Imp. Stampa</v-chip
                              >
                            </v-col>
                            <v-col
                              cols="1"
                              class="tw-flex tw-justify-center tw-items-center"
                            >
                              <v-btn icon>
                                <v-icon
                                  color="red"
                                  size="30"
                                  class="action-file hover:tw-opacity-80"
                                  @click="removeSubEdit(index, index2)"
                                >
                                  mdi-delete-circle
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row class="!tw-p-0">
                            <v-col cols="12" class="tw-flex tw-justify-center">
                              <v-btn icon>
                                <v-icon
                                  size="30"
                                  class="action-file hover:tw-opacity-80"
                                  @click="addSubEdit(index)"
                                >
                                  mdi-plus-circle
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row class="tw-p-4">
                            <v-col
                              cols="12"
                              class="!tw-p-2 tw-flex tw-flex-col"
                            >
                              <div class="tw-mb-2 tw-font-bold">Note</div>
                              <textarea
                                class="tw-bg-white tw-rounded-xl tw-w-full tw-h-32 tw-box-border tw-p-4"
                                v-model="quotation.notes"
                                placeholder="Inserisci le note..."
                              ></textarea>
                            </v-col>
                            <v-col class="nopd center_y mg-10" sm="12">
                              <v-col class="nopd center">
                                <v-btn icon :loading="isLoading.quotation">
                                  <v-icon
                                    size="30"
                                    class="action-file !tw-text-green hover:tw-opacity-80"
                                    v-if="user.role == 'commerciale'"
                                    @click="
                                      updateQuotation(
                                        index,
                                        'preventivi_scatola'
                                      )
                                    "
                                  >
                                    mdi-check-circle
                                  </v-icon>
                                </v-btn>
                                <v-btn icon>
                                  <v-icon
                                    size="30"
                                    class="action-file hover:tw-opacity-80"
                                    @click="quotation.is_editing = false"
                                  >
                                    mdi-close-circle
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-col>
                            <v-col
                              v-if="quotation.creation_time"
                              class="tw-p-2 tw-flex tw-justify-end"
                              cols="12"
                            >
                              <p style="font-size: 12px">
                                <b>Data di creazione: </b
                                >{{ quotation.creation_time }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-row>
                      </div>
                    </div>
                  </form>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-4'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-6"
            >
              <v-row class="time-content" v-if="role == 'master'">
                <v-row>
                  <v-col cols="12">
                    <v-card-title class="time-title"
                      >Registra tempo di lavorazione</v-card-title
                    >
                  </v-col>
                  <v-col v-if="!start_time_tracciato" sm="6">
                    <button
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="start"
                      @click="Task('start')"
                    >
                      Inizia</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>
                  <v-col
                    v-else-if="start_time_tracciato && end_time_tracciato"
                    sm="6"
                  >
                    <button
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="start"
                      @click="Task('start')"
                    >
                      Riprendi</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>
                  <v-col v-else sm="6">
                    <button
                      disabled
                      class="v-btn theme--light elevation-2 v-size--default"
                      style="cursor: not-allowed; opacity: 0.7"
                      data-field="tracciato"
                      data-id="start"
                    >
                      Riprendi</button
                    ><span>{{ start_time_tracciato }}</span>
                  </v-col>

                  <v-col v-if="!end_time_tracciato" sm="6">
                    <button
                      ref="stop"
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      data-id="end"
                      @click="Task('end')"
                    >
                      Stop</button
                    ><span>{{ end_time_tracciato }}</span>
                  </v-col>
                  <v-col v-else sm="6">
                    <button
                      disabled
                      class="v-btn theme--light elevation-2 v-size--default"
                      data-field="tracciato"
                      style="cursor: not-allowed; opacity: 0.7"
                      data-id="end"
                    >
                      Stop</button
                    ><span>{{ end_time_tracciato }}</span>
                  </v-col>
                </v-row>
              </v-row>
              <v-divider></v-divider>

              <v-row
                class="time-content tw-flex-1 tw-overflow-y-scroll tw-h-3/4"
              >
                <v-row class="wh" width="100%">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Tracciato
                      </v-card-title>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Inizio</th>
                              <th class="text-left">Fine</th>
                              <th class="text-left">Tempo di lavoro</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in project.tracciato"
                              :key="index"
                            >
                              <td>
                                <TimeRow
                                  v-bind:time="item.start_time"
                                ></TimeRow>
                              </td>
                              <td>
                                <TimeRow v-bind:time="item.end_time"></TimeRow>
                              </td>
                              <td>
                                <TimeRow
                                  v-bind:time="item.final_time"
                                ></TimeRow>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <PlusTime
                        v-if="role != 'esterno'"
                        v-bind:project_id="project.id"
                        v-bind:task_type="'tracciato'"
                      ></PlusTime>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card v-if="parseInt(result_time.split(':')[0])">
                      <v-card-title
                        v-if="parseInt(result_time.split(':')[0]) == 0"
                        class="headline grey lighten-2"
                      >
                        Tempo di lavoro totale:
                        {{ result_time.split(":")[1] }} minuti
                      </v-card-title>
                      <v-card-title v-else class="headline grey lighten-2">
                        Tempo di lavoro totale:
                        {{ result_time.split(":")[0] }} ore e
                        {{ result_time.split(":")[1] }} minuti
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'mobile-tabs-5-5'">
            <v-card
              flat
              class="tw-h-full !tw-rounded-bl-xl !tw-rounded-br-xl !tw-rounded-none tw-box-border tw-p-4 md:tw-p-6"
            >
              <v-row class="tw-p-0 tw-bg-white !tw-h-full">
                <v-col cols="12" class="tw-p-2 tw-flex tw-flex-col">
                  <div class="tw-flex tw-items-center">
                    <div class="tw-font-semibold tw-text-2xl">
                      Calcolo BCT/ECT
                    </div>
                    <primary-btn
                      @click.native="$store.state.bctModal = true"
                      text="Nuovo Calcolo"
                      class="tw-ml-2"
                    ></primary-btn>
                  </div>
                  <div
                    v-if="project.bct && project.bct.length > 0"
                    class="!tw-flex-1 tw-w-full md:tw-w-2/3 tw-overflow-y-auto tw-mt-8"
                  >
                    <BctListItem
                      v-for="(item, index) in project.bct"
                      :item="item"
                      :index="index"
                      :key="index"
                      :project_id="project.id"
                      :esterno="esterno"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <BctModal :id_project="project.id" :esterno="esterno" />
      <v-dialog
        v-model="new_prev_dialog"
        content-class="dl-time"
        scrollable
        max-height="600px"
        min-width="1000px"
      >
        <v-card>
          <!-- RENDERIZZA SCATOLA -->

          <form
            if="selected_prev_type == 'preventivi_scatola'"
            class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
          >
            <div class="tw-text-xl tw-font-bold tw-mb-8 tw-w-full">
              Nuovo Preventivo
            </div>
            <div
              class="tw-flex tw-w-full tw-justify-center tw-mb-2"
              v-for="(sub_quotation, index2) in scatola_model.quotations"
              :key="index2"
            >
              <div class="tw-flex tw-items-center tw-flex-1 tw-mb-2">
                <div>Compo</div>
                <div class="tw-flex-1 tw-ml-4">
                  <input-field
                    :darker="true"
                    class="tw-w-full"
                    type="text"
                    v-model="sub_quotation.compo"
                    placeholder="Compo"
                    :disabled="isDisabled"
                    required
                  ></input-field>
                </div>
              </div>
              <div class="tw-flex tw-items-center tw-w-1/5 tw-mb-2">
                <div class="tw-ml-4">Quantità</div>
                <div class="tw-flex-1 tw-ml-4">
                  <input-field
                    :darker="true"
                    class="tw-w-full"
                    type="number"
                    v-model="sub_quotation.qty"
                    placeholder="0"
                    :disabled="isDisabled"
                    required
                  ></input-field>
                </div>
              </div>
              <div class="tw-flex tw-items-center tw-w-1/5 tw-mb-2">
                <div class="tw-ml-4">Stampa</div>
                <div class="tw-flex-1 tw-ml-4">
                  <custom-select
                    placeholder="Seleziona il tipo di stampa"
                    :clearable="true"
                    label="name"
                    :options="['Flexo', 'Offset', 'Digitale']"
                    :disabled="isDisabled"
                    class="!tw-bg-grey tw-min-h-full tw-text-black tw-py-2 tw-px-4 tw-rounded-xl tw-outline-none tw-cursor-pointer tw-w-full !tw-border-0 select-style-chooser"
                    v-model="sub_quotation.stampa"
                  >
                  </custom-select>
                </div>
              </div>
              <div class="tw-flex tw-items-center tw-w-1/5 tw-mb-2">
                <div class="tw-ml-4">N° Colori</div>
                <div class="tw-flex-1 tw-ml-4">
                  <input-field
                    :darker="true"
                    class="tw-w-full"
                    type="number"
                    v-model="sub_quotation.n_colori"
                    placeholder="0"
                    :disabled="isDisabled"
                  ></input-field>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-mt-2 tw-justify-center tw-items-center">
              <v-icon @click="addSubQuotation()">mdi-plus-circle</v-icon>
            </div>
            <div class="tw-flex tw-items-center tw-w-full tw-mt-4">
              <div class="tw-w-full"></div>
            </div>
            <div class="tw-flex tw-w-full tw-mt-2">
              <div>Note</div>
              <div class="tw-flex-1 tw-ml-4">
                <textarea
                  class="tw-bg-grey tw-rounded-xl tw-w-full tw-h-64 tw-box-border tw-p-4"
                  v-model="scatola_model.notes"
                  placeholder="Inserisci le note..."
                ></textarea>
              </div>
            </div>
          </form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="new_prev_dialog = false">
              Annulla
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isLoading.quotation"
              @click="createQuotation(selected_prev_type)"
            >
              Crea
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div
        v-if="isMobileChatVisible"
        class="timeline tw-flex-1 !tw-bg-white !tw-h-full md:tw-ml-4 box-border tw-rounded-xl tw-flex tw-flex-col md:tw-relative tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 md:tw-top-auto md:tw-left-auto md:tw-transform-none md:tw--translate-x-0 md:tw--translate-y-0 tw-w-full"
      >
        <div class="tw-flex tw-p-8 tw-items-center">
          <div class="tw-text-2xl tw-flex-1 tw-font-semibold">Timeline</div>
          <div
            @click="isMobileChatVisible = false"
            class="tw-bg-custom-blue tw-w-10 tw-h-10 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-rounded-full"
          >
            <v-icon class="!tw-text-white">mdi-close</v-icon>
          </div>
        </div>
        <div class="tw-flex-1 tw-overflow-hidden tw-p-8">
          <div
            class="tw-flex tw-flex-col tw-h-full tw-overflow-y-scroll font2 tw-pr-4"
            ref="messagesContainer"
          >
            <div
              v-for="(block, date) in project.talk.length > 0
                ? sortedTalkProject
                : project.talk"
              :key="date"
              class="tw-w-full tw-flex tw-flex-col"
            >
              <div
                class="tw-text-center tw-text-xl tw-font-medium tw-mb-4 tw-mt-2"
              >
                - {{ date }} -
              </div>
              <div
                v-for="(task, index) in block"
                :key="index"
                :class="taskStatus(task.is_read).bg"
                class="tw-p-4 tw-rounded-xl tw-flex tw-flex-col tw-mb-2"
              >
                <div class="tw-flex tw-justify-end">
                  <primary-btn
                    class="tw-ml-2"
                    text="a ICA"
                    v-if="user.role == 'commerciale'"
                    @click.native="transferData('talk', '', task)"
                  ></primary-btn>
                </div>
                <div class="tw-font-bold tw-mb-2 tw-text-lg">
                  {{ task.sender }}
                </div>
                <div class="tw-text-md tw-whitespace-pre-line" v-html="task.msg">
                </div>
                <div
                  class="tw-w-full tw-flex tw-justify-between tw-mt-8 tw-items-center"
                >
                  <div
                    v-if="!task.is_read"
                    class="tw-text-xs tw-text-custom-red tw-font-bold"
                  >
                    {{ taskStatus(task.is_read).text }}
                  </div>
                  <div v-else class="tw-text-xs tw-text-green tw-font-bold">
                    {{ taskStatus(task.is_read).text }}
                  </div>
                  <div class="tw-text-xs tw-font-bold">{{ task.time }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="user.role == 'esterno'" class="tw-h-24 tw-box-border tw-p-4">
          <div class="tw-w-full tw-flex tw-items-center tw-h-full tw-relative">
            <textarea
              id="send"
              :class="{ box_full_h: msgBox }"
              class="tw-bg-azure tw-p-4 tw-rounded-xl l tw-w-full tw-bottom-0 tw-absolute tw-h-full tw-transition-all"
              @click="toggleBox('opening')"
              @blur="toggleBox('closing')"
              :disabled="isDisabled"
              @keyup.enter="addLineBreak"
              v-model="current_msg"
              placeholder="Scrivi un messaggio..."
              type="text"
              name="message"
            ></textarea>
            <div
              @click="newTask"
              class="tw-absolute tw-right-0 tw-z-10 tw-w-12 tw-h-12 tw-p-4 tw-flex tw-items-center tw-justify-center tw-bg-custom-blue tw-rounded-xl tw-mr-2 hover:tw-opacity-80 tw-cursor-pointer"
            >
              <v-icon dark>mdi-send</v-icon>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <response-notification></response-notification>

    <div
      v-if="!isMobileChatVisible"
      class="tw-absolute tw-bottom-4 tw-right-4 md:tw-hidden tw-block !tw-z-50"
    >
      <div
        @click="isMobileChatVisible = true"
        class="tw-bg-custom-blue tw-relative tw-w-16 tw-h-16 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-rounded-full"
      >
        <v-icon class="!tw-text-white">mdi-chat</v-icon>
      </div>
    </div>
    <div id="loading">
      <div class="shape">
        <v-progress-circular
          :rotate="360"
          :size="200"
          :width="25"
          :value="uploading"
          color="#1f7a8c"
          class="loading"
        >
          {{ uploading }}
        </v-progress-circular>
      </div>
    </div>

    <v-dialog v-model="dialog_delegate" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"
            ><b>Avviso:</b> Questo progetto è stato delegato a
            {{ project.delegate }}</span
          >
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_delegate = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alert" width="500">
      <v-card style="background-color: white">
        <v-card-title style="margin-bottom: 20px">
          <h1>Attenzione!</h1>
        </v-card-title>

        <v-card-subtitle
          ><h3>
            I seguenti file superano il limite di caricamento di 7 MB, per cui
            NON verranno caricati:
          </h3>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col v-if="unuploaded_files.length > 0" cols="12" class="d-flex">
              <v-card
                style="width: 100% !important; color: black !important"
                v-for="(file, index) in unuploaded_files"
                :key="index"
                ><v-card-text style="width: 100% !important">{{
                  file
                }}</v-card-text></v-card
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeFilesAlert">
            Ho capito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="role == 'commerciale' && is_p_saved"
      v-model="dialog_production"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Dati Produzione</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field
                @input="checkEdit"
                label="Quantità*"
                required
                v-model="pieces_prod"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                @input="checkEdit"
                label="Onda*"
                required
                v-model="material.onda"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                @input="checkEdit"
                label="Copertina Est.*"
                required
                outlined
                v-model="material.copertina_est"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                @input="checkEdit"
                label="Copertina Int.*"
                required
                v-model="material.copertina_int"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Data di consegna</span>
              </v-card-title>
            </v-col>

            <v-col cols="4">
              <v-text-field
                @input="checkEdit"
                label="Inserisci una data*"
                required
                v-model="delivery_date"
                type="date"
                :min="today"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Palletizzazione</span>
              </v-card-title>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="palletizzazione"
                :items="pallet"
                label="Seleziona palletizzazione"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="legato"
                label="Legato a pacchi"
                value="Legato a pacchi"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="legato"
                label="Film Estensibile"
                value="Film Estensibile"
              ></v-checkbox>
            </v-col>
            <v-col v-if="palletizzazione == pallet[0]" cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Tipo di bancale</span>
              </v-card-title>
            </v-col>
            <v-col cols="12" v-if="palletizzazione == pallet[0]">
              <v-select
                v-model="bancale"
                :items="bancali"
                label="Seleziona tipo di bancale"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                @input="checkEdit"
                label="Note"
                outlined
                v-model="notes_prod"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_production = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Richiedi Produzione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else-if="role == 'master' && is_p_saved"
      v-model="dialog_production"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Dati Produzione</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field
                @input="checkEdit"
                label="Quantità*"
                required
                v-model="pieces_prod"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                @input="checkEdit"
                label="Onda*"
                required
                v-model="material.onda"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                @input="checkEdit"
                label="Copertina Est.*"
                required
                v-model="material.copertina_est"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                @input="checkEdit"
                label="Copertina Int.*"
                required
                v-model="material.copertina_int"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Data di consegna</span>
              </v-card-title>
            </v-col>

            <v-col cols="4">
              <v-text-field
                @input="checkEdit"
                label="Inserisci una data*"
                v-model="delivery_date"
                type="date"
                :min="today"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Palletizzazione</span>
              </v-card-title>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="palletizzazione"
                :items="pallet"
                label="Seleziona palletizzazione"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="legato"
                label="Legato a pacchi"
                value="Legato a pacchi"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="legato"
                label="Film Estensibile"
                value="Film Estensibile"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col v-if="palletizzazione == pallet[0]" cols="12" class="nopd">
              <v-card-title>
                <span class="headline">Tipo di bancale</span>
              </v-card-title>
            </v-col>
            <v-col cols="12" v-if="palletizzazione == pallet[0]">
              <v-select
                v-model="bancale"
                :items="bancali"
                label="Seleziona tipo di bancale"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                @input="checkEdit"
                label="Note"
                outlined
                v-model="notes_prod"
                disabled
              >
              </v-textarea>
              <v-row class="files nopd">
                <v-col sm="9" class="form-allegato force-h">
                  <form
                    enctype="multipart/form-data"
                    id="allegato-prod"
                    class="form-ica"
                  >
                    <label class="uploader">
                      <div v-if="prod_files.length == 0">
                        Non sono presenti file...
                      </div>

                      <v-row
                        class="allegato-row"
                        v-for="(fname, index) in prod_files"
                        :key="index"
                      >
                        <v-col class="nopd center_y" sm="10">{{
                          fname.replace(/_/g, " ")
                        }}</v-col>
                        <v-col class="nopd" sm="2">
                          <v-col cols="12" class="nopd right">
                            <v-icon
                              class="action-file"
                              @click="
                                downloadProductionFile(
                                  fname,
                                  'production',
                                  index
                                )
                              "
                            >
                              mdi-download
                            </v-icon>
                            <v-icon
                              class="action-file"
                              @click="deleteprodfiles(index)"
                            >
                              mdi-delete
                            </v-icon>
                          </v-col>
                        </v-col>
                      </v-row>
                    </label>
                  </form>
                </v-col>
                <v-col sm="3" class="input-ica">
                  <div class="upload-icon">
                    <v-icon color="primary" dark>mdi-upload </v-icon>
                    <p style="font-size: 13px">
                      Trascina i file qui per caricarli
                    </p>
                  </div>
                  <input
                    @change="uploadprodFiles"
                    id="upload-false_prod-file"
                    class="nicenice"
                    name="prod-file"
                    type="file"
                    size="1"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_production = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Conferma Produzione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import { bus } from "../main";

import TimeRow from "../components/ProjectComponents/TimeRow.vue";
import PlusTime from "../components/PlusTime.vue";
import missingInformationButton from "../components/missingInformationButton.vue";
import transferData from "../components/transferData.vue";
import BctModal from "../components/ProjectComponents/BctModal.vue";
import BctListItem from "../components/ProjectComponents/BctListItem.vue";
import CounterBadge from '../components/ProjectComponents/CounterBadge.vue';
import NotesComponent from '../components/ProjectComponents/NotesComponent/NotesComponent.vue';

export default {
  name: "ProjectEsterno",
  props: ["id", "choosenEsterno"],
  components: {
    TimeRow,
    PlusTime,
    missingInformationButton,
    transferData,
    BctModal,
    BctListItem,
    CounterBadge,
    NotesComponent
  },

  data() {
    return {
      isMobileChatVisible: false,
      isLoading: {
        bct: {
          saving: false,
        },
        quotation: false,
        actions: false,
        saving: false,
      },
      hasScrolled: false,
      is_p_saved: false,
      alert: false,
      unuploaded_files: [],
      selected_prev_type: "",
      secondary_referrers: [
        "Gianfranco",
        "Fabiano",
        "Chiara",
        "Monica",
        "Roberta",
        "Roberto",
      ],
      scatola_model: {
        quotations: [
          {
            compo: "",
            qty: 0,
            n_colori: 0,
            stampa: "",
            fustella: { price: 0, resa: 0 },
            impianto_stampa: { price: 0, resa: 0 },
            unitario: 0,
          },
        ],
        notes: "",
        is_saved: 0,
        type: "box",
        ref: "",
        delegate: "",
        status: 0,
        is_editing: 0,
      },
      new_prev_dialog: false,
      no_preview: false,
      requested_file: "",
      filePreviewDialog: false,
      current_uploading: [],
      fileDialog: false,
      form_file: undefined,
      supported_ico_ext: ["jpg", "jpeg", "png", "ard", "pdf", "ai"],
      path: "",
      actions_dialog: false,
      delegates: [],
      fab: false,
      est_or_int: null,
      are_dimensions: false,
      current_answer: [],
      delegate: "",
      response_type: "success",
      tabs: "mobile-tabs-5-1",
      current_msg: "",
      production_status: "",
      dialog_priority: false,
      priority: "",
      priorities: ["ordine in corso", "urgente", "standard", "non urgente"],
      current_user: [],
      imballo: "",
      comp: "",
      es_l: "",
      es_p: "",
      es_h: "",
      int_l: "",
      int_p: "",
      int_h: "",
      dim_int: "",
      fefco: "",
      stampa: "",
      colori: "",
      ritiro: "",
      role: "",
      material: {},
      pallet: ["Su bancale", "Master a croce", "Americana 60x40", "Nessuna"],
      palletizzazione: "",
      legato: [],
      bancale: "",
      bancali: [
        "Generico 80x120",
        "Generico 100x120",
        "EPAL Prima Scelta",
        "EPAL Seconda Scelta",
        "Bancale fuori misura",
      ],

      canEdit: undefined,
      isDisabled: false,
      isEditing: "",
      overlay: "",

      customers: [],

      dialog: false,
      email_dialog: false,
      time_data_dialog: false,
      dialog_transfer: false,
      dialog_delegate: false,

      new_name: "",
      new_address: "",
      new_city: "",
      new_cap: "",
      new_province: "",
      new_country: "",
      new_phone: "",
      new_email: "",
      new_ref: "",
      new_site: "",
      time: "",
      date: "",
      timestamp: "",
      start_time: {},
      end_time: {},
      tracciato: [],
      start_time_tracciato: "",
      end_time_tracciato: "",
      start_time_mastrino: "",
      end_time_mastrino: "",
      start_time_campionatura: "",
      end_time_campionatura: "",
      requests: [],

      time_tracciato: [],
      time_mastrino: [],
      time_campionatura: [],

      uploadPercentage: 0,
      success: false,
      success_text: "",
      loading: false,
      file_uploader: "",

      ica_files: [],
      customer_files: [],
      prod_files: [],
      data_transfer: new FormData(),

      input_isDisabled: true,

      preview: "",
      result_time: "",
      edited: false,
      uploading: 0,

      pieces_prod: 0,
      notes_prod: "",
      suppliers: [],
      msgBox: false,
      existing_files: [],
    };
  },

  created: async function () {
    this.checkIfMobile();
    let project = {};
    this.path = axios.defaults.baseURL.split("api")[0];
    axios
      .get(
        `project/${
          this.user.role === "commerciale" || this.choosenEsterno
            ? this.choosenEsterno
            : this.user.esterno
        }/${this.id}`
      )
      .then((response) => {
        project = response.data.project;
        project.bct = JSON.parse(project.bct);
        document.title = this.$route.meta.title + " N° " + project.id;
        let delegates = this.delegates;
        delegates.push(project.delegate);
        this.delegates = delegates;

        if (project.progettazione_tecnica.dim_est.l) {
          this.est_or_int = "Esterne";
          this.are_dimensions = true;
        } else if (project.progettazione_tecnica.dim_int.l) {
          this.est_or_int = "Interne";
          this.are_dimensions = true;
        }

        this.role = this.user.role;

        this.suppliers = response.data.suppliers;

        this.current_user = response.data.current_user;
        this.canEdit = true;
        this.customers = response.data.customers;

        try {
          this.start_time_tracciato =
            project.tracciato[response.data.tracciato.length - 1].start_time;
          this.end_time_tracciato =
            project.tracciato[response.data.tracciato.length - 1].end_time;
        } catch {}
        try {
          this.start_time_mastrino =
            project.mastrino[response.data.mastrino.length - 1].start_time;
          this.end_time_mastrino =
            project.mastrino[response.data.mastrino.length - 1].end_time;
        } catch {}

        this.$store.dispatch("setProject", project);

        this.openProject();
        this.view();

        if (this.role == "master" && this.user.username != project.delegate) {
          this.dialog_delegate = true;
        }
      });
    bus.$on("getTimes", () => {
      axios.get("/times/" + this.project.id).then((response) => {
        this.project.tracciato = response.data.times.tracciato;
        this.project.mastrino = response.data.times.mastrino;
      });
    });

    window.addEventListener("beforeunload", this.askConfirm);
  },
  updated: function () {
    if (!this.hasScrolled && !this.project) {
      this.Scroll();
    }
    if (this.project.status == "in lavorazione") {
      document.getElementById("actionbar").style.zIndex = 2000;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.edited == true) {
      this.$confirm("Hai dati non salvati, sei sicuro di voler uscire?").then(
        (res) => {
          if (res) {
            next();
          } else {
            next(false);
          }
        }
      );
    } else {
      next();
    }
  },

  destroyed: function () {
    this.hasScrolled = false;
    this.closeProject();
  },

  computed: {
    customerFiles(){
      return this.project.customer_files.filter(file => !file.archived)
    },
    archivedCustomerFiles(){
      return this.project.customer_files.filter(file => file.archived)
    },
    icaFiles(){
      return this.project.ica_files.filter(file => !file.archived)
    },
    archivedICAFiles(){
      return this.project.ica_files.filter(file => file.archived)
    },
    sortedTalkProject() {
      return Object.keys(this.project.talk[0])
        .sort((a, b) => {
          let dateA = a.split("-").reverse().join("-");
          let dateB = b.split("-").reverse().join("-");

          return new Date(dateA) - new Date(dateB);
        })
        .reduce((obj, key) => {
          obj[key] = this.project.talk[0][key];
          return obj;
        }, {});
    },
    esterno() {
      if (this.user.role != "commerciale") return this.user.esterno;
      return this.choosenEsterno;
    },
    project: {
      get() {
        return this.$store.state.project;
      },
      set(val) {
        this.$store.dispatch("setProject", val);
      },
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    customers_list() {
      var customers = [];
      this.customers.forEach((customer) => {
        if (customer.name && !customers.includes(customer.name)) {
          customers.push(customer.name);
        }
      });
      return customers;
    },
  },

  methods: {
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );
      console.log("ismobile ", isMobile);
      isMobile
        ? (this.isMobileChatVisible = false)
        : (this.isMobileChatVisible = true);
    },
    editQuot(index, index2, type) {
      var edited = this.project.preventivi_scatola[index];
      edited.quotations[index2][type].isEditing = true;
      this.$set(this.project.preventivi_scatola, index, edited);
    },

    closeFilesAlert() {
      this.alert = false;
      this.unuploaded_files = [];
    },
    newTask: function () {
      if (
        !this.project.customer &&
        !this.project.name &&
        !this.project.referrer
      ) {
        this.$store.dispatch("notificate", {
          text: "Inserisci cliente e progetto e commerciale di riferimento",
          type: "warning",
        });
        return;
      }
      const formData = new FormData();
      this.project.last_msg = this.current_msg;

      this.project.esterno = this.esterno;
      axios
        .post("new-task/" + this.esterno, {
          project: JSON.stringify(this.project),
        })
        .then((response) => {
          if (response.data.alert) {
            this.$confirm(response.data.msg).then((res) => {
              if (res) {
                this.updateProject();
              }
            });
          } else {
            console.log("STO QUA");
            this.project.talk = response.data.project.talk;
            this.msgBox = false;
            this.dialog_priority = false;
            this.current_msg = "";
            if (this.project.customer == "" && this.project.name == "") {
              this.is_p_saved = false;
            } else {
              this.is_p_saved = true;
            }
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
          }
        });
    },

    handleStopping() {
      this.$confirm("Vuoi fermare la lavorazione?").then((res) => {
        if (res) {
          this.Task("end");
        }
      });
    },
    openPrevDialog(type) {
      this.selected_prev_type = type;
      this.new_prev_dialog = true;
    },
    taskStatus(status) {
      if (status) {
        return { bg: "tw-bg-grey", text: "Completata" };
      } else {
        return { bg: "tw-bg-azure", text: "Non completata" };
      }
    },

    openProductionDialog() {
      this.dialog_production = true;
    },
    closePreviewDialog() {
      this.filePreviewDialog = false;
      this.preview = window.location.origin + "/static/img/no_preview.png";
      this.no_preview = false;
    },
    handleNoPreview() {
      this.no_preview = true;
      this.preview = window.location.origin + "/static/img/no_preview.png";
    },
    openPreviewDialog(name, type) {
      this.requested_file =
        window.location.origin +
        "/static/files/" +
        "/" +
        this.esterno +
        "/" +
        this.project.id +
        "/" +
        type +
        "/" +
        name;
      var ext = name.split(/[\s.]+/);
      ext = ext[ext.length - 1];
      if (ext != "pdf") {
        this.preview = this.requested_file;
      } else {
        var path =
          window.location.origin +
          "/static/files/" +
          this.project.id +
          "/" +
          type +
          "/previews/";
        this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      }
      this.filePreviewDialog = true;
    },
    closeFileDialog() {
      this.fileDialog = false;
      this.current_uploading = [];
    },
    openUploadFileDialog(form) {
      this.form_file = form;
      this.fileDialog = true;
    },
    getFileIco(fname) {
      var ext = fname.split(/[\s.]+/);
      ext = ext[ext.length - 1];
      if (this.supported_ico_ext.includes(ext)) {
        return ext;
      } else {
        return "generic";
      }
    },
    toggleBox(action) {
      if (this.msgBox && action != "opening") {
        this.msgBox = false;
      } else {
        this.msgBox = true;
      }
    },
    setTalk(talk) {
      this.project.talk = talk;
    },
    missingInfo() {
      this.$refs.missing_info.openDialog();
    },
    setIdEsterno(id) {
      this.project.id_associato = id;
    },
    cloneProject() {
      this.$confirm("Sei sicuro di voler clonare il seguente progetto?").then(
        (res) => {
          this.edited = false;
          axios
            .post("clone-project/" + this.esterno, {
              project: JSON.stringify(this.project),
            })
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg.text,
                type: response.data.msg.type,
              });
              this.$router.push("/projects-queue").catch(() => {});
            });
        }
      );
    },

    transferData(type, fname = "", talk = "") {
      if (this.project.id_associato) {
        if (type == "progettazione_tecnica") {
          axios
            .post(
              "/transfer/" +
                this.project.id_associato +
                "/" +
                this.project.id +
                "/ica/" +
                this.esterno,
              {
                progettazione_tecnica: JSON.stringify(
                  this.project.progettazione_tecnica
                ),
              }
            )
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: response.data.msg_type,
              });
            });
        } else if (type == "ica_file" && fname != "") {
          axios
            .post(
              "/transfer/" +
                this.project.id_associato +
                "/" +
                this.project.id +
                "/ica/" +
                this.esterno,
              { ica_file: fname }
            )
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: response.data.msg_type,
              });
            });
        } else if (type == "customer_file" && fname != "") {
          axios
            .post(
              "/transfer/" +
                this.project.id_associato +
                "/" +
                this.project.id +
                "/ica/" +
                this.esterno,
              { customer_file: fname }
            )
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: response.data.msg_type,
              });
            });
        } else if (type == "talk" && talk != "") {
          axios
            .post(
              "/transfer/" +
                this.project.id_associato +
                "/" +
                this.project.id +
                "/ica/" +
                this.esterno,
              { talk: JSON.stringify(talk) }
            )
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: response.data.msg_type,
              });
            });
        }
      } else {
        this.$refs.transfer.openDialog();
      }
    },
    hasCreated(is_saved) {
      if (is_saved) {
        return "7";
      } else if (!is_saved && this.role != "commerciale") {
        return "12";
      }
    },
    checkForm(classname) {
      var forms = document.getElementsByClassName(classname);
      for (var i = 0; i < forms.length; i++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }
      return true;
    },

    calcStatus(items) {
      var somma = 0;

      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          somma = somma + items[i].status;
        }

        var media = somma / items.length;

        if (media < 1 || media == 0) {
          return 0;
        } else if (media < 2 || media == 1) {
          return 1;
        } else if (media == 2) {
          return 2;
        }
      }
    },

    filterSuppliers(items, type) {
      var new_items = [];
      if (type == "fustella") {
        new_items = items.filter((item) => item.fustella.length > 0);

        return new_items;
      } else if (type == "stampa") {
        new_items = items.filter((item) => item.impianto_stampa.length > 0);
        return new_items;
      }
    },
    generateSuppliers(suppliers, type, quotation_index) {
      var suppliers_list = [];
      var items = this.filterSuppliers(suppliers, type);

      if (type == "fustella") {
        var selected =
          this.project.preventivi_fustella[quotation_index].machine_supplier;
        for (var i = 0; i < items.length; i++) {
          if (items[i].name == selected) {
            for (var j = 0; j < items[i].fustella.length; j++) {
              suppliers_list.push({
                supplier: items[i].fustella[j],
                price: "",
                status: 0,
                has_sent: 0,
                is_editing: 0,
                notes: "",
              });
              this.project.preventivi_fustella[quotation_index].suppliers =
                suppliers_list;
            }
          }
        }
      } else if (type == "stampa") {
        var selected_ =
          this.project.preventivi_stampa[quotation_index].machine_supplier;
        for (var x = 0; x < items.length; x++) {
          if (items[x].name == selected_) {
            for (var z = 0; z < items[x].impianto_stampa.length; z++) {
              suppliers_list.push({
                supplier: items[x].impianto_stampa[z],
                price: "",
                status: 0,
                has_sent: 0,
                is_editing: 0,
                notes: "",
              });
              this.project.preventivi_stampa[quotation_index].suppliers =
                suppliers_list;
            }
          }
        }
      }
    },
    Scroll() {
      try {
        var content = this.$refs.messagesContainer;
        content.scrollTop = content.scrollHeight;
        this.hasScrolled = true;
      } catch (err) {
        console.log(err);
      }
    },
    dragStart(e, fname, type) {
      //e.dataTransfer.effectAllowed = "copyMove";
      e.dataTransfer.setData(
        "DownloadUrl",
        "application/octet-stream:" +
          fname +
          ":http://" +
          window.location.host +
          "/static/files/" +
          this.esterno +
          "/" +
          this.project.id +
          "/" +
          type +
          "/" +
          fname
      );
    },
    dict_reverse: function (obj) {
      var new_obj = {};
      var rev_obj = Object.keys(obj).reverse();
      rev_obj.forEach(function (i) {
        new_obj[i] = obj[i];
      });
      return new_obj;
    },

    addQuotation(i) {
      this.project.preventivi_scatola[i].qty.push({
        qty: "",
        price: "",
        resa: "",
        compo: "",
      });
    },

    removeQuotation(i) {
      this.project.preventivi_scatola[i].qty.splice(
        this.project.preventivi_scatola[i].qty.indexOf(i),
        1
      );
    },

    createQuotation: function (type) {
      if (this.checkForm("quotation-form")) {
        var obj;
        this.scatola_model.delegate = this.project.delegate;
        this.scatola_model.ref = this.project.referrer;
        this.scatola_model.customer = this.project.customer;
        this.scatola_model.riferimento = this.project.name;
        obj = this.scatola_model;
        obj.stampa = this.scatola_model.stampa;

        var form = new FormData();
        form.append("quotation", JSON.stringify(obj));
        this.isLoading.quotation = true;
        axios
          .post(
            "save-quotation-esterno/" +
              this.project.id +
              "/" +
              type +
              "/" +
              this.esterno,
            form
          )
          .then((response) => {
            obj.is_saved = 1;
            obj.is_editing = 0;
            obj.delegate = response.data.quotation.delegate;
            obj.status = response.data.quotation.status;
            obj.ref = response.data.quotation.ref;

            this.project.preventivi_scatola.push(obj);
            this.isLoading.quotation = false;
            this.new_prev_dialog = false;
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
          });
      }
    },

    addSubQuotation() {
      if (this.scatola_model.quotations.length > 0) {
        var last_created =
          this.scatola_model.quotations[
            this.scatola_model.quotations.length - 1
          ];
        this.scatola_model.quotations.push({
          compo: last_created.compo,
          qty: last_created.qty,
          n_colori: last_created.n_colori,
          stampa: last_created.stampa,
          fustella: { price: 0, resa: 0 },
          impianto_stampa: { price: 0, resa: 0 },
          unitario: 0,
        });
      } else {
        this.scatola_model.quotations.push({
          compo: "",
          qty: 0,
          n_colori: 0,
          stampa: "",
          fustella: { price: 0, resa: 0 },
          impianto_stampa: { price: 0, resa: 0 },
          unitario: 0,
        });
      }
    },
    addSubEdit(index) {
      var prev = this.project.preventivi_scatola[index];
      if (prev.quotations.length > 0) {
        var last_created =
          prev.quotations[this.scatola_model.quotations.length - 1];
        prev.quotations.push({
          compo: last_created.compo,
          qty: last_created.qty,
          n_colori: last_created.n_colori,
          stampa: last_created.stampa,
          fustella: { price: 0, resa: 0 },
          impianto_stampa: { price: 0, resa: 0 },
          unitario: 0,
        });
      } else {
        prev.quotations.push({
          compo: "",
          qty: 0,
          n_colori: 0,
          stampa: "",
          fustella: { price: 0, resa: 0 },
          impianto_stampa: { price: 0, resa: 0 },
          unitario: 0,
        });
      }

      this.$set(this.project.preventivi_scatola, index, prev);
    },
    removeSubEdit(index, index2) {
      var prev = this.project.preventivi_scatola[index];
      prev.quotations.splice(index2, 1);

      this.$set(this.project.preventivi_scatola, index, prev);
    },
    updateQuotation: function (i, type) {
      this.isLoading.quotation = true;

      var forms = document.getElementsByClassName("quot-form");
      for (var j = 0; j < forms.length; j++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }

      axios
        .post(
          "update-quotation-esterno/" +
            this.project.id +
            "/" +
            i +
            "/" +
            type +
            "/" +
            this.esterno,
          { quotation: JSON.stringify(this.project[type][i]) }
        )
        .then((response) => {
          this.project[type][i].status = response.data.quotation.status;
          this.project[type][i].is_editing = response.data.quotation.is_editing;
          this.project[type][i].has_sent = response.data.quotation.has_sent;
          this.isLoading.quotation = false;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
        });
    },
    deleteQuotation: function (i, type) {
      this.$confirm("Sei sicuro di voler cancellare il preventivo?").then(
        (res) => {
          if (res) {
            axios
              .post(
                "delete-quotation-esterno/" +
                  this.project.id +
                  "/" +
                  i +
                  "/" +
                  type +
                  "/" +
                  this.esterno
              )
              .then((response) => {
                this.project[type].splice(this.project[type].indexOf(i), 1);
                this.$store.dispatch("notificate", {
                  text: response.data.msg,
                  type: "success",
                });
              });
          }
        }
      );
    },

    delegateProject: function () {
      const delegateForm = new FormData();

      delegateForm.append("delegate", this.project.delegate);

      axios
        .post("delegate/" + this.project.id + "/" + this.esterno, delegateForm)
        .then((response) => {
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
        });
    },

    setLoader: function () {
      const loader = document.querySelector("#loading");
      loader.style.display = "flex";
      if (this.uploading == 100) {
        setTimeout(function () {
          loader.style.display = "none";
        }, 1500);
      }
    },

    askConfirm: function (e) {
      if (this.edited) {
        var confirmationMessage = "Sium";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE

        return confirmationMessage;
      }
    },

    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      var h = today.getHours();
      var m = today.getMinutes();
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }

      const time = h + ":" + m;

      const dateTime = date + " " + time;
      this.timestamp = dateTime;
      this.date = date;
      this.time = time;
    },

    getFinale: function () {
      var hours = 0;
      var minutes = 0;
      var all_times = this.project.tracciato.concat(
        this.project.mastrino,
        this.project.campionatura
      );
      for (var i = 0; i < all_times.length; i++) {
        try {
          hours = hours + parseInt(all_times[i].final_time.split(":")[0]);
          minutes = minutes + parseInt(all_times[i].final_time.split(":")[1]);
        } catch {}
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      var result_time = String(hours) + ":" + String(minutes);
      this.result_time = result_time;
    },

    checkEdit: function () {
      this.edited = true;
    },
    shareFiles: function (filename, ref) {
      const fname = new FormData();
      fname.append("filename", filename);
      fname.append("to", ref);
      axios
        .post("view-filez/" + this.esterno + "/" + this.project.id + "/", fname)
        .then((response) => {
          if (response.data.msg.type == "success") {
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
            let viewPath = window.location.origin;
            if (viewPath.includes("127.0.0.1"))
              viewPath = viewPath.replace(":8080", ":5000");
            window.open(viewPath + "/" + response.data.path);
          } else {
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
          }
        });
    },

    goBack: function () {
      this.$router.push("/projects-queue").catch(() => {});
    },

    addLineBreak() {
      //var linebreak = document.createElement("br");
      this.getNow();
      var input = document.querySelector("#send").value;
      this.current_msg = input;
      //this.current_msg = this.current_msg +  " " + this.date + " (" + this.time + ")";
    },
    downloadRequestedFile() {
      window.open(this.requested_file);
    },
    generatePreviews: function () {
      try {
        var allegati = this.project.ica_files.concat(
          this.project.customer_files
        );
        var i;
        for (i = 0; i < allegati.length; i++) {}
      } catch {}
    },

    uploadFiles: function (e) {
      const filesForm = new FormData();
      var files = e.target.files;

      for (var x = 0; x < e.target.files.length; x++) {
        if (e.target.files[x].size > 7340032) {
          this.unuploaded_files.push(e.target.files[x].name);
        } else {
          filesForm.append(e.target.getAttribute("name"), e.target.files[x]);
          if (e.target.getAttribute("name") == "customer-file") {
            filesForm.append("type", "customer");
          } else if (e.target.getAttribute("name") == "ica-file") {
            filesForm.append("type", "ica");
          }
          this.current_uploading.push({
            name: e.target.files[x].name,
            size: (e.target.files[x].size / 1024 / 1024).toFixed(2),
          });
        }
      }

      let config = {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.uploading = percentCompleted;
        },
      };

      for (var i = 0; i < files.length; i++) {
        if (e.target.getAttribute("name") == "ica-file") {
          if (this.project.ica_files.includes(files[i].name)) {
            this.existing_files.push(files[i].name);
          }
        } else {
          if (this.project.customer_files.includes(files[i].name)) {
            this.existing_files.push(files[i].name);
          }
        }
      }

      if (this.existing_files.length == 1) {
        this.$confirm(
          "Il file " +
            this.existing_files[0] +
            " è già esistente e verrà sovrascritto. \n Sicuro di voler continuare?"
        ).then((res) => {
          if (!res) {
            return;
          }
        });
      } else if (this.existing_files.length > 1) {
        let msg = "";
        for (var j = 0; j < this.existing_files.length; j++) {
          msg = msg + "\n -" + this.existing_files[j];
        }
        this.$confirm(
          "I seguenti file " +
            msg +
            " \n esistono e verranno sovrascritti. \n Sicuro di voler continuare?"
        ).then((res) => {
          if (!res) {
            return;
          }
        });
      }

      var counter = 0;

      if (!filesForm.entries().next().done) {
        filesForm.append("id_project", this.project.id);
        filesForm.append("esterno", this.esterno);
        axios.post("upload-file/", filesForm, config).then((response) => {
          this.project.talk = response.data.timeline;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
          if (e.target.getAttribute("name") == "ica-file") {
            this.project.ica_files = response.data.ica_files;
          } else if (e.target.getAttribute("name") == "customer-file") {
            this.project.customer_files = response.data.customer_files;
          }
          this.generatePreviews();
          if (this.form_file == "ica-file") {
            this.$refs.ica_files.value = null;
          } else {
            this.$refs.customer_files.value = null;
          }
        });
      }

      this.existing_files = [];
      if (this.unuploaded_files.length > 0) {
        this.alert = true;
      }
    },

    confirmTransfer: function () {
      this.project.to_transfer = this.to_transfer;

      let config;

      config = {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.uploading = percentCompleted;
          this.setLoader();
        },
      };

      axios
        .post(
          "transfer-data/" + this.esterno,
          { project: JSON.stringify(this.project) },
          config
        )
        .then((response) => {
          this.project = response.data.project;
          this.dialog_transfer = false;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
          this.edited = false;
        })

        .catch((error) => {
          if (error.response) {
          }
        });
    },

    filePreviewica: function (name) {
      //var path  = "//pc-jacopo/uploads/files/" + this.$route.params.id + "/ica/previews/";
      var path =
        window.location.origin +
        "/static/files/" +
        this.esterno +
        "/" +
        this.project.id +
        "/ica/previews/";
      //var path  = "http://192.168.1.50:8080/assets/uploads/files/" + this.$route.params.id + "/ica/previews/";
      this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      // DA OTTIMIZZARE LE IMMAGINI DA BACKEND
      //  if (name.split(".")[1] == "jpg" || name.split(".")[1] == "png"){
      //    path  = "http://local.icaproject.com:8081/assets/uploads/files/" + this.$route.params.id + "/ica/";
      //    this.preview = path + name;
      //}
    },

    filePreviewcustomer: function (name) {
      //var path  = "//pc-jacopo/uploads/files/" + this.$route.params.id + "/customer/previews/";
      var path =
        window.location.origin +
        "/static/files/" +
        this.esterno +
        "/" +
        this.project.id +
        "/customer/previews/";
      //var path  = "http://192.168.1.50:8080/assets/uploads/files/" + this.$route.params.id + "/customer/previews/";
      this.preview = path + name.split(".")[0] + "_preview" + ".jpg";
      // DA OTTIMIZZARE LE IMMAGINI DA BACKEND
      //if (name.split(".")[1] == "jpg" || name.split(".")[1] == "png"){
      //path  = "http://192.168.1.50:8081/assets/uploads/files/" + this.$route.params.id + "/customer/";
      //this.preview = path + name;

      //}
    },

    deleteprodfiles: function (number) {
      axios
        .post("delete-prod-file/" + this.project.id + "/" + number)
        .then((response) => {
          if (response.data.msg == "not found") {
          } else {
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            this.project.prod_files = response.data.prod_files;
          }
        });
    },
    downloadFile(filename, selector) {
      var form = new FormData();
      form.append("id", this.project.id);
      form.append("filename", JSON.stringify(filename));
      form.append("selector", selector);
      form.append("esterno", this.esterno);

      axios
        .post("download-file/" + this.esterno, form, { responseType: "blob" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename.name.replace(/_/g, " ");
          link.click();
        });
    },
    archiveFile(fileList, filename) {
      this.$confirm(
        "Sei sicuro di voler archiviare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios
            .patch(`project/${this.esterno}/${this.project._id}/archive-file/`, {
              file_to_archive: filename.name,
              file_list_name: fileList,
            })
            .then((response) => {
              fileList == 'ica_files' ? this.project.ica_files = response.data.file_list : this.project.customer_files = response.data.file_list
              this.$store.dispatch("notificate", {
                text: response.data.message,
                type: "success",
              });
            })
            .catch((err) => {
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore durante l'archiviazione del file",
                type: "error",
              });
            });
        }
      });
    },
    recoverFile(fileList, filename) {
      this.$confirm(
        "Sei sicuro di voler ripristinare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios
            .patch(`project/${this.esterno}/${this.project._id}/recover-file/`, {
              file_to_recover: filename.name,
              file_list_name: fileList,
            })
            .then((response) => {
              fileList == 'ica_files' ? this.project.ica_files = response.data.file_list : this.project.customer_files = response.data.file_list
              this.$store.dispatch("notificate", {
                text: response.data.message,
                type: "success",
              });
            })
            .catch((err) => {
              this.$store.dispatch("notificate", {
                text: "C'è stato un errore durante il ripristino del file",
                type: "error",
              });
            });
        }
      });
    },
    deleteFile(filename, selector) {
      var form = new FormData();
      form.append("id", this.project.id);
      form.append("filename", JSON.stringify(filename));
      form.append("selector", selector);
      form.append("esterno", this.esterno);

      this.$confirm(
        "Sei sicuro di voler cancellare il file " + filename.name + "?"
      ).then((res) => {
        if (res) {
          axios.post("delete-file/", form).then((response) => {
            this.project.talk = response.data.timeline;
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
            if (selector == "ica") {
              this.project.ica_files = response.data.files;
              this.selected_ica_files = [];
            } else if (selector == "customer") {
              this.project.customer_files = response.data.files;
              this.selected_customer_files = [];
            }
          });
        }
      });
    },

    deleteTime(index, field) {
      this.$confirm(
        "Sei sicuro di voler cancellare la seguente lavorazione?"
      ).then((res) => {
        if (res) {
          axios
            .post("time-delete/" + this.project.id + "/" + field + "/" + index)
            .then((response) => {
              if (response.data.field == "tracciato") {
                this.project.tracciato = response.data.data_time;
              } else if (response.data.field == "mastrino") {
                this.project.mastrino = response.data.data_time;
              }
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },

    deleteICAfiles: function (number) {
      this.$confirm("Sei sicuro di voler cancellare il file?").then((res) => {
        if (res) {
          axios
            .post(
              "delete-ica-file/" +
                this.project.id +
                "/" +
                number +
                "/" +
                this.esterno
            )
            .then((response) => {
              this.project.talk = response.data.timeline;
              this.project.ica_files = response.data.ica_files;
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },
    deleteCustomerfiles: function (number) {
      this.$confirm("Sei sicuro di voler cancellare il file?").then((res) => {
        if (res) {
          axios
            .post(
              "delete-customer-file/" +
                this.project.id +
                "/" +
                number +
                "/" +
                this.esterno
            )
            .then((response) => {
              this.project.talk = response.data.timeline;
              this.project.customer_files = response.data.customer_files;
              this.$store.dispatch("notificate", {
                text: response.data.msg,
                type: "success",
              });
            });
        }
      });
    },

    updateProject: function () {
      if (!this.project.customer || !this.project.name) {
        this.$store.dispatch("notificate", {
          text: "Inserisci cliente e nome progetto",
          type: "warning",
        });
        return;
      }
      const formData = new FormData();
      this.project.last_msg = this.current_msg;
      this.isLoading.saving = true;

      axios
        .post("project/" + this.project.id + "/" + this.esterno, {
          project: JSON.stringify(this.project),
        })
        .then((response) => {
          this.isLoading.saving = false;
          this.msgBox = false;
          this.are_dimensions = true;
          this.project = response.data.project;
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
          this.edited = false;
          this.dialog_priority = false;
          this.current_msg = "";
        });
    },

    answerRequest: function (which, when, what) {
      const formData = new FormData();

      formData.append("talk", what);
      formData.append("which", which);
      formData.append("when", when);

      axios.post("answer/" + this.project.id, formData).then((response) => {
        this.current_answer = "";

        this.$store.dispatch("notificate", {
          text: response.data.msg,
          type: "success",
        });
        this.edited = false;
        this.current_msg = "";
      });
    },

    deleteProject: function () {
      this.$confirm(
        "Sei sicuro di voler cancellare il seguente progetto?"
      ).then((res) => {
        if (res) {
          this.edited = false;
          axios
            .post("project/delete/" + this.project.id + "/" + this.esterno)
            .then((response) => {
              this.$store.dispatch("notificate", {
                text: response.data.msg.text,
                type: response.data.msg.type,
              });
              this.$router.push("/projects-queue").catch(() => {});
            });
        }
      });
    },

    openProject: function () {
      if (this.project.status != "in lavorazione") {
        axios
          .post("open/" + this.$route.params.id + "/" + this.choosenEsterno)
          .then((response) => {
            this.project.status = response.data.status;
            this.project.isEditing = response.data.is_editing;
            if (
              this.project.isEditing != this.user.username &&
              this.project.isEditing != ""
            ) {
              this.isDisabled = true;
            } else {
              this.isDisabled = false;
            }
          });
      }
    },
    closeProject: function () {
      this.hasScrolled = false;
      this.$store.state.project = {};
      axios
        .post("close/" + this.project.id + "/" + this.esterno)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    newCustomer: function () {
      const newCustomer_form = new FormData();
      newCustomer_form.append("new_name", this.new_name);
      newCustomer_form.append("new_address", this.new_address);
      newCustomer_form.append("new_city", this.new_city);
      newCustomer_form.append("new_cap", this.new_cap);
      newCustomer_form.append("new_province", this.new_province);
      newCustomer_form.append("new_country", this.new_country);
      newCustomer_form.append("new_phone", this.new_phone);
      newCustomer_form.append("new_email", this.new_email);
      newCustomer_form.append("new_ref", this.new_ref);
      newCustomer_form.append("new_site", this.new_site);

      axios
        .post("newcustomer", newCustomer_form, {
          withCredentials: true,
          crossDomain: true,
        })
        .then((response) => {
          this.project.customer = this.new_name;
          this.customers = response.data.customer_list;

          if (response.data.error) {
            this.$store.dispatch("notificate", {
              text: response.data.error,
              type: "warning",
            });
          }

          this.customer_name = this.new_name;
          this.$store.dispatch("notificate", {
            text: "Cliente creato correttamente",
            type: "success",
          });
        });

      this.dialog = false;
    },

    view: function () {
      if (this.project.status == "aperto" && this.canEdit == 0) {
        this.isDisabled = true;
      }

      if (this.project.status == "in lavorazione" && this.role != "master") {
        this.overlay = "overlay-lock";
      }
    },

    Task: function (tipo) {
      const field = "tracciato";
      const tracciato_form = new FormData();
      const get = tipo;
      this.getNow();

      if (get == "start") {
        this.start_time[field] = this.timestamp;
        this.project.status = "in lavorazione";

        tracciato_form.append("start", this.start_time[field]);
        this.sendWork({
          type: "start",
          customer: this.project.customer,
          rif: this.project.name,
          chat: this.project.talk[0],
          status: this.project.status,
          id: this.project.id,
        });
      } else {
        this.end_time[field] = this.timestamp;
        tracciato_form.append("end", this.end_time[field]);
        this.sendWork({
          type: "end",
          customer: this.project.customer,
          rif: this.project.name,
          chat: this.project.talk[0],
          status: "in attesa",
          id: this.project.id,
        });
      }

      axios
        .post("task/" + field + "/" + this.project.id, tracciato_form)

        .then((response) => {
          if (response.data.field == "tracciato") {
            this.project.tracciato = response.data.data_time;
          } else if (response.data.field == "mastrino") {
            this.project.mastrino = response.data.data_time;
          }

          try {
            this.start_time_tracciato =
              this.project.tracciato[
                this.project.tracciato.length - 1
              ].start_time;
            this.end_time_tracciato =
              this.project.tracciato[
                this.project.tracciato.length - 1
              ].end_time;
          } catch {}
          try {
            this.start_time_mastrino =
              this.project.mastrino[
                this.project.mastrino.length - 1
              ].start_time;
            this.end_time_mastrino =
              this.project.mastrino[this.project.mastrino.length - 1].end_time;
          } catch {}
        });
    },

    Completed: function () {
      console.log(this.esterno);
      this.isLoading.actions = true;
      axios
        .post("completed/" + this.project.id + "/" + this.esterno)
        .then((response) => {
          this.isLoading.actions = false;
          if (response.data.error) {
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "warning",
            });
          } else {
            this.$store.dispatch("notificate", {
              text: response.data.msg,
              type: "success",
            });
          }
        });
    },

    getColor(status) {
      if (status == 0) {
        return "red";
      } else if (status == 1) {
        return "#ffc412";
      } else if (status == 3) {
        return "blue";
      } else {
        return "green";
      }
    },

    getCurrentStatus(status) {
      if (status == 0) {
        return "in attesa di contabile";
      } else if (status == 1) {
        return "in attesa di bonifico";
      } else if (status == 3) {
        return "Posticipato";
      } else {
        return "Completato";
      }
    },

    getQuotationColor(status) {
      if (status == 0) {
        return "#474747";
      } else if (status == 1) {
        return "#ffc412";
      } else if (status == 2) {
        return "green";
      }
    },
    checkifRead(i) {
      if (i == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>


<style scoped>
.active-tab {
  background-color: #ddebeb;
  color: #2f2f2f;
}

.box-f {
  width: 100%;
}

.actions > button {
  margin: 5px;
}

.uncompleted {
  background-color: rgb(243, 255, 255) !important;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.wh {
  background-color: white;
  margin: 0;
}

.force-h {
  height: 100% !important;
}

.production-allegato {
  min-height: 200px;
}

.mg-5 {
  margin: 2px;
}
.mg-10-l {
  margin-left: 10px;
}

.block-answer {
  width: 100%;
  display: flex;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0efeb;
  align-items: center;
  border-radius: 200px;
  justify-content: space-around;
}

.block-answer-sent {
  width: 100%;
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(219, 241, 241);
  align-items: center;
  border-radius: 10px;
  justify-content: space-around;
  padding: 10px;
}

.answer {
  padding: 10px;
  width: 80%;
  margin-right: 50px;
  height: 100%;
}
.answer-sent {
  width: 100%;
  margin-right: 50px;
  height: 100%;
}

.center {
  text-align: center;
}

.center_y {
  display: flex;
  align-items: center;
}

#files-2 {
  margin-top: 10px;
}

.upload-icon {
  text-align: center;
}

.msg-text {
  width: 100%;
  white-space: nowrap;
}

.fix {
  width: auto;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  margin: -20px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 30px;
  background-color: rgb(47, 47, 47);
  color: white;
  z-index: 4;
}

.time-content {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}

.time-title {
  padding: 0 !important;
}

.loading {
  z-index: 9000;
}

#loading {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.212);
  z-index: 9000;
}

.shape {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.246);
}

.limit-height {
  max-height: 20%;
}

.content-row {
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.uploader::-webkit-scrollbar {
  width: 5px;
  z-index: 2000;
}

.uploader {
  -ms-overflow-style: 5px; /* IE and Edge */
  scrollbar-width: 5px; /* Firefox */
}

::-webkit-scrollbar {
  width: 2px;
  z-index: 4000;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.action-file {
  cursor: pointer;
}

.nopd {
  padding: 0px !important;
}

.allegato-row {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.185);
  background-color: white;
  flex: none;
  box-sizing: border-box;
  cursor: grab;
}

.allegato-row:hover {
  background-color: rgb(248, 248, 248);
}

.right {
  text-align: right;
}

.uploader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px;
}

#upload-false_ica-file,
#upload-false_customer-file,
#upload-false_prod-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.form-ica {
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.input-ica {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allegato-title {
  height: 20%;
  padding: 0px !important;
}

.form-allegato {
  height: 80%;
  padding: 0;
}

.v-tabs {
  height: 10%;
}

.v-item-group {
  height: 90%;
}
.v-item-group >>> .v-window__container,
.v-item-group >>> .v-window-item {
  height: 100%;
}

.v-window {
  overflow: inherit;
  background-color: transparent !important;
}

.tab-content {
  height: 90%;
}

.new-customer {
  cursor: pointer;
}

.new-customer:hover {
  background-color: #f0efeb;
  border-radius: 4px;
}

.overlay-lock {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.664);
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
}

.hide {
  display: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.input-block {
  border: 1px rgb(190, 190, 190) solid;
  width: 100%;
  border-radius: 4px;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 50%;
}

.input-data {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: "Open Sans", sans-serif !important;
}

.generic-info {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.chat-data {
  width: 25%;
  flex-basis: auto !important;
  height: 100%;
}

.data-info {
  width: 55%;
  flex-basis: auto !important;
  box-sizing: border-box;
  height: 100%;
}
p {
  padding: 0;
  margin: 0;
}

label {
  margin-right: 5px;
}

.actions {
  position: fixed;
  width: 20%;
  height: 75px;
  left: 40%;
  bottom: 5px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-action {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5px;
}

.icon-action {
  height: 100%;
  width: 100%;
}

.smaller {
  height: 40px !important;
  width: 40px !important;
}

.icon {
  width: 50px;
  height: 50px;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.chat {
  border: 1px solid rgb(204, 204, 204);
  height: 100%;
  border-radius: 20px;

  display: flex;
  flex-direction: column-reverse;
}
.chat-view {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  background-color: white;
  overflow-y: scroll;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.chat-view::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.chat-view-ciko {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  border-radius: 20px 20px 0px 0px;
}

.chat-view-ciko::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view-ciko {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.msg-time {
  margin-top: 20px;
  font-size: 12px;
  float: right;
  margin-bottom: 0 !important;
}

.chat-bar {
  height: 20%;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: inherit;
  background-color: #f0efeb;
  transition: 0.5s;
}
.send-msg {
  width: 10%;
  height: 100% !important;
  min-width: 50px !important;
}

.box_full_h {
  height: 600px !important;
  transition: 0.5s;
}

.block-msg {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 10px;
  white-space: pre-line;
  z-index: 2;
}

.msg {
  background-color: white;
  padding: 21px 20px 5px 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.viewer {
  float: right !important;
  background-color: rgb(255, 132, 110) !important;
  border-radius: 15px 0px 15px 15px !important;
}

.system {
  float: none !important;
  background-color: transparent !important;
  text-align: center;
  box-shadow: none;
}

.userlogo {
  text-transform: capitalize;
  color: rgb(240, 90, 97);
}

.file-preview {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  bottom: 0;
}

.file-preview-up {
  z-index: 250;
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  top: 0;
}

.allegato-row:hover .file-preview {
  visibility: visible;
}
.allegato-row:hover .file-preview-up {
  visibility: visible;
}
.dl-time {
  background: white;
}

.isediting {
  position: fixed;
  top: 12%;
  right: 2%;
}

.v-application .primary--text,
.blue--text.text--darken-1 {
  color: rgb(17, 121, 123) !important;
}

.mg-10 {
  margin-top: 10px;
}

.no-mg {
  margin: 0;
}

.unread-requests {
  display: none;
  position: absolute;
  top: -33%;
  width: 100%;
  text-align: center;
  font-weight: 800;
  left: 0;
  z-index: -200000;
  border-radius: 10px;
}

.unread-requests0 {
  display: block !important;
}

.supplier-quotation {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: rgb(241, 241, 241);
  margin-bottom: 7px;
  margin-top: 7px;
  cursor: default !important;
}

.quot {
  padding: 20px !important;
}
</style>
