<template>

    <v-dialog
      v-model="dialog.isVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{dialog.text}}
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="response = 0"
          >
            Annulla
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="response = 1"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>

export default {
    name: "ConfirmDialog",
    data(){
      return {
      }
    },
    computed: {
      dialog:{
        get(){
          return this.$store.getters.confirmDialog
        } 
      }
    },
    methods: {
     
    }
}

</script>

<style scoped>

</style>