<template>
  <div>
    <v-btn
      @click="isVisible = true"
      class="tw-ml-2 !tw-bg-custom-blue !tw-rounded-xl !tw-text-white"
      >Nuovo progetto</v-btn
    >
    <v-dialog v-model="isVisible" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Nuovo progetto</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="customersList && customersList.length > 0 && (esterno == 'Francia' || esterno == 'susni' || !esterno)" cols="10">
              <v-autocomplete
                class="tw-rounded-xl"
                type="text"
                flat
                outlined
                background-color="white"
                clearable
                icon="mdi-magnify"
                :items="customersList"
                v-model="customer"
                label="Cliente"
                hide-details=""
                item-text="name"
                item-value="name"
                :return-object="!esterno"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-else>
              <v-text-field
                class="tw-rounded-xl"
                flat
                hide-details
                outlined
                label="Nome cliente*"
                v-model="customer"
              ></v-text-field>
            </v-col>
            <v-col v-if="customersList && customersList.length > 0 && (esterno == 'Francia' || esterno == 'susni' || !esterno)" class="tw-items-center tw-flex" cols="2">
              <CustomerModal @emitNewCustomer="updateCustomers" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="tw-rounded-xl"
                flat
                hide-details
                outlined
                label="Nome progetto*"
                v-model="name"
              ></v-text-field>
              
            </v-col>
            <v-col v-if="!esterno" cols="12">
              <v-select
                class="tw-rounded-xl"
                flat
                outlined
                label="Operatore*"
                v-model="delegate"
                :items="operators"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isVisible = false">
            Annulla
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createProject"
            :loading="loading"
          >
            Crea
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseNotification />
  </div>
</template>

<script>
import axios from "../../../plugins/axios";
import CustomerModal from "../NewCustomerProject/CustomerModal.vue";

export default {
  components: { CustomerModal },
  name: "ProjectModal",
  props: {
    customers: Array,
    cta: String,
    operators: Array,
    esterno: String,
  },

  data() {
    return {
      customer: null,
      name: null,
      delegate: 'Marco',
      loading: false,
      isVisible: false,
      customersList: [],
    };
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  watch: {
    isVisible(val) {
      if (!val) {
        this.customer = null;
        this.name = null;
        this.customersList = [];
      }
      else this.customersList = this.customers
    },
  },
  methods: {
    updateCustomers(data) {
      this.customersList = data.customers;
      this.customer = data.customer;
    },
    validateData() {
      if (!this.customer || !this.name) {
        this.$store.dispatch("notificate", {
          text: "Compila tutti i campi",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    createProject() {
      if (!this.validateData()) return;
      this.loading = true;
      console.log("CLIENTE",this.customer)
      axios
      .post(`project/${this.esterno ? this.esterno: ''}`, { customer: this.customer, name: this.name, delegate: this.delegate })
        .then((response) => {
          this.$store.dispatch("notificate", {
            text: "Progetto creato con successo",
            type: "success",
          });
          this.$router.push({
            name: response.data.routeName,
            params: { id: response.data.id },
          }).catch(() => {})
        })
        .catch((err) => {
          console.log(err.response.data);
          //if status code is 400
          if (err.response.status == 400) {
            this.$store.dispatch("notificate", {
              text: err.response.data.message,
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>