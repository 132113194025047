<template>
  <v-row
    class="tw-box-border tw-p-4 tw-mt-2 tw-mb-2 tw-rounded-xl tw-bg-grey tw-w-full"
  >
    <div class="tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between tw-w-full tw-p-6 tw-text-lg">
      <div><b>{{ quotation.type == 'preventivi_stampa' ? 'Stampatore' : 'Fustellatore'}}: </b>{{ quotation.machine_supplier }}</div>
      <div><b>Materiale: </b>{{ quotation.tp }}</div>
      <div><b>Resa: </b>{{ quotation.resa }}</div>
    </div>
    <v-col
      v-if="quotation.suppliers && quotation.suppliers.length > 0"
      cols="12"
      class="nopd tw-mt-4"
    >
      <v-row
        v-for="(supplier, index3) in quotation.suppliers"
        :key="index3"
        class="tw-flex tw-my-2 tw-p-2 tw-px-4 tw-bg-white tw-rounded-xl tw-mx-auto tw-flex-col md:tw-flex-row"
        width="100%"
      >
        <div class="tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between tw-w-full tw-items-center">
            <div style="margin-right: 3px"><b>Fornitore: </b> {{ supplier.supplier }}</div>
            <div v-if="supplier.price" style="margin-right: 3px"><b>Prezzo: € </b>{{ supplier.price }}</div>
            <div >
                <SupplierModal
                :isActionEnabled="isActionEnabled"
                :_id="_id"
                :files="ica_files"
                :supplierIndex="index3"
                :quotationIndex="index"
                :quotation="quotation"
                :supplier="supplier"
                :_draftFiles="supplier.draftFiles ? supplier.draftFiles : []"
                action="next"
                />
            </div>
            <div>
                <SupplierModal
                :_id="_id"
                :files="ica_files"
                :supplierIndex="index3"
                :quotationIndex="index"
                :quotation="quotation"
                :supplier="supplier"
                :_draftFiles="supplier.draftFiles ? supplier.draftFiles : []"
                action="view"
                />
            </div>
        </div>
      </v-row>
    </v-col>
    <v-col class="!tw-p-0 !tw-justify-center !tw-flex" cols="12">
        <v-icon
          size="30"
          class="action-file"
          @click="deleteQuotation(index, quotationType)"
          color="red"
          v-if="user.role == 'commerciale'"
        >
          mdi-delete-circle
        </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import SupplierModal from "./supplierModal.vue";
import axios from "../../plugins/axios";
import { mapQuotationStatus } from '../../plugins/utils';

export default {
  
  name: "QuotationItem",
  props: ["quotation", "_id", "ica_files", "index", "quotationType", "isActionEnabled"],
  components: {
    SupplierModal,
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    _mapQuotationStatus(status) {
        return mapQuotationStatus(status)
    },
    deleteQuotation(i, type) {
      this.$confirm("Sei sicuro di voler cancellare il preventivo").then(
        (res) => {
          if (res) {
            axios
              .delete("quotation/" + this._id + "/" + this.quotation.salted_id + "/" + type)
              .then((response) => {
                this.$store.dispatch("notificate", {
                  text: response.data.msg,
                  type: "success",
                });
                this.$emit("deleted-quotation", {
                  index: this.index,
                  type: this.quotationType,
                });
              })

              .catch((err) => {
                //handle error
              })
              .finally(() => {
                //handle finally
              });
          }
        }
      );
    },
    getQuotationColor(status) {
      if (status == 0) {
        return "#474747";
      } else if (status == 1) {
        return "#ffc412";
      } else if (status == 2) {
        return "green";
      }
    },
  },
};
</script>