<template>
  <div
    class="tw-box-border tw-bg-azure tw-rounded-xl tw-px-4 tw-py-2 tw-mt-2 tw-flex tw-text-sm tw-w-96"
  >
    <div><b>Fornitore: </b> {{ item.supplier }}</div>
    <div v-if="canDelete" class="tw-flex tw-flex-1 tw-justify-end">
      <v-icon @click="deleteSupplier" class="hover:tw-opacity-80" color="red">mdi-delete</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "generatedSupplier",
  props: ["item","canDelete"],
  methods: {
    deleteSupplier() {
        this.$confirm(
            `Sei sicuro di voler eliminare ${this.item.supplier} dai fornitori ? <br>Verrà escluso dall'elenco dei preventivi.`,
        )
        .then((res) => {
            if (res) {
                this.$emit('deletedSupplier',this.item)
            }
        })
    }
  }
}

</script>