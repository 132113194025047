<template>
  <div>
    <v-icon @click="dialogue = true" color="blue"> mdi-pencil </v-icon>
    <v-dialog v-model="dialogue" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"><b>{{ supplierData.name }}</b></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field placeholder="Inserisci email" label="Email" outlined v-model="email"
                :rules="emailRules"></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="tw-font-semibold tw-text-sm">Fustella</div>
              <v-select :items="filteredFustella" item-text="username" item-value="id" label="Aggiungi Fustella"
                v-model="selectedFustella" multiple outlined></v-select>
            </v-col>
            <v-col cols="12">
              <div class="tw-font-semibold tw-text-sm">Impianti Stampa</div>
              <v-select :items="filteredStampa" item-text="username" item-value="id" label="Aggiungi Impianto Stampa"
                v-model="selectedImpiantoStampa" multiple outlined></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogue = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="updateSupplier">
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "EditSupplierForm",
  props: {
    availableSuppliers: {
      type: Array,
      required: true,
    },
    supplierData: {
      type: Object,
      default: () => ({
        name: "",
        id: null,
        email: "",
        _id: "",
      }),
    },
    selectedSuppliers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      email: this.supplierData.email,
      selectedFustella: [],
      selectedImpiantoStampa: [],
      dialogue: false,
    };
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || "L'email è obbligatoria",
        (v) => /.+@.+\..+/.test(v) || "L'email non è valida",
      ];
    },
    filteredFustella() {
      return this.availableSuppliers.filter(
        (supplier) => supplier.supplier_type === "preventivi_fustella"
      );
    },
    filteredStampa() {
      return this.availableSuppliers.filter(
        (supplier) => supplier.supplier_type === "preventivi_stampa"
      );
    },
  },
  methods: {
    updateSupplier() {

      const payload = {
        email: this.email,
        suppliers: [...this.selectedFustella, ...this.selectedImpiantoStampa],
      };
      axios
        .patch(`admin/supplier/${this.supplierData._id}`, payload)
        .then((response) => {
          this.$emit("update-success");
          this.$store.dispatch("notificate", {
            text: 'Fornitore aggiornato con successo',
            type: 'success',
          });
          this.dialogue = false;
        });
    },

  },
  watch: {
    supplierData: {
      handler(newValue) {
        this.email = newValue.email;

        this.selectedFustella = newValue.suppliers
          .filter((supplier) => supplier.supplier_type === "preventivi_fustella")
          .map((supplier) => supplier.id);

        this.selectedImpiantoStampa = newValue.suppliers
          .filter((supplier) => supplier.supplier_type === "preventivi_stampa")
          .map((supplier) => supplier.id);

        this.dialogue = newValue.dialogue;
      },
      deep: true,
      immediate: true,
    },
  },


};
</script>

<style scoped></style>