<template>
  <v-dialog
    v-model="isVisible"
    content-class="dl-time"
    scrollable
    max-height="600px"
    width="800px"
  >
    <v-card class="tw-h-full">
      <!-- RENDERIZZA BCT -->
      <form
        class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 bct-form"
      >
        <div class="tw-flex tw-w-full tw-items-center">
          <div class="tw-text-xl tw-font-bold">Nuovo Calcolo</div>
        </div>
        <div class="tw-flex tw-flex-col tw-w-full tw-my-8">
          <div class="tw-text-lg tw-font-semibold tw-mb-2">Legenda</div>
          <div class="tw-flex tw-items-center tw-mt-2">
            <div>
              <div
                class="tw-w-full md:!tw-w-4 !tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mr-4"
              ></div>
            </div>
            <div class="text-md">
              <b>ECT</b> = sforzo necessario per deformare la striscia di
              cartone
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-2">
            <div>
              <div
                class="tw-w-full md:!tw-w-4 !tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mr-4"
              ></div>
            </div>
            <div class="text-md">
              <b>Spessore</b> = spessore del cartone espresso in mm
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-2">
            <div>
              <div
                class="tw-w-full md:!tw-w-4 !tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mr-4"
              ></div>
            </div>
            <div class="text-md">
              <b>Perimetro</b> = perimetro della scatola espresso in cm
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-2">
            <div>
              <div
                class="tw-w-full md:!tw-w-4 !tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mr-4"
              ></div>
            </div>
            <div class="text-md">
              <b>Fustellatura = </b> da spuntare se sono presenti maniglie,
              perforatore o altri tipi di fustellatura che riducono la
              robustezza della scatola
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-2 tw-mb-4">
            <div>
              <div
                class="tw-w-full md:!tw-w-4 !tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mr-4"
              ></div>
            </div>
            <div class="text-md">
              <b>BCT dinamico = </b> BCT statico * 3 o 5
            </div>
          </div>
        </div>
        <div class="tw-flex-col tw-w-full tw-mb-2">
          <div class="tw-ml-2">Cosa vuoi calcolare?</div>
          <div class="tw-flex-1 tw-ml-4">
            <v-radio-group v-model="bct_model.target">
              <v-radio
                v-for="item in ['BCT', 'ECT']"
                :key="item"
                :label="`${item}`"
                :value="item"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-w-full md:tw-w-1/2 tw-mb-2">
          <div v-if="bct_model.target == 'BCT'" class="tw-ml-2">ECT</div>
          <div v-else class="tw-ml-2">BCT Dinamico</div>
          <div class="tw-flex-1 tw-ml-4">
            <input-field
              v-if="bct_model.target == 'BCT'"
              :darker="true"
              class="tw-w-full"
              type="number"
              v-model="bct_model.ect_inpt"
              required
              step="any"
            ></input-field>
            <input-field
              v-else
              :darker="true"
              class="tw-w-full"
              type="number"
              v-model="bct_model.bct_inpt"
              required
              step="any"
            ></input-field>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-w-full md:tw-w-1/2 tw-mb-2">
          <div class="tw-ml-4">Spessore cartone (mm)</div>
          <div class="tw-flex-1 tw-ml-4">
            <input-field
              :darker="true"
              class="tw-w-full"
              type="number"
              v-model="bct_model.thickness"
              required
              step="any"
            ></input-field>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-w-full md:tw-w-1/2 tw-mb-2">
          <div class="tw-ml-2">Perimetro (cm)</div>
          <div class="tw-flex-1 tw-ml-4">
            <input-field
              :darker="true"
              class="tw-w-full"
              type="number"
              v-model="bct_model.perimeter"
              required
              step="any"
            ></input-field>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-w-full md:tw-w-1/2 tw-mb-6">
          <div class="tw-flex-1 tw-ml-4 tw-flex tw-items-center">
            <v-checkbox
              class="!tw-m-0"
              label="Fustellatura"
              v-model="bct_model.die_cut"
              hide-details
            ></v-checkbox>
          </div>
        </div>
        <div
          class="tw-w-full tw-mt-4 tw-p-4 tw-bg-grey tw-rounded-lg"
          v-if="
            bct_model.target == 'BCT' &&
            bct_model.bct.static &&
            bct_model.bct.dynamic.standard &&
            bct_model.bct.dynamic.extraordinary
          "
        >
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-xl tw-mb-2">
              <b>BCT calcolato: </b>
            </div>
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT statico: </b> {{ bct_model.bct.static }}
            </div>
            <div class="tw-w-full">
              <v-divider color="black" class="tw-my-2"></v-divider>
            </div>
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT dinamico standard (/3): </b>
              {{ bct_model.bct.dynamic.standard }}
            </div>
            <div class="tw-text-lg tw-ml-2">
              <b>- BCT dinamico straordinario (/5): </b>
              {{ bct_model.bct.dynamic.extraordinary }}
            </div>
          </div>
        </div>
        <div
          class="tw-w-full tw-mt-4 tw-p-4 tw-bg-grey tw-rounded-lg"
          v-if="
            bct_model.target == 'ECT' &&
            bct_model.ect.static &&
            bct_model.ect.dynamic.standard &&
            bct_model.ect.dynamic.extraordinary
          "
        >
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-xl tw-mb-2">
              <b>ECT calcolato: </b>
            </div>
            <div class="tw-text-lg tw-ml-2">
              {{ bct_model.ect.static }}
            </div>
          </div>
        </div>
        <div class="tw-w-full tw-mt-8 tw-flex">
          <primary-btn
            :text="'Calcola ' + bct_model.target"
            @click.native="calcBCT(bct_model.target)"
          ></primary-btn>
          <secondary-btn
            text="Cancella campi"
            @click.native="clearBCT"
            class="tw-ml-4"
          ></secondary-btn>
        </div>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.bctModal = false"
        >
          Annulla
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          @click="saveBCT"
          v-if="hasResult"
        >
          Salva
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios";
import PrimaryBtn from "../ui/PrimaryBtn.vue";

export default {
  components: { PrimaryBtn },
  name: "BctModal",
  props: ["esterno", "id_project"],
  data() {
    return {
      hasResult: false,
      isLoading: false,
      bct_model: {
        target: "BCT",
        die_cut: false,
        k: 1.82,
        ect_inpt: null,
        bct_inpt: null,
        thickness: null,
        perimeter: null,
        ect: {
          static: null,
          dynamic: {
            standard: null,
            extraordinary: null,
          },
        },
        bct: {
          static: null,
          dynamic: {
            standard: null,
            extraordinary: null,
          },
        },
      },
      formData: new FormData(),
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.$store.state.bctModal;
      },
      set() {},
    },
  },
  methods: {
    checkForm() {
      var forms = document.getElementsByClassName("bct-form");
      for (var i = 0; i < forms.length; i++) {
        if (!forms[i].reportValidity()) {
          return false;
        }
      }
      return true;
    },
    closeModal() {
      this.$store.state.bctModal = false;
    },
    clearBCT() {
      this.bct_model.ect_inpt = null;
      this.bct_model.thickness = null;
      this.bct_model.perimeter = null;
      this.bct_model.bct_inpt = null;
      this.bct_model.bct.static = null;
      this.bct_model.bct.dynamic.standard = null;
      this.bct_model.bct.dynamic.extraordinary = null;
      this.bct_model.ect.static = null;
    },
    calcBCT(target) {
      let result;
      if (this.checkForm("bct-form")) {
        this.bct_model.target = target;
        if (target == "BCT") {
          result =
            this.bct_model.k *
            this.bct_model.ect_inpt *
            Math.sqrt(this.bct_model.thickness) *
            Math.sqrt(this.bct_model.perimeter);
          if (this.bct_model.die_cut) {
            result = result - result * 0.1;
          }
          this.bct_model.bct.static =
            Math.round((result + Number.EPSILON) * 100) / 100;
          this.bct_model.bct.dynamic.standard =
            Math.round(((result + Number.EPSILON) * 100) / 100) * 3;
          this.bct_model.bct.dynamic.extraordinary =
            Math.round(((result + Number.EPSILON) * 100) / 100) * 5;
        } else {
          result =
            this.bct_model.bct_inpt /
            (this.bct_model.k *
              Math.sqrt(this.bct_model.thickness) *
              Math.sqrt(this.bct_model.perimeter));
          if (this.bct_model.die_cut) {
            result = result + result * 0.1;
          }
          this.bct_model.ect.static =
            Math.round((result + Number.EPSILON) * 100) / 100;
          this.bct_model.ect.dynamic.standard =
            Math.round(((result + Number.EPSILON) * 100) / 100) * 3;
          this.bct_model.ect.dynamic.extraordinary =
            Math.round(((result + Number.EPSILON) * 100) / 100) * 5;
        }
        this.hasResult = true;
        this.$store.dispatch("notificate", {
          text: target + " calcolato correttamente",
          type: "success",
        });
      }
    },
    saveBCT() {
      let data = this.bct_model;
      data.project_id = this.id_project;
      data.esterno = this.esterno;
      if (this.checkForm("bct-form")) {
        console.log(this.id_project);
        axios
          .post("bct/", { bct_model: JSON.stringify(data) })
          .then((response) => {
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
            this.$store.state.project.bct = response.data.bct;
            this.$store.state.bctModal = false;
          });
      }
    },
  },
};
</script>