<template>


  <v-container
    fluid
  >
  <v-row width="100%">
    <v-col cols="12">
      <v-card elevation="0" class="tw-w-full !tw-bg-grey !tw-border-0">
        <v-row v-if="settings" class="no-pd">
          <div class="tw-w-full tw-bg-white tw-rounded-lg">
            <v-card-title style="padding: 12px" >Notifiche</v-card-title>
            <v-col class="d-flex tw-items-center" cols="12">
                <p class="info-set">Mostra le notifiche sul desktop</p>
                <v-switch
                  v-model="settings.desktop_notifications"
                >
                </v-switch>
            </v-col>
          </div>
          <!-- <div class="tw-w-full tw-bg-white tw-rounded-lg tw-mt-4">
            <v-card-title style="padding: 12px" >Impostazioni Progetto</v-card-title>
            <v-col class="d-flex align-center" cols="12">
                <p class="info-set">Chiusura automatica del progetto dopo inattività</p>
                <v-switch
                  v-model="settings.project_closer.is_active"
                >
                </v-switch>
                <span v-if="settings.project_closer.is_active">
                  <v-text-field
                  label="Imposta Timeout (minuti)"
                  type="number"
                  background-color="white"
                  v-model="timeout"
                  ></v-text-field>
                </span>
            </v-col>
          </div> -->
          <v-col cols="12" class="tw-p-0 tw-mt-6">
            <v-card-actions class="no-pd">
              <primary-btn @click.native="saveSettings" :loading="loading" text="Salva Impostazioni"></primary-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>

  <v-alert
    elevation="7"
    :type="response_type"
    :value="success"
    class="onsuccess"

  >
  {{ success_text }}
  </v-alert>
  
  </v-container>

</template>

<script>
import axios from 'axios'
import FileCompareTool from '../components/FilesComponents/FileCompareTool.vue'

  export default {
  //components: { FileCompareTool },
    name: 'Settings', 

    data (){
      return {
        loading: false,
        success_text: "",
        response_type: 'success',
        success: false,
        settings: {
          desktop_notifications: 0,
          project_closer: {
            is_active: 0,
            timeout: 1200000
          }
        }
        }
      },
    computed: {
      timeout:{
        get(){
          return this.settings.project_closer.timeout / 60000
        },
        set(newTimeout){
          this.settings.project_closer.timeout = newTimeout * 60000
        }
        
      }
    },

    mounted(){

       axios.get("settings")
        .then((response) => {
          this.settings = response.data.settings

        })  
    },

    methods: {
      displayMessage: function(text,type){

        this.response_type = type
        this.success_text = text
        this.success = true
        setTimeout(() => this.success = false, 1800);      


      },
      saveSettings(){
        this.loading = true
        axios.post("settings", {'settings':JSON.stringify(this.settings)})
        .then((response) => {
          this.displayMessage("Impostazioni salvate con successo", "success")
          this.loading = false

        }) 
      }

    },

}
  
  
</script>

<style scoped>

.info-set {
  margin-bottom: 0px !important;
  margin-right: 10px;
}

.v-input__control {
  width: 2px !important;
}

.onsuccess, .testolo {
  

  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}


  .capitalizer {

    text-transform:capitalize;
  }

  .full-height {

    height: 100%;

  }

  .elevation-1 {

    width: 100%;
    box-sizing: border-box;
    
  }
  
  .item {

    cursor: pointer;
  }

  .fixed-actions{
    position: fixed;
    bottom: 40px;
    right: 48.5%;
  } 

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {
  
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}



</style>
