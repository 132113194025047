<template>
    <v-btn :loading="loading" v-if="!square" :disabled=disabled :class="'!tw-shadow-md font2 primary-bg-color tw-px-4 tw-py-2 tw-rounded-xl tw-font-semibold hover:tw-opacity-80 tw-tracking-widest ' + (block ? 'tw-w-full' : '')">{{text}}
        <span v-if="icon"><v-icon>{{icon}}</v-icon></span>
    </v-btn>    
    <v-btn :loading="loading" icon v-else :disabled=disabled class="!tw-shadow-md font2 primary-bg-color tw-rounded-xl tw-font-semibold hover:tw-opacity-80 tw-tracking-widest">{{text}}
        <v-icon size="20">{{icon}}</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "PrimaryBtn",
    props:["text","disabled","icon","square","loading","block"]
}
</script>