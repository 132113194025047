import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios' 

Vue.use(Vuex)

//const url = "http://localhost:5000/user"
//const headers = {Accept: "application/json"}

const store = new Vuex.Store({
    state: {
      updateModal: false,
      bctModal: false,
      newUserDialogue: false,
      arProjectModal: false,
      focus_mode: 0,
      requested_data:'',
      projects: [],
      unlockedProject: null,
      projects_esterno: [],
      project: {
        progettazione_tenica:{},
        progettazione_grafica:{},
        tracciato: [],
        mastrino: [],
        campionatura:[],
        preventivi_fustella: [],
        preventivi_stampa: [],
        preventivi_scatola: [],
        talk: [],
        prod_files: [],
        payments: [],
        missing_informations: {},
        bct: {},
        ar_projects: [],

      },
      confirmDialog: {
        isVisible: false,
        text: "",
        options: ["Annulla","Conferma"]
      },
      notification: {
        isVisible: false,
        type: "success",
        text: "",
        timeout: 1500
      },
      currentUser: {
        isAuth : false,
        settings: {"desktop_notifications":1,"notification":1,"project_closer":{"is_active":false,"timeout":1200000}},
      },
      
      editSupplierDialogue: {
        dialogue: false,
        data: {
          email: "",
          name: "",
          fustella: [],
          impianto_stampa: []
        } 
      },
      
    },
    mutations: {
      fetchProject(state, payload){
        state.project = payload
      },
      toggleArModal(state){
        state.arProjectModal = !state.arProjectModal
      },
      setProject(state,payload){
        state.project = payload
      },
      setUnlockedProject(state,payload){
        state.unlockedProject = payload
      },
      setProjects(state,payload){
        state.projects = payload
      },
      setEsternoProjects(state,payload){
        state.projects_esterno = payload
      },
      setuser(state, payload) {
        state.currentUser = payload
        state.currentUser.isAuth = true
        state.focus_mode = payload.focus_mode
      },
      unsetuser(state){
        state.currentUser = { isAuth : false }
      },
      unsetdata(state){
        state.projects = []
        state.projects_esterno = []
        state.currentUser = { isAuth : false }
      },
      compileSupplierDialogue(state, payload){
        state.editSupplierDialogue = payload
      },
      openUserDialog(state){
        state.newUserDialogue = true
      },
      addMachineSupplier(state, payload){
        if (payload == "f"){
          state.editSupplierDialogue.data.fustella.push("")
        }               
        else {
          state.editSupplierDialogue.data.impianto_stampa.push("")
        }               
      },
      notificate(state, payload){
        if (!state.notification.isVisible){
          state.notification.isVisible = true
        }
        state.notification.type = payload.type
        state.notification.text = payload.text
        setTimeout(() => {
          state.notification.isVisible = false
        },state.notification.timeout)
        
      },
      askConfirm(state,payload){
        if (!state.confirmDialog.isVisible){
          state.confirmDialog.isVisible = true
          
        }
        state.confirmDialog.text = payload   
      },
      confirmResponse(payload){
        return payload
      },
    },
    getters:{
      getProject(state){
        return state.project
      },
      getCurrentUser(state) {
        return state.currentUser
      },
      auth(state){
        return state.currentUser.isAuth
      },
      getSupplierData(state){
        return state.editSupplierDialogue
      },
      getNotification(state){
        return state.notification
      },
      confirmDialog(state){
        return state.confirmDialog
      },
      newUserDialog(state){
        return state.newUserDialogue
      },
      getProjects(state){
        return state.projects
      },
      getEsternoProjects(state){
        return state.projects_esterno
      }
      

    },
    actions: {
      fetchProject({commit}, payload){
        axios.get(`/project/${payload}`).then(res => {
          res.data.project.suppliers = res.data.suppliers
          res.data.project.canEdit = res.data.canEdit
          res.data.project.bct = JSON.parse(res.data.project.bct)
          res.data.project.ar_projects = JSON.parse(res.data.project.ar_projects)
          commit('fetchProject', res.data.project)
        }
        ).catch(err => {
          console.log(err)
        },)
      },
      toggleArModal({commit}){
        commit('toggleArModal')
      },
      setProject({commit},payload){
        commit('setProject',payload)
      },
      setUnlockedProject({commit},payload){
        commit('setUnlockedProject',payload)
      },
      setProjects({commit},payload){
          commit('setProjects',payload)
        
      },
      setEsternoProjects({commit},payload){
          commit('setEsternoProjects',payload)
        
      },
      setuser({commit}, payload) {
        commit('setuser',payload)
          
      },
      unsetuser({commit}){
        commit('unsetuser')
      },
      unsetdata({commit}){
        commit('unsetdata')
      },
      compileSupplierDialogue({commit}, payload){
        commit('compileSupplierDialogue', payload)
      },
      addMachineSupplier({commit}, payload){
        commit('addMachineSupplier', payload)
        
      },
      notificate({commit}, payload){
        commit('notificate', payload)
      },
      async askConfirm({commit, dispatch},payload){
        commit('askConfirm',payload)
        await Promise.resolve(commit('confirmResponse',payload)) 
        .then((res)=>{
          return res
        })

      },
      openUserDialog({commit}){
        commit('openUserDialog')
      }

    }
  })

  export default store;