import EventBus from "./eventBus";
import store from "../store";

export default {
  install(Vue, options) {
    let socket;
    const connect = () => {
      socket = new WebSocket(options.connection);

      socket.onopen = () => console.log('WebSocket is connected.');
      socket.onmessage = (event) => {
        const receivedData = JSON.parse(event.data);
        const user = store.state.currentUser.username;
        if (receivedData.targets[0] == "all") {
          EventBus.$emit('data-received', receivedData);
        }
        else if (receivedData.targets && receivedData.targets.includes(user)) {
          EventBus.$emit('data-received', receivedData);
        }
      };
      socket.onerror = (error) => console.error('WebSocket Error:', error);
      socket.onclose = (event) => {
        if (event.wasClean) {
          console.log(`Closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
          // connection died, try to reconnect
          console.error('Connection died');
          setTimeout(connect, 5000); // try to reconnect in 5 seconds
        }
      };
    };

    connect(); // initial connection

    Vue.prototype.$socket = socket;
  },
};
