<template>
  <v-container fluid class="tw-p-6 tw-pt-20">
    <v-row>
      <v-col col="12" class="!tw-p-0 tw-items-center tw-mb-4">
        <div
          class="tw-flex md:tw-items-center md:tw-flex-row tw-flex-col tw-w-full"
        >
          <div
            class="tw-flex md:tw-w-auto tw-w-full tw-justify-between md:tw-justify-start !tw-mb-4 md:tw-mb-0"
          >
            <h1
              v-if="choosenEsterno"
              class="tw-text-3xl tw-font-bold tw-mb-2 md:tw-mb-0"
            >
              Progetti
              {{
                choosenEsterno.charAt(0).toUpperCase() +
                choosenEsterno.slice(1).replace(/_/g, " ")
              }}
            </h1>
            <div class="md:tw-hidden">
              <ProjectModal
                cta="Nuovo"
                :esterno="choosenEsterno"
                :operators="
                  choosenEsterno == 'Francia' ? ['Chiara'] : ['Monica']
                "
                :customers="customers"
                v-if="user.role == 'esterno'"
              />
            </div>
          </div>
          <v-autocomplete
            class="md:!tw-ml-6 tw-rounded-xl"
            type="text"
            flat
            outlined
            background-color="white"
            clearable
            icon="mdi-magnify"
            :items="customers"
            v-model="selectedCustomer"
            :placeholder="'Filtra per cliente'"
            label="Filtra per cliente"
            hide-details=""
            item-text="name"
            @change="getProjects"
          ></v-autocomplete>
          <v-text-field
            flat
            outlined
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca nei progetti "
            class="md:!tw-ml-2 tw-rounded-xl"
            background-color="white"
            hide-details
          ></v-text-field>
          <div
            v-if="user.role != 'esterno'"
            class="md:tw-ml-6 tw-flex tw-flex-col md:tw-w-64 tw-w-full md:tw-my-0 tw-my-4"
          >
            <v-select
              class="md:!tw-ml-6 tw-rounded-xl"
              placeholder="Seleziona il collaboratore"
              :items="collaborators"
              flat
              outlined
              hide-details=""
              background-color="white"
              v-model="choosenEsterno"
            />
          </div>
          <div class="tw-flex tw-ml-4">
            <v-menu
              offset-y
              transition="slide-y-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#11797b" v-bind="attrs" v-on="on" size="auto"
                  >mdi-filter-menu</v-icon
                >
              </template>

              <v-card class="elevation-0" style="padding: 20px">
                <v-checkbox
                  v-model="filters.by_ref"
                  label="Visualizza solo i tuoi progetti"
                  @change="setFilters"
                ></v-checkbox>
              </v-card>
            </v-menu>
            <div class="md:tw-block tw-hidden">
              <ProjectModal
                cta="Nuovo"
                :esterno="choosenEsterno"
                :operators="
                  choosenEsterno == 'Francia' ? ['Chiara'] : ['Monica']
                "
                :customers="customers"
                v-if="user.role == 'esterno'"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-skeleton-loader
        :loading="loading"
        transition="fade-transition"
        class="dt"
        height="500"
        type="table"
      >
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="filtered"
          loading="true"
          loading-text="Sto caricando..."
          :items-per-page="itemsPerPage"
          class="elevation-1 item dt font2 !tw-rounded-lg projects tw-w-full"
          height="100%"
          @click:row="goTo"
          @page-count="pageCount = $event"
          :page.sync="page"
        >
          <template v-slot:item.is_editing="{ item }">
            <v-chip
              color="transparent"
              class="capitalizer"
              v-if="item.is_editing"
            >
              {{ item.is_editing }}
            </v-chip>
          </template>

          <template v-slot:item.customer="{ item }">
            <td style="text-transform: uppercase">
              {{ item.customer }}
            </td>
          </template>

          <template v-slot:item.name="{ item }">
            <td style="text-transform: uppercase">
              {{ item.name }}
            </td>
          </template>

          <template v-slot:item.last_status="{ item }">
            <v-chip
              v-if="
                item.last_status != 'completato' &&
                item.status != 'in lavorazione' &&
                !item.missing_informations.are_missing
              "
              :color="getColor(item.last_status)"
              dark
              class="capitalizer"
            >
              {{ item.last_status }}
            </v-chip>
            <v-chip
              v-else-if="
                getQuotationStatus(item.preventivi_fustella).status ==
                  'In Attesa di Prev' ||
                getQuotationStatus(item.preventivi_stampa).status ==
                  'In Attesa di Prev'
              "
              color="blue"
              dark
              class="capitalizer"
            >
              Preventivo
            </v-chip>
            <v-chip
              v-else-if="item.status == 'in lavorazione'"
              :color="'red'"
              dark
              class="capitalizer"
            >
              In Lavorazione
            </v-chip>
            <v-chip
              v-else-if="item.missing_informations.are_missing"
              color="lightgrey"
              dark
              class="capitalizer"
            >
              Info mancanti
            </v-chip>
            <v-chip v-else color="#4cb946" dark class="capitalizer">
              {{ item.last_status }}
            </v-chip>
          </template>

          <template v-slot:item.preventivi_fustella="{ item }">
            <td dark class="capitalizer">
              <v-badge
                v-if="item.preventivi_fustella.length > 0"
                content="F"
                class="bigger-badge"
                :color="getQuotationStatus(item.preventivi_fustella).color"
              ></v-badge>
            </td>
          </template>
          <template v-slot:item.preventivi_stampa="{ item }">
            <td dark class="capitalizer">
              <v-badge
                v-if="item.preventivi_stampa.length > 0"
                content="IS"
                class="bigger-badge"
                :color="getQuotationStatus(item.preventivi_stampa).color"
              ></v-badge>
            </td>
          </template>
          <template v-slot:item.synch_status="{ item }">
            <v-tooltip v-if="item.synch_status" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="getpStatus(item.synch_status)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  fab
                  class="elevation-0"
                  width="20px"
                  height="20px"
                >
                </v-btn>
              </template>
              <span class="capitalizer">{{ item.synch_status }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.production_status="{ item }">
            <v-tooltip v-if="item.production_status" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="getpStatus(item.production_status)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  fab
                  class="elevation-0"
                  width="20px"
                  height="20px"
                >
                  P
                </v-btn>
              </template>
              <span class="capitalizer">{{ item.production_status }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.externalAssociation="{ item }">
            <div
              v-if="getExternalAssociationLink(item)"
              dark
              class="capitalizer"
            >
              <AssociatedButton :link="getExternalAssociationLink(item)" />
            </div>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <div class="text-center pt-2">
        <v-pagination
          color="#11797b"
          class="pag"
          v-model="page"
          :length="totalPages"
        ></v-pagination>
      </div>
    </v-row>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#11797b"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import ProjectModal from "../components/ProjectComponents/NewProjectModal/ProjectModal.vue";
import AssociatedButton from "../components/ProjectComponents/AssociatedButton/AssociatedButton.vue";

export default {
  name: "ProjectsEsterno",

  components: {
    ProjectModal,
    AssociatedButton,
  },

  data() {
    return {
      selectedCustomer: null,
      total_count: 0,
      timeoutId: null,
      customers: [],
      loading: false,
      choosenEsterno: "",
      collaborators: [],
      filters: {
        by_ref: false,
        by_delegate: false,
      },
      is_disabled: false,
      timeout: 7000,
      fab: false,
      role: "",
      page: 1,
      itemsPerPage: 100,
      pageCount: 0,
      scrollPosition: 0,
      search: "",

      pagination: {
        rowsPerPage: 50,
        descending: false,
        page: 1,
      },
    };
  },
  created: function () {
    this.loading = true;
    const memoryEsterno = localStorage.getItem("choosen_esterno");
    const memoryCustomer = localStorage.getItem("customer_esterno");
    console.log(memoryCustomer)
    if (memoryCustomer && memoryCustomer != "undefined" && memoryCustomer != 'null' ) this.selectedCustomer = memoryCustomer;
    else {
      this.selectedCustomer = ''
    }
    if (this.user.role == "commerciale") {
      this.collaborators = this.user.collaborators;
      if (memoryEsterno && memoryEsterno != "undefined")
        this.choosenEsterno = memoryEsterno;
      else {
        if (this.collaborators.length > 0 && this.user.esterno) {
          this.choosenEsterno = this.user.esterno;
        } else {
          this.choosenEsterno = this.collaborators[0];
        }
      }
    } else {
      this.choosenEsterno = this.user.esterno;
    }
    this.getProjects();
  },
  watch: {
    search(newV, oldV) {
      let debounceTimer;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        if (newV != oldV) {
          this.loading = true;
          this.getProjects();
        }
      }, 600); // Delay execution by 300 milliseconds
    },
    page(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.getProjects();
      }
    },
    choosenEsterno(newv, oldv) {
      this.loading = true;
      if (newv != oldv) {
        if (oldv && oldv != "undefined") this.selectedCustomer = null;
        this.search = "";
        localStorage.setItem("choosen_esterno", newv);
        this.getProjects();
      }
    },
    selectedCustomer(newV, oldV) {
      this.loading = true;
      if (newV != oldV) {
        localStorage.setItem("customer_esterno", newV);
        this.getProjects();
      }
      
    },
    projects() {
      if (this.timeoutId) {
        clearInterval(this.timeoutId);
      }
      this.timeoutId = setInterval(() => this.getProjects(), this.timeout);
    },
  },

  computed: {
    headers() {
      let baseHeaders = [
        { text: "ID", value: "id", sortable: true, filterable: false },
        {
          text: "Cliente",
          value: "customer",
          sortable: false,
          filterable: true,
        },
        {
          text: "Riferimento Progetto",
          value: "name",
          sortable: false,
          filterable: true,
        },
        {
          text: "Aperto da",
          value: "is_editing",
          sortable: false,
          filterable: false,
        },
        {
          text: "Stato",
          value: "last_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Progettazione",
          value: "synch_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Produzione",
          value: "production_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Corrispondente",
          value: "referrer",
          sortable: false,
          filterable: false,
        },
        {
          text: "Ultima richiesta",
          value: "last_request",
          sortable: false,
          filterable: false,
        },
        {
          text: "Data di creazione",
          value: "time",
          sortable: false,
          filterable: false,
        },
      ];

      if (this.user.role != "esterno") {
        baseHeaders.push({
          text: "Progetto trasferito",
          value: "externalAssociation",
          sortable: false,
          filterable: false,
        });
      }

      return baseHeaders;
    },
    totalPages() {
      return Math.ceil(this.total_count / this.itemsPerPage);
    },
    projects: {
      get() {
        return this.$store.getters.getEsternoProjects;
      },
      set() {
        return this.$store.getters.getEsternoProjects;
      },
    },
    filtered() {
      let items = [];

      if (this.filters.by_ref) {
        items = this.projects.filter(
          (project) => project.referrer == this.user.username
        );
      } else if (this.filters.by_delegate) {
        items = this.projects.filter(
          (project) => project.delegate == this.user.username
        );
      } else {
        items = this.projects;
      }
      return items;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },

  mounted: function () {
    this.filters = this.user.filters;
    window.addEventListener("scroll", this.updateScroll);
  },

  updated: function () {
    window.scrollTo(0, parseInt(this.scrollPosition));
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    getExternalAssociationLink(project) {
      let link = null;
      if (this.user.role != "esterno") {
        if (project._id_associato) {
          link = `/project/${project._id_associato}`;
        }
      }
      return link;
    },
    setFilters() {
      axios
        .post("/set-filters/", { filters: JSON.stringify(this.filters) })
        .then((response) => {
          this.filters = response.data.filters;
          this.$store.state.currentUser.filters = response.data.filters;
        });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    fixWatcher() {
      if (this.sorting == ["status", "in attesa"]) {
        this.sorting = ["status", "completed"];
      } else {
        this.sorting = ["status", "in attesa"];
      }
    },

    newProject() {
      axios.post("newproject/" + this.user.esterno).then((response) => {
        this.is_disabled = true;

        this.$router
          .push({
            name: "progettoEsterno",
            params: {
              id: response.data.id,
              choosenEsterno: this.choosenEsterno,
            },
          })
          .catch(() => {});
        setTimeout(function () {
          this.is_disabled = false;
        }, 3000);
      });
    },
    goTo(a) {
      const routeParams = {
        id: a._id,
        choosenEsterno: this.choosenEsterno,
      };

      this.$router
        .push({ name: "progettoEsterno", params: routeParams })
        .then(() => {
          console.log("Navigation successful");
        })
        .catch((err) => {
          console.error("Navigation error:", err);
        });
    },
    getProjects() {
      axios
        .get(
          `projects/${
            this.user.role == "commerciale"
              ? this.choosenEsterno
              : this.user.esterno
          }?page=${this.page}&search=${this.search}&customer=${
            this.selectedCustomer ? this.selectedCustomer : ""
          }`
        )
        .then((response) => {
          this.total_count = response.data.total;
          this.customers = response.data.customers;
          this.$store.dispatch("setEsternoProjects", response.data.projects);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getColor(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "in lavorazione") {
        return "red";
      } else if (status == "creato") {
        return "black";
      } else {
        return "green";
      }
    },
    getpStatus(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "da completare") {
        return "#ffc412";
      } else if (status == "in lavorazione") {
        return "red";
      } else if (status == "in produzione") {
        return "red";
      } else if (status == "file da caricare") {
        return "#2f2f2f";
      } else if (status == "completato") {
        return "green";
      } else {
        return "transparent";
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getQuotationStatus(elements) {
      var counter_0 = 0;
      var counter_1 = 0;

      for (var i = 0; i < elements.length; i++) {
        if (elements[i].status == 0) {
          counter_0 = counter_0 + 1;
        } else if (elements[i].status == 1) {
          counter_1 = counter_1 + 1;
        }
      }

      if (counter_0 > 0) {
        return { status: "In Attesa di Prev", color: "#474747" };
      } else if (counter_1 > 0) {
        return { status: "In Attesa", color: "#ffc412" };
      } else if (elements.length == 0) {
        return { status: "", color: "transparent" };
      } else if (elements.length > 0) {
        return { status: "Completato", color: "#4CAF50" };
      }
    },
  },
};
</script>

<style scoped>
.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.item {
  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}

.v-data-footer__select {
  display: none !important;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {
  z-index: 2000;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.text-center {
  text-align: center;
}

.force-square {
  border-radius: 0px !important;
}

.bigger-badge {
  font-size: 10px !important;
}

.dt {
  width: 100%;
}
</style>
